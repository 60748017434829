// Manage project page

import React from "react";
import { Layout } from "../../common/Element";
// import { DataGrid } from "@mui/x-data-grid";
import "../../css/DataTable.css";
import CreateProject from "./CreateProject";
import Icomoon from "../../libraries/Icomoon";
import Modal from "react-bootstrap/Modal";
import AppConfig from "../../modal/AppConfig";
import { projectList, deleteProject, uploadFile, MultipleFileUpload, updateProjectGalleryImageApi } from "../../apis/Project";
import {
  createGallery,
  updateGallery,
  deleteGallery,
} from "../../apis/Gallery";
import { callApi } from "../../apis/Api";
import { observer } from "mobx-react";
import ConfirmModal from "../../common/ConfirmModal";
import moment from "moment";
import User from "../../modal/User";
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Table } from "react-bootstrap";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class ManageProject extends React.Component {
  state = {
    loading: false,
    size: 10,
    current_page: 1,
    rows: [],
    // columns: [],
    viewProjectGallery: false,
    files: [],
    uploadPics: null,
    totalData: 0,
    page: 0,
    editProjectId: "",
    viewProjectGalleryId: "",
    status: false,
    viewProjectConfirmModal: false,
    galleryCollection: [],
    editGallaryId: "",
    viewGalleryConfirmModal: false,
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  async componentDidMount() {
    // const columns = [
    //   { field: "id", headerName: "#", hide: false, minWidth: 150 },
    //   { field: "name", headerName: "Project Name", minWidth: 450 },
    //   { field: "date", headerName: "Date", minWidth: 250 },
    //   {
    //     field: "action",
    //     headerName: "Action",
    //     minWidth: 250,

    //     renderCell: (cellValues) => (
    //       <div>
    //         <Icomoon
    //           className="pointer mx-2"
    //           icon="pen-solid"
    //           size={16}
    //           color="#E40045"
    //           onClick={() => this.editProject(cellValues.row.id)}
    //         />
    //         <Icomoon
    //           className="pointer mx-2"
    //           icon="trash-solid"
    //           size={16}
    //           color="#E40045"
    //           onClick={() => this.deleteProject(cellValues.row.id)}
    //         />
    //         <i
    //           className="fas fa-images theme-font-color big-font-size mx-2 pointer"
    //           onClick={() => this.viewProjectGallery(cellValues.row.id)}
    //         />
    //       </div>
    //     ),
    //   },
    // ];

    await this.projectApi();
    // this.setState({ columns: columns });
  }

  projectApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
    };
    const response = await projectList(requestData);
    if (response && response.status === "success") {
      // const { rows } = this.state;
      const result = response.result;
      let apiRows = [];
      // if (rows.length > 0) {
      //   for (let i in rows) {
      //     apiRows.push(rows[i]);
      //   }
      // }
      for (let i in result.data) {
        const row = {
          id: result.data[i].id,
          slNumber: Number(i) + 1,
          name: result.data[i].name,
          date: result.data[i].date
            ? moment(result.data[i].date)?.format("DD-MMM-YYYY")
            : "-",
          // action: <div>
          //     <Icomoon  className="pointer mx-2"  icon="trash-solid" size={16}  color="#E40045" onClick={() => this.deleteProject(result.data[i].id)} />
          //     <i className="fas fa-images theme-font-color big-font-size mx-2 pointer" onClick={() => this.setState({viewProjectGallery:true})}/>
          // </div>
        };
        apiRows.push(row);
      }
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  galleryApi = async () => {
    // View Gallery api
    const { viewProjectGalleryId } = this.state;
    let galleryFunctionUrl = "view_gallery";
    const galleryResponse = await callApi(
      galleryFunctionUrl,
      {},
      "GET",
      viewProjectGalleryId
    );
    const galleryCollection = galleryResponse.result.projectGalleries;
    this.setState({ galleryCollection: galleryCollection, loading: false });
  };

  render() {
    return (
      <Layout>
        <CustomLoaderModal loader={this.state.loading} />
        <div className="px-5 pb-5" style={{ marginTop: "4rem" }}>
          <div className="d-flex justify-content-between">
            <p className="theme-font-color align-self-center">
              Manage Projects
            </p>
            <button
              className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
              onClick={() => this.setState({ status: true, editProjectId: "" })}
            >
              CREATE PROJECT
            </button>
          </div>
          {this.renderTable()}
          {this.renderUploadImageModal()}
        </div>

        <CreateProject
          id={AppConfig.user_id}
          edit={this.state.editProjectId}
          status={this.state.status}
          closeModel={() => this.setState({ status: false, editProjectId: "" })}
          projectApi={this.projectApi}
        />
        <ConfirmModal
          delete={true}
          visible={this.state.viewProjectConfirmModal}
          heading="Delete Project"
          title="Are you sure you want to delete the project?"
          confirm={() => this.getProjectSuccess()}
          handleClose={() => this.setState({ viewProjectConfirmModal: false })}
        />
        <div>
          <ConfirmModal
            delete={true}
            visible={this.state.viewGalleryConfirmModal}
            heading="Delete Picture"
            title="Are you sure you want to delete the picture?"
            confirm={() => this.getGallerySuccess()}
            handleClose={() =>
              this.setState({ viewGalleryConfirmModal: false })
            }
          />
        </div>
      </Layout>
    );
  }

  // renderTable = () => {
  //   return (
  //     <div style={{ height: 620, width: "100%" }}>
  //       <DataGrid
  //         rows={this.state.rows}
  //         columns={this.state.columns}
  //         pageSize={this.state.size}
  //         onPageSizeChange={(size) =>
  //           this.setState({ size: size, current_page: 1 }, () =>
  //             this.projectApi()
  //           )
  //         }
  //         rowsPerPageOptions={[10, 20, 50]}
  //         onPageChange={(current_page) =>
  //           this.setState({ current_page: parseInt(current_page) + 1 }, () =>
  //             this.projectApi()
  //           )
  //         }
  //         rowCount={this.state.totalData}
  //         disableSelection={true}
  //         pagination
  //       />
  //     </div>
  //   );
  // };

  headCells = [
    {
      id: "slNumber",
      label: "#",
      width: "5%",
      align: "center",
    },
    {
      id: "name",
      label: "Name",
      width: "40%",
    },
    {
      id: "date",
      label: "Date",
      width: "40%",
    },
    {
      id: "action",
      label: "Action",
      width: "20%",
      align: "center",
    },
  ];

  handleRequestSort = (property) => {
    this.setState((prevState) => {
      const { orderBy, order, rows } = prevState;
      const isAsc = orderBy === property && order === "asc";

      const sortedRows = [...rows].sort((a, b) => {
        if (property === "slNumber") {
          return isAsc ? a.slNumber - b.slNumber : b.slNumber - a.slNumber;
        }
        if (property === "title") {
          return isAsc
            ? a.title.localeCompare(b.title)
            : b.title.localeCompare(a.title);
        }
        if (property === "type") {
          return isAsc
            ? a.type.localeCompare(b.type)
            : b.type.localeCompare(a.type);
        }
        return 0;
      });

      return {
        orderBy: property,
        order: isAsc ? "desc" : "asc",
        rows: sortedRows,
      };
    });
  };

  renderTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%" }}
            style={{
              border: "1px solid #E40045",
              borderRadius: "5px",
              backgroundColor: "#f7f7f7",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow style={{ borderColor: "red" }}>
                    {this.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={
                          this.state.orderBy === headCell.id
                            ? this.state.order
                            : false
                        }
                        width={headCell.width}
                        align={headCell.align ? headCell.align : "left"}
                      >
                        <TableSortLabel
                          active={this.state.orderBy === headCell.id}
                          direction={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : "asc"
                          }
                          onClick={() => this.handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.rows.map((row) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell
                          align="left"
                          style={{ padding: "15px 10px" }}
                        >
                          {" "}
                          {row.slNumber}{" "}
                        </TableCell>
                        <TableCell align="left" style={{ padding: "15px 5px" }}>
                          {" "}
                          {row.name || "-"}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-truncate"
                          style={{ maxWidth: "150px", padding: "15px 5px" }}
                        >
                          {row.date || "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "15px 5px" }}
                        >
                          <div className="d-flex justify-content-center">
                            <Icomoon
                              className="pointer mx-2"
                              icon="pen-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.editProject(row.id)}
                            />
                            <Icomoon
                              className="pointer mx-2"
                              icon="trash-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.deleteProject(row.id)}
                            />
                            <i
                              className="fas fa-images theme-font-color big-font-size mx-2 pointer"
                              onClick={() => this.viewProjectGallery(row.id)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {this.state.rows.length === 0 && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "300px",
                  }}
                >
                  <p>No records found</p>
                </div>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.size}
              page={this.state.page}
              onPageChange={(e, newPage) =>
                this.setState(
                  {
                    size: this.state.size,
                    current_page: newPage + 1,
                    page: newPage,
                  },
                  () => this.projectApi()
                )
              }
              onRowsPerPageChange={(e) =>
                this.setState({ size: e.target.value, current_page: 1 }, () =>
                  this.projectApi()
                )
              }
            />
          </Paper>
        </Box>
      </div>
    );
  };

  // Render upload picture modal function

  renderUploadImageModal = () => {
    return (
      <Modal
        size="xl"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.viewProjectGallery}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              Project Gallery
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.setState({
                  viewProjectGallery: false,
                  uploadPics: null,
                  uploadImageError: "",
                });
              }}
            />
          </div>
          <Modal.Body>
            <div className="row p-3">
              {this.state.galleryCollection.map((galleryCollection) => (
                <div className="col-md-3 p-2 mx-0" key={galleryCollection.id}>
                  <img
                    src={galleryCollection.image}
                    alt={galleryCollection.id}
                    width="100%"
                    height="175px"
                  />
                  <div className="d-flex my-3">
                    <Icomoon
                      className="pointer mx-2"
                      icon="pen-solid"
                      size={16}
                      color="#E40045"
                      onClick={(e) => {
                        this.setState({ editGallaryId: galleryCollection.id });
                        this.handleUploadPicsSelect(e);
                      }}
                    />
                    <Icomoon
                      className="pointer mx-2"
                      icon="trash-solid"
                      size={16}
                      color="#E40045"
                      onClick={() => {
                        this.deleteGallery(galleryCollection.id);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            {this.state.uploadPics ? (
              <div>{this.renderUploadedPic()}</div>
            ) : null}
            <form
              onSubmit={this.onSubmitGalleryPic}
              encType="multipart/form-data"
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-4">
                  <input
                    type="file"
                    multiple
                    id="uploadPics"
                    className="d-none"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.selectUploadPics}
                  />
                  {/* <button
                    className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                    onClick={this.handleUploadPicsSelect}
                  >
                    <i className="fas fa-upload" />
                    <span className="mx-3">Upload</span>
                  </button> */}

                  <input
                    type="file"
                    multiple
                    id="mutipleUploadPics"
                    className="d-none"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.handleFileChange}
                  />
                  <button
                    className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                    onClick={this.handleMultipleUploadPicsSelect}
                  >
                    <i className="fas fa-upload" />
                    <span className="mx-3">Multiple Upload</span>
                  </button>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    this.setState({
                      viewProjectGallery: false,
                      viewProjectGalleryId: ""
                    });
                  }}
                  className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                >
                  <span className="mx-3">Close</span>
                </button>
                {/* <button
                  type="submit"
                  className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                >
                  <span className="mx-3">Save</span>
                </button> */}
              </div>
              <div className="d-flex justify-content-start">
                {this.state.uploadImageError && (
                  <span className="small-font-size text-danger">
                    {" "}
                    {this.state.uploadImageError}
                  </span>
                )}
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  handleMultipleUploadPicsSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("mutipleUploadPics");
    fileSelector.click();
  };

  handleFileChange = async (e) => {
    const files = e.target.files;
    this.setState({ loading: true });
    MultipleFileUpload(files, this.callBackMultipleUpload, this.state.viewProjectGalleryId, User.user_id);
  };

  callBackMultipleUpload = (response = false) => {
    if (response && response.status === "success") {
      AppConfig.setMessage(response.message, false);
      this.viewProjectGallery(this.state.viewProjectGalleryId);
      // this.setState({ loading: false });
    } else if (response && response.status === "error") {
      AppConfig.setMessage(response.message);
      // this.viewProjectGallery(this.state.viewProjectGalleryId);
      this.setState({ loading: false });
    }
  };
  
  // Handle file select

  handleUploadPicsSelect = (e) => {
    e.preventDefault();
    this.setState({ uploadImageError: "" });
    const fileSelector = document.getElementById("uploadPics");
    fileSelector.click();
  };

  // Select file

  selectUploadPics = (e) => {
    e.preventDefault();
    const uploadPics = e.target.files[0];
    this.setState({ loading: true });
    // const type = "uploadPics";
    // uploadFile(uploadPics, this.callBackUploadPics, type);
    const functionUrl = 'view_gallery';
    const projectId = this.state.viewProjectGalleryId;
    const galleryId = this.state.editGallaryId;
    const userId = User.user_id;
    updateProjectGalleryImageApi(functionUrl, uploadPics, this.callBackUploadPics, galleryId, projectId, userId);
  };

  //  Call back function for uploaded pics
  callBackUploadPics = (response = false) => {
    if (response && response.statuscode === 200) {
      AppConfig.setMessage("Project image updated successfully", false);
      this.viewProjectGallery(this.state.viewProjectGalleryId);
      // this.setState({ loading: false });
    } else if (response.status === "error") {
      AppConfig.setMessage("Project image updating failed");
      this.setState({ loading: false });
    }
    // if (response && response.status === "success") {
    //   // this.setState({ uploadPics: response.result.projectimage });
    // }
  };

  //Render uploaded pic

  renderUploadedPic = () => {
    return (
      <div className="col-md-4">
        <div className="col-md-12 border border-dark rounded bg-white">
          <div className="row">
            <img src={this.state.uploadPics} alt="pic" />
          </div>
        </div>
        <i
          className="fas fa-edit theme-font-color big-font-size mx-2 pointer"
          onClick={this.handleUploadPicsSelect}
        />
        <i
          className="fas fa-trash-alt theme-font-color big-font-size mx-2 pointer"
          onClick={() => this.setState({ uploadPics: "" })}
        ></i>
      </div>
    );
  };

  // Edit project modal
  editProject = (id) => {
    this.setState({ editProjectId: id, status: true });
  };

  // Delete project confirm modal
  deleteProject = (id) => {
    this.setState({ viewProjectConfirmModal: true, deleteId: id });
  };

  // Delete project api function
  getProjectSuccess = () => {
    const id = this.state.deleteId;
    deleteProject(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewProjectConfirmModal: false, uploadPics: null });
        AppConfig.setMessage("Deleted Successfully", false);
        this.projectApi();
        this.props.history.push("/manage-project");
      } else {
        this.setState({ viewProjectConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };

  // View project gallary modal and display the gallary  pics by calling gallary api

  viewProjectGallery = (id) => {
    this.setState(
      { viewProjectGallery: true, viewProjectGalleryId: id, loading: true },
      () => {
        this.galleryApi();
      }
    );
  };

  // Create and update uploaded gallery images
  onSubmitGalleryPic = async (e) => {
    e.preventDefault();
    const id = this.state.editGallaryId;
    let requestData = {
      user_id: User.user_id,
      project_id: this.state.viewProjectGalleryId,
      image: this.state.uploadPics,
    };

    let response = "";
    if (requestData.image) {
      if (id) {
        requestData["updated_by"] = User.user_id;
        response = await updateGallery(requestData, id);
      } else {
        requestData["created_by"] = User.user_id;
        response = await createGallery(requestData);
      }
    } else {
      return this.setState({
        uploadImageError: "Please upload an project image",
      });
    }

    if (response.status === "success") {
      this.setState({
        viewProjectGallery: false,
        uploadPics: null,
        editGallaryId: "",
      });
      this.viewProjectGallery(this.state.viewProjectGalleryId);
      AppConfig.setMessage("Picture uploaded successfully", false);
    } else if (response.status === "error") {
      this.setState({ viewProjectGallery: false });
      AppConfig.setMessage(response.result);
    }
  };

  // Delete gallery confirm modal
  deleteGallery = (id) => {
    this.setState({
      viewGalleryConfirmModal: true,
      deleteGalleryId: id,
      uploadImageError: "",
    });
  };

  // // Delete gallery api function
  getGallerySuccess = () => {
    const id = this.state.deleteGalleryId;
    deleteGallery(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewGalleryConfirmModal: false });
        AppConfig.setMessage("Deleted Successfully", false);
        this.viewProjectGallery(this.state.viewProjectGalleryId);
        this.props.history.push("/manage-project");
      } else {
        this.setState({ viewProjectConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };
}
export default observer(ManageProject);
