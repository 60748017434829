export const serviceCategories = [
  {
    label: "1 BHK",
    value: "1bhk-interior-design",
    displayValue: "1 BHK Interior Design",
    category:"residential-interior",
    categoryTitle: "1 BHK Designs",
    categoryDescription:
      "Check out popular 1 BHK interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "2 BHK",
    value: "2bhk-interior-design",
    displayValue: "2 BHK Interior Design",
    category:"residential-interior",
    categoryTitle: "2 BHK Designs",
    categoryDescription:
      "Check out popular 2 BHK interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "3 BHK",
    value: "3bhk-interior-design",
    displayValue: "3 BHK Interior Design",
    category:"residential-interior",
    categoryTitle: "3 BHK Designs",
    categoryDescription:
      "Check out popular 3 BHK interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "4 BHK/Duplex",
    value: "4bhk-duplex-interior-design",
    displayValue: "4 BHK/Duplex Interior Design",
    category:"residential-interior",
    categoryTitle: "4 BHK/Duplex Designs",
    categoryDescription:
      "Check out popular 4 BHK/Duplex interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "Villa/Independent House",
    value: "villa-independent-house-interior-design",
    displayValue: "Villa/Independent House Interior Design",
    category:"residential-interior",
    categoryTitle: "Villa/Independent House Designs",
    categoryDescription:
      "Check out popular Villa/Independent House interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "Restaurant Interior",
    value: "restaurant-interior-design",
    displayValue: "Restaurant Interior Design",
    category:"commercial-interior",
    categoryTitle: "Restaurant Interior Designs",
    categoryDescription:
      "Check out popular restaurant interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "Hotel Interior",
    value: "hotel-interior-design",
    displayValue: "Hotel Interior Design",
    category:"commercial-interior",
    categoryTitle: "Hotel Interior Designs",
    categoryDescription:
      "Check out popular hotel interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "Pub Interior",
    value: "pub-interior-design",
    displayValue: "Pub Interior Design",
    category:"commercial-interior",
    categoryTitle: "Pub Interior Designs",
    categoryDescription:
      "Check out popular pub interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "Cafe Interior",
    value: "cafe-interior-design",
    displayValue: "Cafe Interior Design",
    category:"commercial-interior",
    categoryTitle: "Cafe Interior Designs",
    categoryDescription:
      "Check out popular cafe interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "Retail Store Interior",
    value: "retail-store-interior-design",
    displayValue: "Retail Store Interior Design",
    category:"commercial-interior",
    categoryTitle: "Retail Store Interior Designs",
    categoryDescription:
      "Check out popular retail store interior designs amongst our 10,000+ happy customers.",
  },
  {
    label: "Office Interior",
    value: "office-interior-design",
    displayValue: "Office Interior Design",
    category:"commercial-interior",
    categoryTitle: "Office Interior Designs",
    categoryDescription:
      "Check out popular office interior designs amongst our 10,000+ happy customers.",
  }
];

export const serviceCategoryList = [
  {
    id: 1,
    title: "A Warm And Cozy 3BHK Flat Design With All The Comfort You Need",
    description:
      "Designed with the intent of being playful and warm, this 3BHK home combines two distinct design styles to strike a balance in the middle.",
    imageUrls: [
      "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/2.jpg",
      "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/4.jpg",
      "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
    ],
  },
  {
    id: 2,
    title: "A Warm And Cozy 3BHK Flat Design With All The Comfort You Need",
    description:
      "Designed with the intent of being playful and warm, this 3BHK home combines two distinct design styles to strike a balance in the middle.",
    imageUrls: [
      "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/2.jpg",
      "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/4.jpg",
      "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
    ],
  },
];


export const serviceData = {
  "modular-kitchen-design": [
    { 
      id: 1,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    },
    { 
      id: 2,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    },
    { 
      id: 3,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    },
    { 
      id: 4,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    }
  ],
  "living-room-design": [
    { 
      id: 1,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    },
    { 
      id: 2,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    },
    { 
      id: 3,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    },
    { 
      id: 4,
      title: "A Bold Navy Blue And White Modular Kitchen Design",
      serviceImage: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg",
      thumbnailUrl: "https://thedezigncode.com/dezigncode_api/public/storage/projects/Beachbox_Commercial/7.jpg"
    }
  ],
}
