import {callApi} from './Api';
import './Global';

/**
 *
 * @param{email, password)data
 */
export const createGallery = async(requestData={}) => {
    const functionUrl = "view_gallery";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};


/**
 *
 * @param{get single project) by id
 */
export const updateGallery = async(requestData, id) => {
    const functionUrl = "view_gallery";
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};


/**
 *
 * @param{delete gallery) by id
 */
export const deleteGallery = async(id) => {
    const functionUrl = "view_gallery";
    try {
        return await callApi(functionUrl, {}, 'DELETE', id )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single project) by id
 */
export const getGallery = async(id) => {
    const functionUrl = "view_gallery";
    try {
        return await callApi(functionUrl, {}, 'GET', id)
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

