// Base url

// global.baseUrl = "http://localhost:8000/api/v1/";
// global.baseUrl = "https://api.thedezigncode.com/v1/";
// global.baseUrl = "https://api.thedezigncode.com/dezigncode_api/api/v1/";
global.baseUrl = "https://api.thedezigncode.com/api/v1/"; // server url
// global.baseUrl = "http://192.168.206.199:8000/api/v1/";

// Function url

global.apiUrls = {
  home: "home",
  about: "about",
  services: "services",
  theme_homes: "themehomes",
  blogs: "blogs",
  footer: "footers",
  projects: "projectslist",
  view_project: "project",
  view_gallery: "projectgallery",
  consult_online: "consult_online",
  policies: "policies",
  offers: "offers",
  quotes: "quotes",
  subscription: "subscription",
  send_mail: "send_email",

  // Admin Urls

  login: "login",
  forgot: "forgot_password",
  change_password: "change_password",
  view_mails: "list_mails",
  project_list: "projects",
  file: "project/image_upload",
  projectMultipleImageUpload: "project-images-upload",

  get_contents: "managecontents",
  update_content: "managecontent",

  /* Testimonial api routes*/
  testimonialList: "testimonials",
  testimonial: "testimonial",
  uploadTestimonial: "upload-testimonial-image",

  /* Banner api routes */
  homeBanner: "home-banner", // post method
  home_Banner: "homebanner", // get, delete, update methods
  homeBanners: "homebanners",

  /* Services routes */
  service: "service", // post, put, delete method
  serviceList: "service-list", // post method
  updateService: "update-service", // put method
  uploadImage: "upload-service-image", // post method

  /* Services galleries routes */
  serviceGallery: "servicegallery", // post, put, get, delete method
  serviceGalleryList: "service-galleries", // get list of gallery
  serviceMultipleImageUpload: "service-images-upload", // multiple image upload

  /* Public services routes */
  getServiceslist: "getServiceslist",
  getServicesType: "getServicesType",

  /* FAQs api routes*/
  faqsList: "faq-list",
  faq: "faq",
  faqType: "faq-type",

  /* client api routes */
  clientList: "clients", // list of clients
  client: "client", // create, get, update and delete
  clientImageUpload: "client-image-upload",

  /* Seo API routes */
  seoList: "seo-list",
  seo: "seo",
  seoByPage: "seo-by-page",
};
