import React from "react";
import "../css/Common.css";
// import { callApi } from "../apis/Api";
import Header from "./Header";
import Footer from "../pages/Footer";
import { serviceCategories } from "./serviceData";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { MenuItem } from "react-pro-sidebar";
import Slider from "react-slick";
import Icomoon from "../libraries/Icomoon";
import ConsultModal from "./ConsultModal";
import { publicServiceList } from "../apis/Service";

class Services extends React.Component {
  state = {
    services: [],
    currentUrl: "",
    categoryUrl: "",
    consultModal: false,
    serviceCategoryData: {}
  };
  async componentDidMount() {
    const { history } = this.props;
    const path = history.location.pathname.split("/")[2];
    this.serviceListApi(path);

    this.unlisten = history.listen(async (location) => {
      const isServicePage = location.pathname.includes("services");

      if (isServicePage) {
        const currectUrl =
          location !== "/services" ? location.pathname.split("/")[2] : "";
        const categoryDetails = serviceCategories.find(
          (c) => c.value === currectUrl
        );

        this.serviceListApi(currectUrl);

        // The location object contains information about the current URL
        this.setState({
          currentUrl: location.pathname,
          categoryUrl: currectUrl,
          selectedCategory: categoryDetails,
          type: categoryDetails && categoryDetails.value,
        });
      }
    });

    const currectUrl =
      history.location.pathname !== "/services"
        ? history.location.pathname.split("/")[2]
        : "";
    const categoryDetails = serviceCategories.find(
      (c) => c.value === currectUrl
    );

    if (history.location.pathname) {
      this.setState({
        currentUrl: history.location.pathname,
        categoryUrl: currectUrl,
        selectedCategory: categoryDetails,
        type: categoryDetails && categoryDetails.value,
      });
    }

    // document.title = `Interior Design Company in Bangalore | DezignCode`;
    // document.querySelector('meta[name="description"]').content =
    //   "Looking for interior design company in Bangalore, DezignCode offers the best home interior design and commercial interior space design. Our interior designers can take care of everything from design to installation. Visit or Call us to get Get Free Quote Now!";
    // let functionUrl = "services";
    // const response = await callApi(functionUrl, {}, "GET");
    // const result = response.result;

    // let services = [];
    // for (let i in result) {
    //   services.push(result[i]);
    // }
    // this.setState({
    //   services: services,
    // });
  }

  serviceListApi = async (category) => {
    const response = await publicServiceList(category);
    if (response && response.status === "success") {
      const result = response?.result;
      if(result) {
        this.setState({ serviceCategoryData: result });
      }
    }
  };
 
  componentWillUnmount() {
    // Cleanup the listener when the component unmounts
    this.unlisten();
  }

  renderServiceContent() {
    const serviceSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: <Icomoon icon="btnl" size={30} />,
      nextArrow: <Icomoon icon="btnr" size={30} />,
      // autoplay: true,
      // autoplaySpeed: 5000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <div className="d-flex w-100 align-content-center justify-content-center">
          <div className="col-md-11">
            {serviceCategories
              ?.filter((item) => item.category === this.state.categoryUrl)
              ?.map((category, index) => (
                <div
                  className={`${
                    this.state.serviceCategoryData[category.value]?.length > 0
                      ? "pb-md-3 py-2"
                      : "d-none"
                  }`}
                  key={index}
                >
                  <p className="font-weight-bold big-font-size theme-font-color px-3">
                    {this.state.serviceCategoryData[category.value]?.length >
                      0 && category.categoryTitle}
                  </p>
                  {this.state.serviceCategoryData[category.value]?.length >
                    0 && (
                    <Slider
                      {...serviceSettings}
                      className="rose-dot service-slick-next-button"
                    >
                      {this.state.serviceCategoryData[category.value]?.map(
                        (service, serviceIndex) => (
                          <div
                            key={serviceIndex}
                            className="d-flex justify-content-center text-decoration-none text-black"
                          >
                            <div className="col-12">
                              <Link
                                to={`/services/${category.category}/${category.value}`}
                                className="text-decoration-none text-black"
                              >
                                <img
                                  src={service.thumbnailUrl}
                                  alt=""
                                  style={{
                                    height: "300px",
                                    width: "100%",
                                  }}
                                />
                              </Link>
                              <div className="d-flex gap-2 justify-content-between">
                                <Link
                                  to={`/services/${category.category}/${category.value}`}
                                  className="text-decoration-none text-black"
                                >
                                  <div className="my-3">
                                    <p className="font-style normal-font-size">
                                      {service.title}
                                    </p>

                                    <div
                                      className="border-0 bg-white theme-font-color font-style normal-font-size"
                                      style={{ fontWeight: 500 }}
                                    >
                                      More Details{" "}
                                      <i className="fas fa-solid fa-angle-right px-2"></i>
                                    </div>
                                  </div>
                                </Link>

                                <div
                                  className="mt-3"
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <ConsultModal service="service" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </Slider>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <Header />
        {/* <div className="d-flex align-items-center service-bg">
          <div className="mt-5">
            <div className="mx-5 mt-5 pt-5">
              <h1 className="text-white font-weight-bold font-style2 mx-5 px-3 pt-5">
                Transform Your Interior Design with Classy, Innovative Design
                and Style
              </h1>
              <p className="text-white font-weight-bold font-style2 mx-5 px-3 pt-5">
                OUR SERVICES
              </p>
            </div>
          </div>
        </div>
        <div className="py-5">{this.renderContent()}</div> */}
        <div className="bg-white pt-3 pt-lg-5 pb-lg-2">
          <ul className="d-none px-md-5 mx-md-5 d-lg-flex justify-content-start flex-wrap gap-2 bg-white">
            <li className="nav-item font-style" style={{ dispaly: "block" }}>
              <Link
                to={
                  this.state.categoryUrl === `residential-interior`
                    ? "/services/residential-interior"
                    : "/services/commercial-interior"
                }
                className={
                  this.state.currentUrl === "/services/residential-interior" ||
                  "/services/commercial-interior"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                All
              </Link>
            </li>
            {serviceCategories
              .filter((item) => item.category === this.state.categoryUrl)
              ?.map((category) => (
                <li
                  className="nav-item dropdown font-style"
                  style={{ dispaly: "block" }}
                  key={category.value}
                >
                  <Link
                    to={`/services/${category.category}/${category.value}`}
                    onClick={() =>
                      this.setState({ selectedCategory: category })
                    }
                    className={`${
                      this.state.currentUrl ===
                      `/services/${category.category}/${category.value}`
                        ? "nav-link active"
                        : "nav-link"
                    }`}
                  >
                    {category.label}
                  </Link>
                </li>
              ))}
          </ul>

          <div
            className="d-flex d-block d-md-none gap-3 pt-5 mt-4 flex-wrap px-3 bg-white"
            style={{ position: "sticky", top: "30px", zIndex: 999 }}
          >
            <FormControl className="col-md-12 mb-3 col-lg-12 me-1">
              <InputLabel id="select-category">Select Category</InputLabel>
              <Select
                labelId="select-category"
                id="select-category"
                value={this.state.type}
                onChange={(e) => {
                  this.setState({
                    type: e.target.value,
                  });
                }}
              >
                <MenuItem value="all">
                  <Link
                    to={
                      this.state.categoryUrl === `residential-interior`
                        ? "/services/residential-interior"
                        : "/services/commercial-interior"
                    }
                    className={`text-decoration-none text-black px-4 w-100 `}
                  >
                    {" "}
                    All Details{" "}
                  </Link>
                </MenuItem>

                {serviceCategories
                  .filter((item) => item.category === this.state.categoryUrl)
                  ?.map((category) => (
                    <MenuItem
                      value={category.value}
                      key={category.value}
                      onClick={() =>
                        this.setState({ selectedCategory: category })
                      }
                    >
                      <Link
                        to={`/services/${category.category}/${category.value}`}
                        className="text-decoration-none w-100 text-black px-4"
                      >
                        {category.label}
                      </Link>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div>{this.renderServiceContent()}</div>
        </div>

        <Footer props={this.props} />
      </>
    );
  }
  renderContent() {
    let j = 1;
    return (
      <div>
        {this.state.services.map((services, i) => {
          if (j % 2 !== 0) {
            j = j + 1;
            return (
              <div id={services.id} className="my-5 py-5 header-margin" key={i}>
                <div className="d-flex justify-content-center">
                  <div className="my-3 mx-5 col-md-10 row border-style px-0">
                    <div className="col-md-6 mx-0 px-0">
                      <img
                        className="border-left-style"
                        src={services.image}
                        alt={services.alt_tag}
                        width="100%"
                        height="695px"
                      />
                    </div>
                    <div className="col-md-6">
                      <div
                        className="bg-white border-style mx-3 py-2"
                        style={{ height: "695px" }}
                      >
                        <h2 className="font-weight-bold text-center font-style2 py-3 px-3 pb-3">
                          {services.title}
                        </h2>
                        <p
                          className="text-align font-style px-4 normal-font-size text-justify description-content"
                          dangerouslySetInnerHTML={{
                            __html: services.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            j = j + 1;
            return (
              <div id={services.id} key={i}>
                <div className="d-flex justify-content-center">
                  <div className="my-3 mx-5 col-md-10 row border-style px-0">
                    <div className="col-md-6">
                      <div
                        className="bg-white border-style mr-3 py-2"
                        style={{ height: "695px" }}
                      >
                        <h2 className="font-weight-bold text-center font-style2 py-3 px-3 pb-3">
                          {services.title}
                        </h2>
                        <p
                          className="text-align font-style normal-font-size px-4 text-justify description-content"
                          dangerouslySetInnerHTML={{
                            __html: services.description,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mx-0 px-0">
                      <img
                        className="border-right-style"
                        src={services.image}
                        alt={services.alt_tag}
                        width="100%"
                        height="695px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default Services;
