// Left menu  Items

import React from "react";
import { useLocation } from "react-router-dom";
import { Menu } from "react-pro-sidebar";
import { Item } from "./Item";

const LeftMenuItem = () => {
  const currentPath = useLocation();
  return (
    <>
      <Menu className="bg-white">
        {/* --------------------------------------------
                        Manage Banner
                ---------------------------------------------- */}
        <Item
          title={"Manage Banner"}
          link={"/manage-banner"}
          icon={"banner"}
          active={currentPath.pathname === "/manage-banner" ? true : false}
        ></Item>

        {/* --------------------------------------------
                        Manage Services
                ---------------------------------------------- */}
        <Item
          title={"Manage Services"}
          link={"/manage-service"}
          icon={"service"}
          active={currentPath.pathname === "/manage-service" ? true : false}
        ></Item>

        {/* --------------------------------------------
                        Manage FAQs
                ---------------------------------------------- */}
        <Item
          title={"Manage FAQs"}
          link={"/manage-faqs"}
          icon={"faq"}
          active={currentPath.pathname === "/manage-faqs" ? true : false}
        ></Item>

        {/* --------------------------------------------
                        Manage Project
                ---------------------------------------------- */}
        <Item
          title={"Manage Project"}
          link={"/manage-project"}
          icon={"project"}
          active={currentPath.pathname === "/manage-project" ? true : false}
        ></Item>

        {/* --------------------------------------------
                        Manage Project
                ---------------------------------------------- */}
        <Item
          title={"Manage Clients"}
          link={"/manage-clients"}
          icon={"clients"}
          active={currentPath.pathname === "/manage-clients" ? true : false}
        ></Item>

        {/* --------------------------------------------
                        Manage Content
                ---------------------------------------------- */}
        <Item
          title={"Manage Content"}
          link={"/manage-content"}
          icon={"content"}
          active={currentPath.pathname === "/manage-content" ? true : false}
        ></Item>

        {/* --------------------------------------------
                        View Mail details
                ---------------------------------------------- */}

        <Item
          title={"Follow Up Email"}
          link={"/view-mail-details"}
          icon={"mail"}
          active={currentPath.pathname === "/view-mail-details" ? true : false}
        ></Item>

        {/* --------------------------------------------
                        Testimonial contents
                ---------------------------------------------- */}

        <Item
          title={"Testimonial Content"}
          link={"/testimonial-content"}
          icon={"testimonial"}
          active={
            currentPath.pathname === "/testimonial-content" ? true : false
          }
        ></Item>

        {/* --------------------------------------------
                        Manage Testimonial contents
                ---------------------------------------------- */}

        <Item
          title={"Manage SEO"}
          link={"/manage-seo"}
          icon={"seo"}
          active={
            currentPath.pathname === "/manage-seo" ? true : false
          }
        ></Item>

        {/* --------------------------------------------
                        Change password
                ---------------------------------------------- */}

        <Item
          title={"Change Password"}
          link={"/change-password"}
          icon={"changePassword"}
          active={currentPath.pathname === "/change-password" ? true : false}
        ></Item>
      </Menu>
    </>
  );
};

export default LeftMenuItem;
