// Forgot passsword Page

import React from 'react';
import {CheckPassword, ComparePassword} from "../../common/Validation";
import {changePassword} from '../../apis/Admin';
import { TextField } from '@material-ui/core';
import {Layout} from '../../common/Element';
import '../../css/Common.css';
import Icomoon from '../../libraries/Icomoon';
import AppConfig from '../../modal/AppConfig';
import { observer } from 'mobx-react';
import CustomLoaderModal from '../../common/CustomLoaderModal';

class ChangePassword extends React.Component {
    state={
        loading: false,
        oldPassword:'',
        newPassword:'',
        confirmPassword:'',
        showOldPassword:false,
        showNewPassword:false,
        showConfirmPassword:false,
        error:'',
        status:false
    }


    render() {
        return (
            <Layout>
                <CustomLoaderModal loader={this.state.loading} />
                <div className="d-flex justify-content-center pt-5 header-margin">                       
                    <div className="col-md-4 mt-3">
                        {this.renderChangePassword()}
                    </div>
                </div>
            </Layout>   
        )
    }


    // Render forgot password function

    renderChangePassword() {
        return(
            <div className="col-md-12 rounded bg-white shadow p-3 my-5 header-margin pt-5">
                <h3 className="theme-font-color font-style mb-3 text-center">Change Password</h3>
                <form onSubmit={this.onSubmitChangePassword}>
                    <div className="py-3">
                        <TextField 
                            type={this.state.showOldPassword ? 'text' : 'password'}
                            className="form-control" 
                            id="oldPassword" 
                            label="Old Password"
                            value={this.state.oldPassword}
                            onChange={(e)=>this.setState({oldPassword:e.target.value, oldPasswordError:''})}
                            onFocus ={()=> this.setState({oldPasswordError:''})}
                            InputProps={{
                                endAdornment: (this.state.showOldPassword
                                    ?
                                    <Icomoon icon='eye-slash-solid' size={20}  
                                        onClick={()=> this.setState({
                                        showOldPassword: !this.state
                                            .showOldPassword
                                        })} 
                                    /> 
                                    : <Icomoon icon='eye-solid' size={20} 
                                        onClick={()=> this.setState({
                                        showOldPassword: !this.state
                                            .showOldPassword
                                        })} 
                                    />
                                )
                            }}
                        />
                    </div>
                    {this.state.oldPasswordError ?  (<span className='small-font-size text-danger'> {this.state.oldPasswordError}</span>):''}
                    <div className="py-3">
                        <TextField 
                            type={this.state.showNewPassword ? 'text' : 'password'}
                            id="newPassword" 
                            label="New Password"
                            className="form-control" 
                            value={this.state.newPassword}
                            onChange={(e)=>this.setState({newPassword:e.target.value, newPasswordError:''})}
                            onFocus ={()=> this.setState({newPasswordError:''})}
                            InputProps={{
                                endAdornment: (this.state.showNewPassword
                                    ?
                                    <Icomoon icon='eye-slash-solid' size={20}  
                                        onClick={()=> this.setState({
                                        showNewPassword: !this.state
                                            .showNewPassword
                                        })} 
                                    /> 
                                    : <Icomoon icon='eye-solid' size={20} 
                                        onClick={()=> this.setState({
                                        showNewPassword: !this.state
                                            .showNewPassword
                                        })} 
                                    />
                                )
                            }}
                        />
                    </div>
                    {this.state.newPasswordError ?  (<span className='small-font-size text-danger'> {this.state.newPasswordError}</span>):''}
                    <div className="py-3">
                        <TextField 
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword" 
                            label="Confirm Password"
                            className="form-control" 
                            value={this.state.confirmPassword}
                            onChange={(e)=>this.setState({confirmPassword:e.target.value, confirmPasswordError:''})}
                            onFocus ={()=> this.setState({confirmPasswordError:''})}
                            InputProps={{
                                endAdornment: (this.state.showConfirmPassword
                                    ?
                                    <Icomoon icon='eye-slash-solid' size={20}  
                                        onClick={()=> this.setState({
                                        showConfirmPassword: !this.state
                                            .showConfirmPassword
                                        })} 
                                    /> 
                                    : <Icomoon icon='eye-solid' size={20} 
                                        onClick={()=> this.setState({
                                        showConfirmPassword: !this.state
                                            .showConfirmPassword
                                        })} 
                                    />
                                )
                            }}
                        />
                    </div>
                    {this.state.confirmPasswordError ?  (<span className='small-font-size text-danger'> {this.state.confirmPasswordError}</span>):''}
                    <button type='submit' className="btn theme-bg-color text-white col-md-12 font-style my-3">Submit</button>
                </form>
            </div>
        )
    }
    

    oldPasswordCheck = () => {
		const oldPasswordError = CheckPassword(this.state.oldPassword);
		if (oldPasswordError && oldPasswordError === 1) {
			this.setState({ oldPasswordError:'Please enter old password' });
			return false;
		} else if (oldPasswordError && oldPasswordError === 2) {
			this.setState({ oldPasswordError: "Please enter valid old password" });
			return false;
		}
		return true;
	};
	passwordCheck = () => {
		const passwordError = CheckPassword(this.state.newPassword);
		if (passwordError && passwordError === 1) {
			this.setState({ newPasswordError: 'Please enter new password' });
			return false;
		} else if (passwordError && passwordError === 2) {
			this.setState({ newPasswordError: "Please enter valid new password" });
			return false;
		}
		return true;
	};
	confirmPasswordCheck = () => {
		const conPasswordError = CheckPassword(this.state.confirmPassword);
		if (conPasswordError && conPasswordError === 1) {
			this.setState({ confirmPasswordError: "Please enter confirm password" });
			return false;
		}
		const comPasswordError = ComparePassword(
			this.state.newPassword,
			this.state.confirmPassword
		);
		if (comPasswordError) {
			this.setState({ confirmPasswordError: "Password doesn't match" });
			return false;
		}
		return true;
	};
	validateAll = () => {
		const oldPasswordInput = this.oldPasswordCheck();
		const passwordInput = this.passwordCheck();
		const confirmPasswordInput = this.confirmPasswordCheck();
		if (oldPasswordInput && passwordInput && confirmPasswordInput) {
			return true;
		}
			return false;
	};

    // onsubmit function for forget password

    onSubmitChangePassword = async (e) =>{
        e.preventDefault();
        const allValidation = this.validateAll()
        if (allValidation) {
            this.setState({ loading: true });
            const requestData = {
             oldpassword:this.state.oldPassword,
             newpassword:this.state.newPassword
            };
            const response = await changePassword(requestData);
            if(response && response.status === 'success') {
                AppConfig.setMessage(response.result, false);
            } else if(response.status === 'error' && response?.statuscode === 400) {
                AppConfig.setMessage(response?.result?.newpassword && response?.result?.newpassword[0]);
            }
            else if(response.status === 'error' && response?.statuscode === 500) {
                AppConfig.setMessage(response?.result);
            }
            this.setState({ loading: false });
        }
    }
}


export default observer(ChangePassword);