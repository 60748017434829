import { callApi } from "./Api";
import "./Global";

/**
 *
 * @param{delete banner image) by id
 */
export const deleteBannerImage = async (id) => {
  const functionUrl = "home_Banner";
  try {
    return await callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        return response;
      })
      // .catch((error) => {
      //   console.log("Error from API => ", error);
      // });
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{get single project) by id
 */
export const getBannerImages = async (filter) => {
  const functionUrl = "homeBanners";
  try {
    return await callApi(functionUrl, {}, "GET", filter)
      .then((response) => {
        return response;
      })
      // .catch((error) => {
      //   console.log("Error from API => ", error);
      // });
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};

/**
 * Common Api for upload file or profile pic.
 * @param(file, callback)
 * @return API response
 */
export const uploadFile = async (requestData, callBack) => {
  const functionUrl = "homeBanner";
  try {
    // const response = await uploadApi(functionUrl, requestData, callBack);
    const uploadApi = (functionUrl, pictureData, callBack, type) => {
      const url = global.baseUrl + global.apiUrls[functionUrl];

      let formData = new FormData();
      formData.append("image", pictureData.image);
      formData.append("type", pictureData.type);
      formData.append("id", pictureData.id);
      formData.append("documentType", pictureData.documentType);
      if (pictureData.updated_by) {
        formData.append("updatedBy", pictureData.updated_by);
      } else {
        formData.append("createdBy", pictureData.created_by);
      }

      // Starting Http Request
      const xhr = new XMLHttpRequest();
      const method = "POST";
      xhr.open(method, url);
      addListeners(xhr, callBack);
      // xhr.onprogress = function () {
      //   console.log("LOADING", xhr.status);
      // };
      xhr.onload = function () {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.response);
          // returning the response in callBack page
          callBack(response);
        }
      };
      // Ending Http Request
      xhr.send(formData);
    };
    const response = uploadApi(functionUrl, requestData, callBack);

    if (response) {
      return callBack(response);
    }
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};

const addListeners = (xhr, callBack) => {
  xhr.addEventListener("loadstart", callBack);
  xhr.addEventListener("load", callBack);
  xhr.addEventListener("loadend", callBack);
  xhr.addEventListener("progress", callBack);
  xhr.addEventListener("error", callBack);
  xhr.addEventListener("abort", callBack);
  xhr.addEventListener("response", callBack);
};
