import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../images/logo.svg";
import "../css/Common.css";
import "../css/Nav.css";
import "../css/Navbar.css";
import ConsultModal from "./ConsultModal";
import { HashLink } from "react-router-hash-link";
// import { serviceCategories } from "./serviceData";

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
      <header>
        <div className="brand-container">
          <a href="/" className="logo">
            <img src={Logo} alt="The Dezign Code Logo" width="100%" />
          </a>
        </div>
        <div className="navdata-container">
          <div className="ctalink-container d-flex align-items-center">
            <div>
              <ConsultModal />
            </div>
            <a
              onClick={() => window.gtag_report_conversion()}
              href="tel:+91 6366939113"
              className="cont-btn theme-font-color text-decoration-none"
            >
              <i className="icon-size fas fa-phone-alt"></i>{" "}
              <span className="hidesm">Call +91 6366939113</span>
            </a>
          </div>
          <div id="menu-btn" className="open">
            <i
              className="icon-size fas fa-align-right"
              onClick={handleToggle}
            ></i>
          </div>
          <nav id="nav" className={toggle === true ? "open-nav" : null}>
            <div id="exit-btn" className="exit">
              <i
                className="icon-size far fa-times-circle"
                onClick={handleToggle}
              ></i>
            </div>
            <ul className="ulnav">
              <li
                className="nav-item dropdown font-style"
                style={{ display: "block" }}
              >
                <Link
                  className={
                    splitLocation[1] === "" || splitLocation[1] === "home"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/"
                >
                  Home
                </Link>
                <div className="dropdown-content">
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/home#how-we-work"
                      className="d-flex justify-content-between"
                    >
                      <span>How we work</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/home#testimonial"
                      className="d-flex justify-content-between"
                    >
                      <span>Testimonial</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/home#our-client"
                      className="d-flex justify-content-between"
                    >
                      <span>Our Client</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/home#brand-partner"
                      className="d-flex justify-content-between"
                    >
                      <span>Brand Partner</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                </div>
              </li>
              <li
                className="nav-item dropdown font-style"
                style={{ dispaly: "block" }}
              >
                <Link
                  to="/about-us"
                  className={
                    splitLocation[1] === "about-us"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  About Us
                </Link>
                <div className="dropdown-content">
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/about-us#our-founder"
                      className="d-flex justify-content-between"
                    >
                      <span>Our Founders</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/about-us#our-team"
                      className="d-flex justify-content-between"
                    >
                      <span>Our Team</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                </div>
              </li>
              <li
                className="nav-item dropdown font-style"
                style={{ display: "block" }}
              >
                <Link
                  to="/services/residential-interior"
                  className={
                    splitLocation[1] === "services"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Services
                </Link>

                <div className="dropdown-content">
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/services/residential-interior"
                      className="d-flex justify-content-between"
                    >
                      <span>Residential Interior</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/services/commercial-interior"
                      className="d-flex justify-content-between"
                    >
                      <span>Commercial Interior</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                  {/* <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/services#3"
                      className="d-flex justify-content-between"
                    >
                      <span>Renovation</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li> */}
                </div>
                {/* <div className="dropdown-content" >
                  {
                    serviceCategories.map((category) => (
                      <div className="font-style">
                      {" "}
                      <HashLink
                        smooth
                        to={`/services/${category.value}`}
                        className="d-flex justify-content-between"
                        style={{ padding: "10px 14px"}}
                      >
                        <span>{category.label}</span>{" "}
                        <i className="fas fa-arrow-right"></i>
                      </HashLink>
                    </div>
                    ))
                  }
                </div> */}
              </li>
              <li className="nav-item font-style">
                <Link
                  to="/our-projects"
                  className={
                    splitLocation[1] === "our-projects"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Our Projects
                </Link>
              </li>
              <li className="nav-item font-style">
                <Link
                  to="/theme-homes"
                  className={
                    splitLocation[1] === "theme-homes"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Theme Home
                </Link>
              </li>
              <li className="nav-item font-style">
                <Link
                  to="/blog"
                  className={
                    splitLocation[1] === "blog" ? "nav-link active" : "nav-link"
                  }
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item dropdown  font-style">
                <Link
                  to="/offers"
                  className={
                    splitLocation[1] === "offers"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Offers
                </Link>
                <div className="dropdown-content">
                  <li className="font-style">
                    {" "}
                    <HashLink
                      smooth
                      to="/offers#refer-friend"
                      className="d-flex justify-content-between"
                    >
                      <span>Refer a friend</span>{" "}
                      <i className="fas fa-arrow-right"></i>
                    </HashLink>
                  </li>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
