// Manage service page

import React from "react";
import { Layout } from "../../common/Element";
import "../../css/DataTable.css";
import CreateService from "./CreateService";
import Icomoon from "../../libraries/Icomoon";
import Modal from "react-bootstrap/Modal";
import AppConfig from "../../modal/AppConfig";
import { serviceList, deleteService, uploadFile } from "../../apis/Service";
import {
  createGallery,
  updateGallery,
  deleteGallery,
  updateServiceGalleryImageApi,
} from "../../apis/ServiceGallery";
import { callApi } from "../../apis/Api";
import { observer } from "mobx-react";
import ConfirmModal from "../../common/ConfirmModal";
import User from "../../modal/User";
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Table } from "react-bootstrap";
import { serviceCategories } from "../serviceData";
import { MultipleFileUpload } from "../../apis/ServiceGallery";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class ManageServices extends React.Component {
  state = {
    loading: false,
    size: 10,
    current_page: 1,
    rows: [],
    viewServiceGallery: false,
    files: [],
    uploadPics: null,
    totalData: 0,
    page: 0,
    editServiceId: "",
    viewServiceGalleryId: "", 
    status: false,
    viewServiceConfirmModal: false,
    galleryCollection: [],
    editGallaryId: "",
    viewGalleryConfirmModal: false,
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  async componentDidMount() {
    await this.serviceListApi();
  }

  serviceListApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
    };
    const response = await serviceList(requestData);
    if (response && response.status === "success") {
      const result = response.result;
      let apiRows = [];

      for (let i in result.data) {
        const type = serviceCategories.find(
          (item) => item.value === result.data[i].serviceType
        )?.displayValue;

        const row = {
          id: result.data[i].id,
          slNumber: Number(i) + 1,
          title: result.data[i].title,
          category: result.data[i].category?.split("-")?.join(" "),
          type: type,
        };
        apiRows.push(row);
      }
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  galleryApi = async () => {
    // View Gallery api
    const { viewServiceGalleryId } = this.state;
    let galleryFunctionUrl = "serviceGallery";
    const galleryResponse = await callApi(
      galleryFunctionUrl,
      {},
      "GET",
      viewServiceGalleryId
    );
    const galleryCollection = galleryResponse.result.serviceGalleries;
    this.setState({ galleryCollection: galleryCollection, loading: false });
  };

  render() {
    return (
      <Layout>
        <CustomLoaderModal loader={this.state.loading} />
        <div className="px-5 pb-5" style={{ marginTop: "4rem" }}>
          <div className="d-flex justify-content-between">
            <p className="theme-font-color align-self-center">
              Manage Services
            </p>
            <button
              className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
              onClick={() => this.setState({ status: true, editServiceId: "" })}
            >
              CREATE SERVICE
            </button>
          </div>
          {this.renderTable()}
          {this.renderUploadImageModal()}
        </div>
        <CreateService
          id={AppConfig.user_id}
          edit={this.state.editServiceId}
          status={this.state.status}
          closeModel={() => this.setState({ status: false, editServiceId: "" })}
          serviceListApi={this.serviceListApi}
        />
        <ConfirmModal
          delete={true}
          visible={this.state.viewServiceConfirmModal}
          heading="Delete Service"
          title="Are you sure you want to delete the service?"
          confirm={() => this.getServiceSuccess()}
          handleClose={() => this.setState({ viewServiceConfirmModal: false })}
        />
        <ConfirmModal
          delete={true}
          visible={this.state.viewGalleryConfirmModal}
          heading="Delete Picture"
          title="Are you sure you want to delete the picture?"
          confirm={() => this.getGallerySuccess()}
          handleClose={() =>
            this.setState({ viewGalleryConfirmModal: false, uploadPics: null })
          }
        />
      </Layout>
    );
  }

  headCells = [
    {
      id: "slNumber",
      label: "#",
      width: "5%",
      align: "center",
    },
    {
      id: "title",
      label: "Title",
      width: "35%",
    },
    {
      id: "category",
      label: "Category",
      width: "25%",
    },
    {
      id: "type",
      label: "Type",
      width: "25%",
    },
    {
      id: "action",
      label: "Action",
      width: "20%",
      align: "center",
    },
  ];

  handleRequestSort = (property) => {
    this.setState((prevState) => {
      const { orderBy, order, rows } = prevState;
      const isAsc = orderBy === property && order === "asc";

      const sortedRows = [...rows].sort((a, b) => {
        if (property === "slNumber") {
          return isAsc ? a.slNumber - b.slNumber : b.slNumber - a.slNumber;
        }
        if (property === "title") {
          return isAsc
            ? a.title.localeCompare(b.title)
            : b.title.localeCompare(a.title);
        }
        if (property === "category") {
          return isAsc
            ? a.category.localeCompare(b.category)
            : b.category.localeCompare(a.category);
        }
        if (property === "type") {
          return isAsc
            ? a.type.localeCompare(b.type)
            : b.type.localeCompare(a.type);
        }
        return 0;
      });

      return {
        orderBy: property,
        order: isAsc ? "desc" : "asc",
        rows: sortedRows,
      };
    });
  };

  renderTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%" }}
            style={{
              border: "1px solid #E40045",
              borderRadius: "5px",
              backgroundColor: "#f7f7f7",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow style={{ borderColor: "red" }}>
                    {this.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={
                          this.state.orderBy === headCell.id
                            ? this.state.order
                            : false
                        }
                        width={headCell.width}
                        align={headCell.align ? headCell.align : "left"}
                      >
                        <TableSortLabel
                          active={this.state.orderBy === headCell.id}
                          direction={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : "asc"
                          }
                          onClick={() => this.handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.rows.map((row) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell
                          align="left"
                          style={{ padding: "15px 10px" }}
                        >
                          {" "}
                          {row.slNumber}{" "}
                        </TableCell>
                        <TableCell align="left" style={{ padding: "15px 5px" }}>
                          {" "}
                          {row.title || "-"}{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-truncate"
                          style={{
                            maxWidth: "150px",
                            padding: "15px 5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {row.category || "-"}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-truncate"
                          style={{
                            maxWidth: "150px",
                            padding: "15px 5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {row.type || "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "15px 5px" }}
                        >
                          <div className="d-flex justify-content-center">
                            <Icomoon
                              className="pointer mx-2"
                              icon="pen-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.editService(row.id)}
                            />
                            <Icomoon
                              className="pointer mx-2"
                              icon="trash-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.deleteService(row.id)}
                            />
                            <i
                              className="fas fa-images theme-font-color big-font-size mx-2 pointer"
                              onClick={() => this.viewServiceGallery(row.id)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {this.state.rows.length === 0 && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "300px",
                  }}
                >
                  <p>No records found</p>
                </div>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.size}
              page={this.state.page}
              onPageChange={(e, newPage) =>
                this.setState(
                  {
                    size: this.state.size,
                    current_page: newPage + 1,
                    page: newPage,
                  },
                  () => this.serviceListApi()
                )
              }
              onRowsPerPageChange={(e) =>
                this.setState({ size: e.target.value, current_page: 1 }, () =>
                  this.serviceListApi()
                )
              }
            />
          </Paper>
        </Box>
      </div>
    );
  };

  // Render upload picture modal function

  renderUploadImageModal = () => {
    return (
      <Modal
        size="xl"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.viewServiceGallery}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              Service Gallery
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.setState({
                  viewServiceGallery: false,
                  uploadPics: null,
                  uploadImageError: "",
                });
              }}
            />
          </div>
          <Modal.Body>
            <div className="row p-3">
              {this.state.galleryCollection.map((galleryCollection, index) => (
                <div className="col-md-3 p-2 mx-0" key={galleryCollection.id}>
                  <img
                    src={galleryCollection.image}
                    alt={galleryCollection.id}
                    width="100%"
                    height="175px"
                  />
                  <div className="d-flex my-3">
                    <Icomoon
                      className="pointer mx-2"
                      icon="pen-solid"
                      size={16}
                      color="#E40045"
                      onClick={(e) => {
                        this.setState({ editGallaryId: galleryCollection.id });
                        this.handleUploadPicsSelect(e);
                      }}
                    />
                    <Icomoon
                      className="pointer mx-2"
                      icon="trash-solid"
                      size={16}
                      color="#E40045"
                      onClick={() => {
                        this.deleteGallery(galleryCollection.id);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            {this.state.uploadPics ? (
              <div>{this.renderUploadedPic()}</div>
            ) : null}
            <form
              onSubmit={this.onSubmitGalleryPic}
              encType="multipart/form-data"
            >
              <div className="d-flex justify-content-between">
                <div>
                  <input
                    type="file"
                    multiple
                    id="uploadPics"
                    className="d-none"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.selectUploadPics}
                  />
                  {/* <div>
                    <button
                      className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                      onClick={this.handleUploadPicsSelect}
                    >
                      <i className="fas fa-upload" />
                      <span className="mx-3">Upload</span>
                    </button>
                  </div> */}

                  <input
                    type="file"
                    multiple
                    id="mutipleUploadPics"
                    className="d-none"
                    accept=".png, .jpg, .jpeg"
                    onChange={this.handleFileChange}
                  />
                  <button
                    className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                    onClick={this.handleMultipleUploadPicsSelect}
                  >
                    <i className="fas fa-upload" />
                    <span className="mx-3">Multiple Upload</span>
                  </button>
                </div>
                {/* <button
                  type="submit"
                  className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                >
                  <span className="mx-3">Save</span>
                </button> */}
                <button
                  type="button"
                  onClick={() => {
                    this.setState({
                      viewServiceGallery: false,
                      viewServiceGalleryId: ""
                    });
                  }}
                  className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
                >
                  <span className="mx-3">Close</span>
                </button>
              </div>
              <div className="d-flex justify-content-start">
                {this.state.uploadImageError && (
                  <span className="small-font-size text-danger">
                    {" "}
                    {this.state.uploadImageError}
                  </span>
                )}
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  handleMultipleUploadPicsSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("mutipleUploadPics");
    fileSelector.click();
  };

  handleFileChange = async (e) => {
    const files = e.target.files;
    this.setState({ loading: true });
    MultipleFileUpload(files, this.callBackMultipleUpload, this.state.viewServiceGalleryId, User.user_id);
  };

  callBackMultipleUpload = (response = false) => {
    if (response && response.status === "success") {
      AppConfig.setMessage(response.message, false);
      this.viewServiceGallery(this.state.viewServiceGalleryId);
      // this.setState({ loading: false });
    } 
    else if (response && response.statu === "error") {
      AppConfig.setMessage(response.message, false);
      // this.viewServiceGallery(this.state.viewServiceGalleryId);
      this.setState({ loading: false });
    } 
  };

  // Handle file select

  handleUploadPicsSelect = (e) => {
    e.preventDefault();
    this.setState({ uploadImageError: "" });
    const fileSelector = document.getElementById("uploadPics");
    fileSelector.click();
  };

  // Select file

  selectUploadPics = (e) => {
    e.preventDefault();
    const uploadPics = e.target.files[0];
    this.setState({ loading: true });
    const functionUrl = 'serviceGallery';
    const projectId = this.state.viewServiceGalleryId;
    const galleryId = this.state.editGallaryId;
    const userId = User.user_id;
    updateServiceGalleryImageApi(functionUrl, uploadPics, this.callBackUploadPics, galleryId, projectId, userId);
    // const type = "uploadPics";
    // uploadFile(uploadPics, this.callBackUploadPics, type);
  };

  //  Call back function for uploaded pics
  callBackUploadPics = (response = false) => {
    if (response && response.status === "success") {
      AppConfig.setMessage("Service image updated successfully", false);
      this.viewServiceGallery(this.state.viewServiceGalleryId);
      // this.setState({ loading: false });
    } else if (response && response.status === "error") {
      AppConfig.setMessage("Service image updating failed");
      this.setState({ loading: false });
    }
    // if (response && response.status === "success") {
    //   this.setState({ uploadPics: response.result.serviceImage });
    // }
  };

  //Render uploaded pic

  renderUploadedPic = () => {
    return (
      <div className="col-md-4">
        <div className="col-md-12 rounded bg-white">
          <div className="row">
            <img src={this.state.uploadPics} alt="pic" />
          </div>
        </div>
        <div className="mt-2 px-3">
          <i
            className="fas fa-edit theme-font-color big-font-size mx-2 pointer"
            onClick={this.handleUploadPicsSelect}
          />
          <i
            className="fas fa-trash-alt theme-font-color big-font-size mx-2 pointer"
            onClick={() => this.setState({ uploadPics: "" })}
          ></i>
        </div>
      </div>
    );
  };

  // Edit service modal
  editService = (id) => {
    this.setState({ editServiceId: id, status: true });
  };

  // Delete service confirm modal
  deleteService = (id) => {
    this.setState({ viewServiceConfirmModal: true, deleteId: id });
  };

  // Delete service api function
  getServiceSuccess = () => {
    const id = this.state.deleteId;
    deleteService(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewServiceConfirmModal: false });
        AppConfig.setMessage("Deleted Successfully", false);
        this.serviceListApi();
        this.props.history.push("/manage-service");
      } else {
        this.setState({ viewServiceConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };

  // View service gallary modal and display the gallary  pics by calling gallary api

  viewServiceGallery = (id) => {
    this.setState(
      { viewServiceGallery: true, viewServiceGalleryId: id, loading: true },
      () => {
        this.galleryApi();
      }
    );
  };

  // Create and update uploaded gallery images
  onSubmitGalleryPic = async (e) => {
    e.preventDefault();
    const id = this.state.editGallaryId;
    let requestData = {
      user_id: User.user_id,
      serviceId: this.state.viewServiceGalleryId,
      image: this.state.uploadPics,
    };

    let response = "";
    if (requestData.image) {
      if (id) {
        requestData["updatedBy"] = User.user_id;
        response = await updateGallery(requestData, id);
      } else {
        requestData["createdBy"] = User.user_id;
        response = await createGallery(requestData);
      }
    } else {
      return this.setState({ uploadImageError: "Please upload an service image" });
    }
    if (response.status === "success") {
      this.setState({
        viewServiceGallery: false,
        uploadPics: null,
        editGallaryId: "",
      });
      this.viewServiceGallery(this.state.viewServiceGalleryId);
      AppConfig.setMessage("Picture uploaded successfully", false);
    } else if (response.status === "error") {
      this.setState({ viewServiceGallery: false, uploadPics: null });
      AppConfig.setMessage(response.result);
    }
  };

  // Delete gallery confirm modal
  deleteGallery = (id) => {
    this.setState({ viewGalleryConfirmModal: true, deleteGalleryId: id, uploadImageError: "" });
  };

  // // Delete gallery api function
  getGallerySuccess = () => {
    const id = this.state.deleteGalleryId;
    deleteGallery(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewGalleryConfirmModal: false });
        this.viewServiceGallery(this.state.viewServiceGalleryId);
        AppConfig.setMessage("Deleted Successfully", false);
        // this.props.history.push("/manage-service");
      } else {
        this.setState({ viewServiceConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };
}
export default observer(ManageServices);
