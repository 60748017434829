
// User name validation
export const CheckUserName = name => {
    // const regex = /^[a-zA-Z]+[a-zA-Z]{1,64}$/;
    const regex = /^[a-zA-Z ]+[a-zA-Z ]{0,64}$/;
    let errorCode = null;
    if (name === '' || typeof name === 'undefined') {
        errorCode = 1;
    } else if (!regex.test(name)) {
        errorCode = 2;
    }
    return errorCode;
};

// Email validation
export const CheckEmail = email => {
    const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    let errorCode = null;
    if (email === '' || typeof email === 'undefined') {
        errorCode = 1;
    } else if (!regex.test(email)) {
        errorCode = 2;
    }
    return errorCode;
};

// Phone validation
export const CheckPhone = phone => {
    const regex = /^[0-9]{10}$/;
    let errorCode = null;
    if (phone === '' || typeof phone === 'undefined') {
        errorCode = 1;
    } else if (!regex.test(phone)) {
        errorCode = 2;
    }
    return errorCode;
};

// Dropdown validation

// create Validation for dropdown
export const DropDownCheck = (value) => {
    let errorCode = null;
    if (value === "" || typeof value === "undefined") {
        errorCode = 1;
    }
    return errorCode;
};

// create validation for date
export const CheckDob = (value) => {
    let errorCode = null;
    if (!value) {
        errorCode = 1;
    }
    return errorCode;
};

// Password validation

export const CheckPassword = password => {
    const regex1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$|!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let errorCode = null;
    if (password === '') {
        errorCode = 1;
    } else if (!regex1.test(password)) {
        errorCode = 2;
    }
    return errorCode;
};

/**
 *
 * @param(OTP)
 * @return Error code or null
 */
export const OtpCheck = (otp) => {
    const numberCheck = /[0-9]/;
    let errorCode = null;
    if (!otp) {
        errorCode = 1;
    } else if (!numberCheck.test(otp) || otp.length < 6) {
        errorCode = 2;
    }
    return errorCode;
};

// compare Password in password and confirm password
export const ComparePassword = (password, confirmPassword) => {
    let errorCode = null;
    if (password !== confirmPassword) {
        errorCode = 1;
    }
    return errorCode;
};


