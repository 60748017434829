import React from 'react';
import '../css/Common.css';
import Policy from '../images/policies/policy.png'
import {callApi} from '../apis/Api';
import Header from './Header';

class Policies extends React.Component{
    state={
        policies:[]
    }
    async componentDidMount() {
        window.scrollTo(0, 0)
        let functionUrl = 'policies';
        const response = await callApi(functionUrl, {}, 'GET');
        const result = response.result
        let policies = [];
            for(let i in result) {
                policies.push(result[i]);
            }
        this.setState({
            policies:policies
            
        })	
	}
    render() {
        return(
            <>
                 <Header/>
                <div className="d-flex align-items-center service-bg" style={{ backgroundImage: `url(${Policy})`}}>
                    <div className="col-md-12 row align-items-center justify-content-between mt-5">
                        <div className="mx-5 mt-5 pt-5">
                            <h1 className="text-white font-weight-bold font-style2 mx-5 px-3 pt-5">OUR POLICIES</h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    {this.renderContent()}
                </div> 
            </>
        )
    }

    // Render warrenty function
    renderContent = () => {
        return(
            <>
                {this.state.policies.map((policies) => (
                    <div className='m-5'>
                        <h1 className="theme-font-color font-weight-bold font-style2">{policies.title ? policies.title:null}</h1> 
                        <p className="font-style">{policies.description ? policies.description:null}</p>  
                    </div>
                ))}
            </>
        )
    }
}
export default Policies
