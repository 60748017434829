import React from "react";
import "../../css/Common.css";
import Icomoon from "../../libraries/Icomoon";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AppConfig from "../../modal/AppConfig";
import Modal from "react-bootstrap/Modal";
import "react-quill/dist/quill.snow.css";
import { observer } from "mobx-react";
import User from "../../modal/User";
import { createFAQ, getFAQ, updateFAQ } from "../../apis/FAQs";
import ReactQuill from "react-quill";
import { serviceCategories } from "../serviceData";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class CreateFAQs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      question: "",
      answer: "",
      type: "",
      // category: "",
      order: "",
      questionError: "",
      answerError: "",
      typeError: "",
      // categoryError: "",
      orderError: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  resetState = () => {
    this.setState({
      id: "",
      question: "",
      answer: "",
      type: "",
      // category: "",
      order: "",
      questionError: "",
      answerError: "",
      typeError: "",
      // categoryError: "",
      orderError: "",
    });
  };

  async componentDidUpdate(prevProps) {
    // const id = User.user_id;

    if (this.props.edit && this.props.edit !== prevProps.edit) {
      const id = this.props.edit;
      const response = await getFAQ(id);
      const result = response.result;
      if (response && response.status === "success") {
        this.setState({
          id: id,
          question: result.question,
          answer: result.answer,
          type: result.type,
          category: result.category,
          order: result.order,
        });
      }
    }
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  handleChange(value) {
    this.setState({ answer: value });
  }

  render() {
    return (
      <div>
        <CustomLoaderModal loader={this.state.loading} />
        {this.renderFAQsModal()}
      </div>
    );
  }

  // Render create faqs modal function
  renderFAQsModal = () => {
    return (
      <Modal
        size="lg"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.status}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              {this.props.edit ? "Edit FAQ" : "Create FAQ"}
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.props.closeModel(false);
                this.resetState();
              }}
            />
          </div>
          <Modal.Body>
            <form
              onSubmit={this.onSubmitCreateFAQ}
              encType="multipart/form-data"
            >
              <div>
                {/* <FormControl className="col-md-12 mb-3 col-lg-12 me-1">
                  <InputLabel id="category">Category</InputLabel>
                  <Select
                    labelId="category"
                    id="category"
                    value={this.state.category}
                    onChange={(e) => {
                      this.setState({
                        category: e.target.value,
                        categoryError: "",
                      });
                    }}
                    onFocus={() => this.setState({ categoryError: "" })}
                  >
                    <MenuItem value="residential-interior">
                      Residential Interior
                    </MenuItem>
                    <MenuItem value="commercial-interior">
                      Commercial Interior
                    </MenuItem>
                  </Select>
                </FormControl>

                <div className="d-flex justify-content-start">
                  {this.state.categoryError && (
                    <span className="small-font-size text-danger">
                      {this.state.categoryError}
                    </span>
                  )}
                </div> */}

                <FormControl className="col-md-12 mb-3 col-lg-12 me-1">
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    value={this.state.type}
                    onChange={(e) => {
                      this.setState({
                        type: e.target.value,
                        typeError: "",
                      });
                    }}
                    onFocus={() => {
                      this.setState({
                        typeError: "",
                      });
                    }}
                  >
                    {/* {serviceCategories.filter((item) => item.category === this.state.category)?.map((category) => (
                      <MenuItem value={category.value} key={category.value}>
                        {category.label}
                      </MenuItem>
                    ))} */}
                    {serviceCategories.map((category) => (
                      <MenuItem value={category.value} key={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="d-flex justify-content-start">
                  {this.state.typeError && (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.typeError}
                    </span>
                  )}
                </div>
                <TextField
                  value={this.state.question}
                  label="Question"
                  onChange={(e) => {
                    this.setState({
                      question: e.target.value,
                      questionError: "",
                    });
                  }}
                  onFocus={() => this.setState({ questionError: "" })}
                  multiline
                  rows={2}
                  className="col-md-12 my-2"
                />
                <div className="d-flex justify-content-start">
                  {this.state.questionError ? (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.questionError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="my-4">
                  <ReactQuill
                    value={this.state.answer}
                    onChange={this.handleChange}
                    onFocus={() => this.setState({ answerError: "" })}
                    modules={this.modules}
                    formats={this.formats}
                    placeholder="Please enter answer"
                    style={{
                      height: "150px",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-start">
                  {this.state.answerError && (
                    <span className="small-font-size text-danger pt-4">
                      {" "}
                      {this.state.answerError}
                    </span>
                  )}
                </div>

                <TextField
                  className={`col-md-12 ${
                    this.state.answerError ? "mt-2" : "mt-5"
                  }`}
                  id="order"
                  label="FAQ order number"
                  value={this.state.order}
                  onChange={(e) =>
                    this.setState({
                      order: e.target.value,
                      orderError: "",
                    })
                  }
                  onFocus={() => this.setState({ orderError: "" })}
                />

                <div className="d-flex justify-content-start">
                  {this.state.orderError && (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.orderError}
                    </span>
                  )}
                </div>

                <button
                  type="submit"
                  className="theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-2 mt-5 font-style"
                >
                  {this.props.edit ? "UPDATE" : "CREATE"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Validation for question
  validateQuestion = (value) => {
    if (value.trim() === "") {
      this.setState({ questionError: "Please enter question" });
      return false;
    } else return true;
  };

  // Validation for answer
  validateAnswer = (value) => {
    if (value.trim() === "") {
      this.setState({ answerError: "Please enter answer" });
      return false;
    } else return true;
  };

  // Validation for type
  validateType = (value) => {
    if (value.trim() === "") {
      this.setState({ typeError: "Please select type" });
      return false;
    } else return true;
  };

  // Validation for category
  // validateCategory = (value) => {
  //   if (value === "") {
  //     this.setState({ categoryError: "Please select category" });
  //     return false;
  //   } else return true;
  // };

  // Validation for order
  validateOrder = (value) => {
    if (value === "") {
      this.setState({ orderError: "Please enter faq order number" });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const questionError = this.validateQuestion(this.state.question);
    const answerError = this.validateAnswer(this.state.answer);
    const typeError = this.validateType(this.state.type);
    // const categoryError = this.validateCategory(this.state.category);
    const orderError = this.validateOrder(this.state.order);

    if (questionError && answerError && typeError && orderError) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit create faq form function
  onSubmitCreateFAQ = async (e) => {
    e.preventDefault();
    const id = this.props.edit;
    const allValidation = this.ValidateAll();
    if (allValidation) {
      this.setState({ loading: true });
      const category = serviceCategories.find(
        (item) => item.value === this.state.type
      ).category;
      const requestData = {
        question: this.state.question,
        answer: this.state.answer,
        type: this.state.type,
        // category: this.state.category,
        category: category,
        order: this.state.order,
      };

      if (this.props.edit) {
        requestData["updatedBy"] = User.user_id;
      } else {
        requestData["createdBy"] = User.user_id;
      }
      if (this.props.edit) {
        const response = await updateFAQ(requestData, id);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.FAQsListApi();
          AppConfig.setMessage("FAQ updated successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      } else {
        const response = await createFAQ(requestData);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.FAQsListApi();
          AppConfig.setMessage("FAQ created successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      }
      this.setState({ loading: false });
    }
  };
}
export default observer(CreateFAQs);
