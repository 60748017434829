// Manage Content page

import React from "react";
import { Layout } from "../../common/Element";
import "react-quill/dist/quill.snow.css";
import { observer } from "mobx-react";
import "../../css/ManageContent.css";
import AppConfig from "../../modal/AppConfig";
import CreateClient from "./CreateClient";
import { clientList, deleteClient } from "../../apis/Client";
import { TablePagination } from "@material-ui/core";
import Icomoon from "../../libraries/Icomoon";
import ConfirmModal from "../../common/ConfirmModal";

class ManageClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: "",
      imageUrl: "", 
      editClientId: "",
      totalData: 0,
      page: 0,
      size: 10,
    };
  }
  async componentDidMount() {
    await this.clientListApi();
  }

  clientListApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
    };
    const response = await clientList(requestData);
    if (response && response.status === "success") {
      const result = response.result;
      const apiRows = result?.data?.map(( row, index )=> ({
        id: row.id,
        slNumber: Number(index) + 1,
        clientName: row.clientName,
        imageUrl: row.image
      }))
      this.setState({ clientsListData: apiRows, totalData: result.total });
    }
  };

  // Delete client confirm modal
  deleteClient = (id) => {
    this.setState({ deleteClientConfirmModal: true, deleteClientId: id });
  };

  
  // Delete client api function
  deleteClientData = () => {
    const id = this.state.deleteClientId;
    deleteClient(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ deleteClientConfirmModal: false });
        AppConfig.setMessage("Deleted Successfully", false);
        this.clientListApi();
      } else {
        this.setState({ deleteClientConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };

  render() {
    return (
      <Layout>
        <div className="px-md-5 px-3" style={{ marginTop: "5.5rem" }}>
          <div className="d-flex justify-content-between">
            <p className="theme-font-color">Manage clients</p>
            <button
              className="btn theme-bg-color text-white normal-font-size font-style py-2"
              onClick={() => this.setState({ status: true, editClientId: '' })}
            >
              CREATE CLIENT
            </button>
          </div>

          <div className="container-md">
            <div className="row p-md-2">
              {
                this.state.clientsListData?.length > 0 && this.state.clientsListData?.map((client) => (
                  <div className="col-12 col-md-4 p-md-3" key={client.id}>
                    <p className="mb-0 pb-1">{client.clientName}&nbsp;</p>
                    {
                      client?.imageUrl && (
                        <div>
                          <img src={client.imageUrl} alt="clientImage" className="w-100" />
                          <div className="d-flex my-3">
                            <Icomoon
                              className="pointer mx-2"
                              icon="pen-solid"
                              size={16}
                              color="#E40045"
                              onClick={(e) => {
                                this.setState({ editClientId: client.id, status: true  });
                              }}
                            />
                            <Icomoon
                              className="pointer mx-2"
                              icon="trash-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => {
                                this.deleteClient(client.id);
                              }}
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </div>
          </div>
          {
            this.state.clientsListData?.length > 0 && 
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={this.state.totalData}
                rowsPerPage={this.state.size}
                page={this.state.page}
                onPageChange={(e, newPage) => 
                  this.setState({ size: this.state.size, current_page: newPage + 1, page: newPage }, () =>
                  this.clientListApi()
                )}
                onRowsPerPageChange={(e) =>
                  this.setState({ size: e.target.value, current_page: 1 }, () =>
                    this.clientListApi()
                  )
                }
              />
          }
        </div>

        <CreateClient
          id={AppConfig.user_id}
          edit={this.state.editClientId}
          status={this.state.status}
          closeModel={() => this.setState({ status: false, editClientId: "" })}
          clientListApi={this.clientListApi}
        />

        <ConfirmModal
          delete={true}
          visible={this.state.deleteClientConfirmModal}
          heading="Delete Client"
          title="Are you sure you want to delete the client?"
          confirm={() => this.deleteClientData()}
          handleClose={() => this.setState({ deleteClientConfirmModal: false })}
        />
      </Layout>
    );
  }
}

export default observer(ManageClients);
