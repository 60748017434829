import React from "react";
import "../../css/Common.css";
import Icomoon from "../../libraries/Icomoon";
import { TextField } from "@material-ui/core";
import { CheckUserName } from "../../common/Validation";
import AppConfig from "../../modal/AppConfig";
import Modal from "react-bootstrap/Modal";
import "react-quill/dist/quill.snow.css";
import { observer } from "mobx-react";
import User from "../../modal/User";
import {
  createTestimonial,
  getTestimonial,
  updateTestimonial,
  uploadFile,
} from "../../apis/Testimonial";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class CreateTestimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      description: "",
      createTestimonial: false,
      testimonialImage: "",
      reviewLink: "",
      nameError: "",
      reviewLinkError: "",
      descriptionError: "",
    };
  }

  resetState = () => {
    this.setState({
      id: "",
      description: "",
      createTestimonial: false,
      name: "",
      testimonialImage: "",
      reviewLink: "",
      nameError: "",
      reviewLinkError: "",
      descriptionError: "",
    });
  };

  async componentDidUpdate(prevProps) {
    // const id = User.user_id;

    if (this.props.edit && this.props.edit !== prevProps.edit) {
      const id = this.props.edit;
      const response = await getTestimonial(id);
      const result = response.result;
      if (response && response.status === "success") {
        this.setState({
          id: id,
          name: result.name,
          description: result.description,
          testimonialImage:
            result.image && (result.image !== "null" || null)
              ? result.image
              : "",
          reviewLink:
            result.review_link && (result.review_link !== "null" || null)
              ? result.review_link
              : "",
        });
      }
    }
  }

  render() {
    return (
      <div>
        <CustomLoaderModal loader={this.state.loading} />

        {this.renderCreateTestimonialModal()}
      </div>
    );
  }

  // Render create testimonial modal function
  renderCreateTestimonialModal = () => {
    return (
      <Modal
        size="md"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.status}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              {this.props.edit ? "Edit Testimonial" : "Create Testimonial"}
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.props.closeModel(false);
                this.resetState();
              }}
            />
          </div>
          <Modal.Body>
            <form
              onSubmit={this.onSubmitCreateTestimonial}
              encType="multipart/form-data"
            >
              <div>
                <TextField
                  className="col-md-12 my-2"
                  id="name"
                  label="Name"
                  value={this.state.name}
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                      nameError: "",
                    })
                  }
                  onFocus={() => this.setState({ nameError: "" })}
                />
                <div className="d-flex justify-content-start">
                  {this.state.nameError ? (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.nameError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <TextField
                  className="col-md-12 my-2"
                  id="review-link"
                  label="Review Link"
                  value={this.state.reviewLink}
                  onChange={(e) => {
                    this.validateReviewLink(e.target.value);
                    this.setState({
                      reviewLink: e.target.value,
                      // reviewLinkError: "",
                    });
                  }}
                  onFocus={() => this.setState({ reviewLinkError: "" })}
                />
                <div className="d-flex justify-content-start">
                  {this.state.reviewLinkError && (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.reviewLinkError}
                    </span>
                  )}
                </div>

                <TextField
                  value={this.state.description}
                  label="Description"
                  onChange={(e) => {
                    this.setState({
                      description: e.target.value,
                      descriptionError: "",
                    });
                  }}
                  onFocus={() => this.setState({ descriptionError: "" })}
                  multiline
                  placeholder="Description"
                  rows={4}
                  className="col-md-12 my-2"
                />

                <div className="d-flex justify-content-start">
                  {this.state.descriptionError && (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.descriptionError}
                    </span>
                  )}
                </div>

                {this.state.testimonialImage ? (
                  <div className="col-md-12 my-2">
                    {this.renderTestimonialImage()}
                  </div>
                ) : (
                  <div className="my-2">
                    <input
                      type="file"
                      id="testimonialImage"
                      className="d-none"
                      accept=".png, .jpg, .jpeg"
                      onChange={this.selectImageFile}
                    />
                    <button
                      className="btn theme-bg-color text-white small-font-size font-style py-2"
                      onClick={this.handleImageFileSelect}
                    >
                      <i className="fas fa-upload" />
                      <span className="mx-3">Upload Image</span>
                    </button>
                  </div>
                )}

                <button
                  type="submit"
                  className="theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-2 mt-5 font-style"
                >
                  {this.props.edit ? "UPDATE" : "CREATE"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Render testimonial image
  renderTestimonialImage = () => {
    return (
      <div className="d-flex">
        <div className="col-md-10 p-0 border border-dark rounded bg-white">
          <div className="d-flex">
            <img
              src={this.state.testimonialImage}
              alt="testimonial"
              width="100%"
              height="200px"
            />
          </div>
        </div>
        <div className="col-md-2">
          <i
            className="fas fa-trash-alt theme-font-color big-font-size m-2 pointer"
            onClick={() => this.setState({ testimonialImage: "" })}
          ></i>
        </div>
      </div>
    );
  };

  // Handle file select

  handleImageFileSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("testimonialImage");
    fileSelector.click();
  };

  // Select file
  // selectImageFile = (e) => {
  //   e.preventDefault();
  //   const testimonialImage = e.target.files[0];
  //   const type = "testimonialImage";
  //   uploadFile(testimonialImage, this.callBackImage, type);
  // };

  // callBackImage = (response = false) => {
  //   if (response && response.status === "success") {
  //     this.setState({ testimonialImage: response.result.testimonialimage });
  //   }
  // };

  selectImageFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && typeof event.target.result === "string") {
        this.setState({ testimonialImage: event.target.result });
      }
    };
    reader.readAsDataURL(file);
  };

  // Validation for name
  validateName = () => {
    const nameError = CheckUserName(this.state.name);
    if (nameError === 1) {
      this.setState({ nameError: "Please enter name" });
      return false;
    } else if (nameError === 2) {
      this.setState({ nameError: "Please enter valid name" });
      return false;
    } else return true;
  };

  // Validation for reviewLink
  validateReviewLink(value) {
    if (value.length > 0) {
      // Regular expression pattern to validate URLs
      const urlPattern = /^(http|https):\/\/[^ "]+$/;
      // Check if the entered URL matches the pattern
      const isValid = urlPattern.test(value);

      if (!isValid) {
        this.setState({ reviewLinkError: "Please enter valid review link" });
        return false;
      } else {
        this.setState({ reviewLinkError: "" });
        return true;
      }
    } else {
      this.setState({ reviewLinkError: "" });
      return true;
    }
  }

  // Validation for description
  validateDescription = (value) => {
    if (value.trim() === "") {
      this.setState({ descriptionError: "Please enter description" });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const nameInput = this.validateName();
    const reviewLink = this.validateReviewLink(this.state.reviewLink);
    const description = this.validateDescription(this.state.description);

    if (nameInput && reviewLink && description) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit create testimonial form function
  onSubmitCreateTestimonial = async (e) => {
    e.preventDefault();
    const id = this.props.edit;
    const allValidation = this.ValidateAll();
    if (allValidation) {
      this.setState({ loading: true });
      const requestData = {
        name: this.state.name,
        description: this.state.description,
        image: this.state.testimonialImage,
        review_link: this.state.reviewLink,
      };

      if (this.props.edit) {
        requestData["updated_by"] = User.user_id;
      } else {
        requestData["created_by"] = User.user_id;
      }
      if (this.props.edit) {
        const response = await updateTestimonial(requestData, id);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.TestimonialListApi();
          AppConfig.setMessage("Testimonial updated successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      } else {
        const response = await createTestimonial(requestData);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.TestimonialListApi();
          AppConfig.setMessage("Testimonial created successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      }
      this.setState({
        loading: false,
      });
    }
  };
}
export default observer(CreateTestimonial);
