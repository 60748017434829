import React from "react";
import "../css/Common.css";
import Footerlogo from "../images/footerlogo.png";
import "../css/Carousel.css";
import Icomoon from "../libraries/Icomoon.js";
import Slider from "react-slick";
import { callApi } from "../apis/Api";
import { emailForm } from "../apis/Form";
import { Link } from "react-router-dom";
import { CheckEmail } from "../common/Validation";
import AppConfig from "../modal/AppConfig";
import Youtube from "../images/home/youtube.png";
import { serviceCategories } from "./serviceData";

// General Focus Hook
const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    ref.current && ref.current.focus();
  };

  return { setFocus, ref };
};

class Footer extends React.Component {
  constructor() {
    super();
    this.brandPartner = utilizeFocus();
  }
  state = {
    email: "",
    brand: [],
    error: "",
    status: false,
  };

  async componentDidMount() {
    const {
      location: { hash },
    } = this.props.props;
    if (hash === "#brand-partner") {
      this.brandPartner.setFocus();
    }
    window.scrollTo(0, 0);
    // Api call for footer
    let functionUrl = "footer";
    const response = await callApi(functionUrl, {}, "GET");
    const result = response.result;
    let brand = [];
    for (let i in result) {
      brand.push(result[i]);
    }
    this.setState({
      brand: brand,
    });
  }

  componentDidUpdate() {
    const {
      location: { hash },
    } = this.props.props;
    if (hash === "#brand-partner") {
      this.brandPartner.setFocus();
    }
  }

  render() {
    return (
      <div>
        <div className="bg-white">
          <div className="p-5 mt-5">
            <h2 className="theme-font-color font-weight-bold text-center font-style2">
              STAY INFORMED
            </h2>
            <p className="text-center my-4 font-style normal-font-size">
              Dezign Code is a full-service interior designing company
              headquartered in Bangalore, India.
            </p>
            <form onSubmit={this.onSubmitStayInformed}>
              <div className="d-flex justify-content-center">
                <div className="d-flex">
                  <input
                    type="text"
                    value={this.state.email}
                    className="py-2 col-md-9 font-style"
                    onChange={(e) =>
                      this.setState({ email: e.target.value, emailError: "" })
                    }
                    onFocus={(e) => this.setState({ emailError: "" })}
                    placeholder="Enter your Email"
                  />
                  <button
                    type="submit"
                    className="bg-dark border-0 text-white p-2 px-5 mx-2 font-style"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {this.state.emailError ? (
                  <span className="small-font-size text-danger">
                    {" "}
                    {this.state.emailError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
          <div
            className="d-flex justify-content-center px-5"
            style={{ paddingBottom: 200 }}
          >
            <p className="text-center text-dark font-style normal-font-size">
              Want to know more about our avant-grade designs, classic looks,
              materials used & service? Get in touch!
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center position-relative">
          {this.carouselContainer()}
        </div>
        <div className="bg-dark pt-5">
          {this.renderFooter()}
          <hr className="footer-line"></hr>
          <div className="px-5 py-3">
            <p className="text-white font-style">
              Copyright ©2021 Expert-themes. All right reserved
            </p>
          </div>
        </div>
      </div>
    );
  }
  renderFooter() {
    return (
      <div className="p-5">
        <input
          type="text"
          className="hiddenInput"
          ref={this.brandPartner.ref}
        />
        <div className="row">
          <div className="col-md-3 my-3">
            <img src={Footerlogo} alt="Footerlogo" />
            <div className="d-flex justify-content-between my-3 col-md-8">
              <a
                href="https://www.facebook.com/thedezigncode"
                target="_blank"
                rel="noreferrer"
              >
                <Icomoon
                  className="footer-icon"
                  style={{ cursor: "pointer" }}
                  icon="facebook2"
                  size={25}
                  color="#fff"
                />
              </a>
              <a
                href="https://www.instagram.com/dezign_code/"
                target="_blank"
                rel="noreferrer"
              >
                <Icomoon
                  className="footer-icon"
                  style={{ cursor: "pointer" }}
                  icon="instagramg"
                  size={25}
                  color="#fff"
                />
              </a>
              <a
                // href="https://www.instagram.com/dezign_code/"
                href="https://youtube.com/@dezigncode6246"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Youtube} alt="" width="35px" height="35px" />
              </a>
              <a
                href="https://www.linkedin.com/company/the-dezign-code/"
                target="_blank"
                rel="noreferrer"
              >
                <Icomoon
                  className="footer-icon"
                  style={{ cursor: "pointer" }}
                  icon="Icon-awesome-linkedin1"
                  size={25}
                  color="#fff"
                />
              </a>
            </div>
          </div>
          <div className="col-md-3 my-3">
            <div className="d-flex gap-3 justify-content-around">
              <div>
                <h6 className="text-white font-style">USEFUL LINKS</h6>
                <ul
                  className="text-white pl-0  font-style mt-0 pt-0"
                  style={{ listStyleType: "none" }}
                >
                  <li className="normal-font-size">
                    <Link
                      to="/"
                      className="text-white text-decoration-none"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/about-us"
                      className="text-white text-decoration-none"
                    >
                      About us
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/our-projects"
                      className="text-white text-decoration-none"
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/blog"
                      className="text-white text-decoration-none"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/theme-homes"
                      className="text-white text-decoration-none"
                    >
                      Theme Homes
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/services"
                      className="text-white text-decoration-none"
                    >
                      Service
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/offers"
                      className="text-white text-decoration-none"
                    >
                      Offers
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/policies"
                      className="text-white text-decoration-none"
                    >
                      Policies
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="text-white font-style">MORE LINKS</h6>
                <ul
                  className="text-white pl-0 normal-font-size font-style mt-0 pt-0"
                  style={{ listStyleType: "none" }}
                >
                  {
                    serviceCategories?.map((service) => (
                      <li className="normal-font-size" key={service.value}>
                        <Link
                          to={`/services/${service.category}/${service.value}`}
                          className="text-white text-decoration-none"
                        >
                          {service.label}
                        </Link>
                      </li>
                    ))
                  }
                  {/* <li className="normal-font-size">
                    <Link
                      to="/services"
                      className="text-white text-decoration-none"
                    >
                      Service
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/offers"
                      className="text-white text-decoration-none"
                    >
                      Offers
                    </Link>
                  </li>
                  <li className="normal-font-size">
                    <Link
                      to="/policies"
                      className="text-white text-decoration-none"
                    >
                      Policies
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-3">
            <h6 className="text-white font-style">CONTACT US</h6>
            <p className="text-white normal-font-size font-style">
              No. 26, Behind HSR Layout 7th sector,
              <br />
              Mangammanapalaya Main Rd, Bommanahalli,
              <br />
              Bengaluru, Karnataka 560068
            </p>
            <p>
              <a
                className="text-decoration-none text-white normal-font-size font-style"
                href="mailto:sales@thedezigncode.com"
              >
                sales@thedezigncode.com
              </a>
              <br />
              <a
                className="text-decoration-none text-white normal-font-size font-style"
                href="tel:+91 6366939113"
              >
                {" "}
                +91 6366939113
              </a>
            </p>
          </div>
          <div className="col-md-3 my-3">
            <h6 className="text-white font-style">LOCATE US</h6>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.013830349314!2d77.63382521517093!3d12.906832090898412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae149406dbd6d9%3A0xf62555af196518e3!2sThe%20Dezign%20Code!5e0!3m2!1sen!2sin!4v1625809928197!5m2!1sen!2sin%22"
              width="85%"
              height="150"
              frameBorder="0"
              className="border-none border-style"
              title="map"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }

  carouselContainer = () => {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      nextArrow: <Icomoon icon="btn1" size={30} />,
      prevArrow: <Icomoon icon="btn2" size={30} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div
        className="shadow col-md-10 border-style py-3 bg-white position-absolute mb-5"
        style={{ bottom: -100 }}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-center m-2">
            <div className="col-md-12 p-3">
              <Slider {...settings}>
                {this.state.brand.map((brand, i) => (
                  <div className="col-12" key={i}>
                    <div className="card h-100 border border-dark p-4">
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={brand.image}
                          alt={brand.alt_tag}
                          height="120"
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Validation for email

  validateEmail = () => {
    const emailError = CheckEmail(this.state.email);
    if (emailError === 1) {
      this.setState({ emailError: "Please enter your email id" });
      return false;
    } else if (emailError === 2) {
      this.setState({ emailError: "Please enter your valid email" });
      return false;
    } else return true;
  };

  onSubmitStayInformed = async (e) => {
    e.preventDefault();
    const emailError = this.validateEmail();
    if (emailError) {
      const requestData = {
        email: this.state.email,
      };
      const response = await emailForm(requestData);
      if (response.status === "success") {
        AppConfig.setMessage(response.result, false);
      } else if (response.status === "error") {
        AppConfig.setMessage(response.result);
      }
    }
  };
}
export default Footer;
