import { callApi, uploadApi } from "./Api";

/**
 *
 * @param{email, password)data
 */

export const testimonialList = async( requestData = {} ) => {
    const functionUrl = "testimonialList";
  
    try {
        return await callApi(functionUrl, requestData )
       
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
  };


/**
 *
 * @param{email, password)data
 */

export const createTestimonial = async(requestData={}) => {
    const functionUrl = "testimonial";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single testimonial) by id
 */
export const updateTestimonial = async(requestData, id) => {
    const functionUrl = "testimonial";
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single testimonial) by id
 */
export const getTestimonial = async(id) => {
    const functionUrl = "testimonial";
    try {
        return await callApi(functionUrl, {}, 'GET', id)
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{delete testimonial) by id
 */
export const deleteTestimonial = async(id) => {
    const functionUrl = "testimonial";
    try {
        return await callApi(functionUrl, {}, 'DELETE', id )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 * Common Api for upload file or profile pic.
 * @param(file, callback) 
 * @return API response
*/
export const uploadFile = async (file, callBack, type) => {
	const functionUrl = 'uploadTestimonial';
	try {
		const response = await uploadApi(functionUrl, file, callBack, type);
		if (response) {
			return callBack(response);
		}
	} catch (error) {
		// console.log('Error from catch => ', error);
	}
}