import React from "react";
import "../../css/Common.css";
import Icomoon from "../../libraries/Icomoon";
import { TextField } from "@material-ui/core";
import AppConfig from "../../modal/AppConfig";
import Modal from "react-bootstrap/Modal";
import {
  createClient,
  getClient,
  uploadFile,
  updateClient,
} from "../../apis/Client";
import "react-quill/dist/quill.snow.css";
import { observer } from "mobx-react";
import User from "../../modal/User";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class CreateClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      createClient: false,
      clientName: "",
      clientImage: "",
      altTag: "",
      clientNameError: "",
      clientImageError: "",
    };
  }

  resetState = () => {
    this.setState({
      id: "",
      createClient: false,
      clientName: "",
      clientImage: "",
      altTag: "",
      clientNameError: "",
      clientImageError: "",
    });
  };

  async componentDidUpdate(prevProps) {
    // const id = User.user_id
    if (this.props.edit && this.props.edit !== prevProps.edit) {
      const id = this.props.edit;
      const response = await getClient(id);
      const result = response.result;
      if (response && response.status === "success") {
        this.setState({
          id: id,
          clientName: result.clientName,
          clientImage: result.image,
          altTag: result.altTag,
        });
      }
    }
  }

  render() {
    return (
      <div>
        <CustomLoaderModal loader={this.state.loading} />
        {this.renderCreateClientModal()}
      </div>
    );
  }

  // Render create client modal function
  renderCreateClientModal = () => {
    return (
      <Modal
        size="md"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.status}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              {this.props.edit ? "Edit Client" : "Create Client"}
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.props.closeModel(false);
                this.resetState();
              }}
            />
          </div>
          <Modal.Body>
            <form
              onSubmit={this.onSubmitCreateClient}
              encType="multipart/form-data"
            >
              <div>
                <TextField
                  className="col-md-12 my-2"
                  id="client-name"
                  label="Client Name"
                  value={this.state.clientName}
                  onChange={(e) =>
                    this.setState({
                      clientName: e.target.value,
                      clientNameError: "",
                    })
                  }
                  onFocus={() => this.setState({ clientNameError: "" })}
                />
                <div className="d-flex justify-content-start">
                  {this.state.clientNameError ? (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.clientNameError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  {this.state.clientImage ? (
                    <div className="col-md-10">{this.renderClientImage()}</div>
                  ) : (
                    <div className="">
                      <input
                        type="file"
                        id="clientImage"
                        className="d-none"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.selectClientImgFile}
                      />
                      {/* <p className="small-font-size my-0"> Image resolution should be 1170*658</p> */}
                      <button
                        className="btn theme-bg-color text-white small-font-size font-style py-2 mt-3"
                        onClick={this.handleClientImgFileSelect}
                      >
                        <i className="fas fa-upload" />
                        <span className="mx-3">Upload Client Image</span>
                      </button>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-start pt-1">
                  {this.state.clientImageError ? (
                    <span className="small-font-size text-danger">
                      {" "}
                      {this.state.clientImageError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <button
                  type="submit"
                  className="theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-2 mt-5 font-style"
                >
                  {this.props.edit ? "UPDATE" : "CREATE"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Render client image
  renderClientImage = () => {
    return (
      <div className="d-flex my-3">
        <div className="col-md-10 border border-dark rounded bg-white">
          <div className="d-flex">
            <img src={this.state.clientImage} alt="client" width="100%" />
          </div>
        </div>
        <div className="col-md-2">
          <i
            className="fas fa-trash-alt theme-font-color big-font-size m-2 pointer"
            onClick={() => this.setState({ clientImage: "" })}
          ></i>
        </div>
      </div>
    );
  };

  // Handle file select
  handleClientImgFileSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("clientImage");
    fileSelector.click();
  };

  // Select file
  // selectClientImgFile = (e) => {
  //     e.preventDefault();
  //     const clientImage = e.target.files[0];
  //     const type = 'clientImage'
  //     uploadFile(clientImage, this.callBackClientImage, type);
  // };

  // callBackClientImage = (response = false) => {
  //     if (response && response.status === "success") {
  //        this.setState({ clientImage: response.result.clientImage, clientImageError : ''})
  //     }

  // }

  selectClientImgFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && typeof event.target.result === "string") {
        this.setState({
          clientImage: event.target.result,
          clientImageError: "",
        });
      }
    };
    reader.readAsDataURL(file);
  };

  // Handle file select
  handleThumbnailImgFileSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("thumbnailimage");
    fileSelector.click();
  };

  // Validation for  clientName
  validateName = () => {
    if (this.state.clientName === "") {
      this.setState({ clientNameError: "Please enter client name" });
      return false;
    } else return true;
  };

  // Validation for  clientImage
  validateImage = () => {
    if (this.state.clientImage === "") {
      this.setState({ clientImageError: "Please upload client image" });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const nameInput = this.validateName();
    const clientImageError = this.validateImage();

    if (nameInput && clientImageError) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit create client form function
  onSubmitCreateClient = async (e) => {
    e.preventDefault();
    const id = this.props.edit;
    const allValidation = this.ValidateAll();
    if (allValidation) {
      this.setState({ loading: true });
      const requestData = {
        clientName: this.state.clientName,
        image: this.state.clientImage,
        altTag: this.state.altTag,
      };
      if (id) {
        requestData["updatedBy"] = User.user_id;
      } else {
        requestData["createdBy"] = User.user_id;
      }
      if (this.props.edit) {
        const response = await updateClient(requestData, id);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.clientListApi();
          AppConfig.setMessage("Client updated successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      } else {
        const response = await createClient(requestData);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.clientListApi();
          AppConfig.setMessage("Client created successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      }
      this.setState({ loading: false });
    }
  };
}
export default observer(CreateClient);
