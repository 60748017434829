import React from "react";
import "../css/AboutUs.css";
import Double from "../images/about/double.png";
import Founder1 from "../images/about/chetan-compressed.jpg";
import Founder2 from "../images/about/hema-compressed.jpg";
// import Founder3 from "../images/about/deepak-compressed.jpg";
import Icomoon from "../libraries/Icomoon";
import Slider from "react-slick";
import { callApi } from "../apis/Api";
import Header from "./Header";
import Footer from "../pages/Footer";

// General Focus Hook
const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    ref.current && ref.current.focus();
  };
  return { setFocus, ref };
};

class AboutUs extends React.Component {
  constructor() {
    super();
    this.ourFounder = utilizeFocus();
    this.ourTeam = utilizeFocus();
  }

  state = {
    team: [],
    award: [],
  };

  async componentDidMount() {
    // document.title = `Interior Design Bangalore - About Us | DezignCode`;
    // document.querySelector('meta[name="description"]').content =
    //   "Know more about our team of Interior Design Bangalore who offers end to end home interior design solution for kitchen, living room, bedroom,etc. Meet our team of dream catchers with highly creative designers, efficient project managers who are ready to transform and transcend your living standards.";
    const {
      location: { hash },
    } = this.props;
    if (hash === "#our-founders") {
      this.ourFounder.setFocus();
    } else if (hash === "#our-team") {
      this.ourTeam.setFocus();
    }

    // Api call for about page
    let functionUrl = "about";
    const response = await callApi(functionUrl, {}, "GET");
    const result = response.result;

    let team = [];
    for (let i in result.team) {
      team.push(result.team[i]);
    }
    let award = [];
    for (let i in result.award) {
      award.push(result.award[i]);
    }

    this.setState({
      team: team,
      award: award,
    });
  }

  componentDidUpdate() {
    const {
      location: { hash },
    } = this.props;
    if (hash === "#our-founders") {
      this.ourFounder.setFocus();
    } else if (hash === "#our-team") {
      this.ourTeam.setFocus();
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="header-margin p-5">
          <div className="p-5">
            <h1 className="theme-font-color font-weight-bold font-style2 mb-5">
              DezignCode - A fastest Growing Interior Design Company
            </h1>
            <h2
              className="theme-font-color font-weight-normal font-style2"
              style={{ fontSize: "180%" }}
            >
              FOUNDER'S
            </h2>
          </div>
          {this.renderCarouselContainer()}
        </div>
        <div className="header-margin">{this.renderOurTeam()}</div>
        <Footer props={this.props} />
      </>
    );
  }

  renderCarouselContainer() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: null,
      prevArrow: null,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="d-flex justify-content-center">
          <div className="col-md-9">
            <input
              type="text"
              className="hiddenInput"
              ref={this.ourFounder.ref}
            />
            <Slider {...settings} className="rose-dot">
              <div className="founder-content">
                <div className="founder-quoteimg">
                  <img
                    src={Double}
                    alt="Chetan patel founder of DezignCode interior designing company in bangalore"
                    width="100%"
                  />
                </div>
                <div className="founderdetails">
                  <div className="founder-message">
                    <div className="col-md-7">
                      <p className="normal-font-size text-justify">
                        An entrepreneur who has nearly 15 years of experience in
                        the interior designing industry. He conceived and
                        developed numerous designs that created a new world for
                        interior design inspirations. His works are beyond words
                        with remarkable designs. <br />
                        <br />
                      </p>
                      <p className="text-center">
                        <i>
                          {" "}
                          "If I cannot do great things, I can do small things in
                          a great way”{" "}
                        </i>
                      </p>
                    </div>
                  </div>
                  <div className="founder-personaldetails">
                    <hr style={{ width: "25%" }}></hr>
                    <p className="font-style normal-font-size my-0 mobile_personal-font">
                      Chetan Patel - Founder
                    </p>
                    <div className="d-flex justify-content-start my-0">
                      <p className="font-style normal-font-size text-lowercase mobile_personal-font">
                        chetan@thedezigncode.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="founder-img">
                  <img
                    src={Founder1}
                    alt="Chetan patel founder of DezignCode interior designing company in bangalore"
                    width="100%"
                    height="530px"
                  />
                </div>
              </div>
              <div className="founder-content">
                <div className="founder-quoteimg">
                  <img
                    src={Double}
                    alt="Hema Niranjan founder of DezignCode interior design company"
                    width="100%"
                  />
                </div>
                <div className="founderdetails">
                  <div className="founder-message">
                    <div className="col-md-7">
                      <p className="normal-font-size text-justify">
                        A successful interior designer and restaurateur who runs
                        the first ARMY theme-based restaurant called HUNGER
                        CAMP. Her ungiving nature paved way for her dreams &
                        awards that inspire other women to follow. She turned
                        down all the hopeless situations with her perseverance
                        and made it to her wardrobe of fame. Her prominent
                        interior designs are in Goa, Bangalore & Hyderabad.
                        <br />
                      </p>
                      <p className="text-center">
                        <i>
                          {" "}
                          “Dreams do find their home in those who love them
                          truly”{" "}
                        </i>
                      </p>
                    </div>
                  </div>
                  <div className="founder-personaldetails px-3">
                    <hr style={{ width: "25%" }}></hr>
                    <p className="font-style normal-font-size my-0 mobile_personal-font">
                      Hema Niranjan - Founder
                    </p>
                    <div className="d-flex justify-content-start my-0">
                      <p className="font-style normal-font-size text-lowercase mobile_personal-font">
                        hema@thedezigncode.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="founder-img">
                  <img
                    src={Founder2}
                    alt="Hema Niranjan founder of DezignCode interior design company"
                    width="100%"
                    height="530px"
                  />
                </div>
              </div>
              {/* <div className="founder-content">
                <div className="founder-quoteimg">
                  <img
                    src={Double}
                    alt="Deepak Kanchi a co-founder of best interior design company in Bangalore"
                    width="100%"
                  />
                </div>
                <div className="founderdetails">
                  <div className="founder-message">
                    <div className="col-md-7">
                      <p className="normal-font-size text-justify">
                        Deepak Kanchi is a seasoned finance professional with
                        25+ years of experience in variety of industries ranging
                        from Food & Beverage, Supply Chain Management, Cleaning
                        to Construction. He has worked in multi-cultural
                        environments in India, Middle East & North Africa. He is
                        a CA from India and a CIA from USA. He has also attended
                        GMP and SMMP at Booth School of Business University
                        Chicago.
                        <br />
                      </p>
                      <p className="text-center">
                        <i>“In the middle of difficulty lies opportunity”</i>
                      </p>
                    </div>
                  </div>
                  <div className="founder-personaldetails px-3">
                    <hr style={{ width: "25%" }}></hr>
                    <p className="font-style normal-font-size my-0 mobile_personal-font">
                      Deepak Kanchi - Co-Founder
                    </p>
                    <div className="d-flex justify-content-start ">
                      <p className="font-style normal-font-size text-lowercase my-0 mobile_personal-font">
                        deepak.kanchi@thedezigncode.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="founder-img">
                  <img
                    src={Founder3}
                    alt="Deepak Kanchi a co-founder of best interior design company in Bangalore"
                    width="100%"
                    height="530px"
                  />
                </div>
              </div> */}
            </Slider>
          </div>
        </div>
      </>
    );
  }

  // Render function for our team

  renderOurTeam() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <Icomoon icon="btn1" size={30} />,
      prevArrow: <Icomoon icon="btn2" size={30} />,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="d-flex justify-content-center">
        <div className="col-md-12">
          <div className="m-5 p-5">
            <h2 className="theme-font-color font-weight-bold font-style2">
              Creative Interior Design Team
            </h2>
            <div className="py-3">
              <p className="font-style normal-font-size">
                Meet our team of dream catchers with highly creative designers,
                efficient project managers and supportive back end team who are
                ready to transform and transcend your living standards.
              </p>
            </div>
            <div className="container-fluid my-5">
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  className="hiddenInput"
                  ref={this.ourTeam.ref}
                />
                <div className="col-md-11">
                  <Slider {...settings} className="rose-dot">
                    {this.state.team.map((team, i) => (
                      <div className="col-md-12" key={i}>
                        <img
                          style={{ borderRadius: "25px" }}
                          src={team.image}
                          alt="A Team of Creative Interior designers in bangalore"
                          width="100%"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
