import React from "react";
import "../../css/Common.css";
import { Link } from "react-router-dom";

const Main = (props) => {
  let { handleToggleSidebar } = props;
  return (
    <main>
      <header className="d-flex">
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          &#9776;
        </div>
        <div className={`d-flex justify-content-end`} style={{ padding: "10px 0px"}}>
          <Link
            to="/"
            target="_blank"
            className="medium-font-size position-fixed top-0 pt-3 end-0 text-decoration-none theme-font-color pe-3 "
          >
            <i className="fas fa-solid fa-globe theme-font-color mr-2"></i>
            Our website
          </Link>
        </div>
      </header>
      <div className="h-100">{props.children}</div>
    </main>
  );
};

export default Main;
