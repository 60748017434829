import React, { useState } from 'react';
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
 
} from 'react-pro-sidebar';
import Logo from '../../images/logo.svg';
import Loading from '../../images/loading.svg';
import '../../css/Common.css'
import 'react-pro-sidebar/dist/css/styles.css';
import MenuList from './MenuList';
import User from '../../modal/User';
import {Link, useHistory} from 'react-router-dom';
import Icomoon from '../../libraries/Icomoon';
// import '../../css/menu.css';

function SideBar  ({ toggled, handleToggleSidebar, handleCollapsedChange})  {
	const [collapsed, setCollapsed] = useState(false);
	const history = useHistory();

	// onClick logout function
	function onClickLogout ()  {
		User.clearStoredDate();
		sessionStorage.clear();
		history.push("/login");
	}
	return (
		<ProSidebar
			collapsed={collapsed}
			toggled={toggled}
			breakPoint="md"
			onToggle={handleToggleSidebar}
			handleToggleSidebar={handleCollapsedChange} 	
		>
			<SidebarHeader className="d-flex justify-content-center py-2 bg-white">
				{collapsed ? 
					<img src={Loading} alt="Logo-1" width="70" height="35"/>:
					<img src={Logo} alt="Logo" width="100%" height="35"/>
				}
			</SidebarHeader>
			<SidebarContent className='bg-white sidebar-collaps'>
				<MenuList/>
			</SidebarContent>
			<SidebarFooter className="p-3 bg-white">
				<div className="d-flex justify-content-between">
					<div>
					{collapsed ? 
						<div> 
							<Link className="text-decoration-none" to="/login">
								<Icomoon icon="logout" size={23} color="#E40045"/>
							</Link>
						</div>
					:
						<div className='d-flex' onClick={onClickLogout}>
							<Icomoon icon="logout" size={23} color="#E40045" />
							<p className="normalText font-weight-bold pl-3 theme-font-color"  style={{cursor:'pointer'}}>Logout</p>
						</div>
					}
					</div>
					<div className='d-flex  align-self-center'>
					<div className="ml-2"> 
						{collapsed  ?
							<Icomoon icon="rarrow1" style={{cursor:'pointer'}}  onClick={() => setCollapsed(false)}  size={30} color="E40045"/> 
								:
							<Icomoon style={{cursor:'pointer'}} onClick={() => setCollapsed(true)} icon="larrow1" size={30} color="E40045"/> 
						}
					</div>
					</div>
				</div>
			</SidebarFooter>

		</ProSidebar>
	);
};

export default SideBar;