// Forgot passsword Page

import React from 'react';
import {CheckEmail} from "../../common/Validation";
import { TextField } from '@material-ui/core';
// import Otp from '../../common/Otp';
import {forgot }from '../../apis/Admin';
import Logo from '../../images/logo.svg';
import {Link} from 'react-router-dom';
import AppConfig from '../../modal/AppConfig';
import { observer } from 'mobx-react';
import CustomLoaderModal from '../../common/CustomLoaderModal';

class ForgotPassword extends React.Component {
    state={
        loading: false,
        email:'',
        otp:''
    }


    render() {
        return (
            <div>
                <CustomLoaderModal loader={this.state.loading} />
                <div className="d-flex justify-content-center pt-5">                       
                    <div className="col-md-3 mt-3">
                        <Link to='/' target="_blank" rel="noreferrer">
                            <img src={Logo} alt="Logo" className='mx-3'/>
                        </Link>
                        {this.renderForgotPassword()}
                    </div>
                </div>
            </div>    
        )
    }


    // Render forgot password function

    renderForgotPassword() {
        return(
            <div className="col-md-12 rounded bg-white shadow p-3 my-5">
                <h3 className="theme-font-color font-style mb-3 text-center">Forgot Password</h3>
                <form onSubmit={this.onSubmitForgotPassword}>
                    <div className="py-3">
                        <TextField 
                            type="text"  
                            className="form-control" 
                            id="email" 
                            label="Email"
                            value={this.state.email}
                            onChange={(e)=>this.setState({email:e.target.value, emailError:''})}
                            onFocus ={()=> this.setState({emailError:''})}
                        />
                    </div>
                    {this.state.emailError ?  (<span className='small-font-size text-danger'> {this.state.emailError}</span>):''}
                    <Link to='/login' className="text-decoration-none"><p className="small-font-size text-dark font-weight-bold my-3">Login</p></Link>
                   
                    <button type='submit' className="btn theme-bg-color text-white col-md-12 font-style my-3">Submit</button>
                </form>
            </div>
        )
    }
    
    // Validation for username
    validateEmail = () => {
        const usernameError = CheckEmail(this.state.email);
        if (usernameError === 1) {
            this.setState({ emailError: 'Please enter your email address' });
            return false;
        } else if (usernameError === 2) {
            this.setState({ emailError: 'Please enter an valid email address' });
            return false;
        } else return true;
    };

    // onsubmit function for forget password

    onSubmitForgotPassword = async (e) =>{
        e.preventDefault();
        const emailError = this.validateEmail()
        if (emailError) {
            this.setState({ loading: true });
            const requestData = {
                email: this.state.email,  
            };
            const response = await forgot(requestData);
            if(response && response.status === 'success') {
                AppConfig.setMessage(response.result, false);
                this.props.history.replace('./login')
            }else if(response.status === 'error') {
                AppConfig.setMessage(response.result);
            }
            this.setState({ loading: false });
        }
}


}


export default observer(ForgotPassword);