import React from "react";
import { Layout } from "../../common/Element";
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Table } from "react-bootstrap";
import Icomoon from "../../libraries/Icomoon";
import { observer } from "mobx-react";
import CreateOrEditSEO from "./CreateOrEditSEO";
import AppConfig from "../../modal/AppConfig";
import { SeoList } from "../../apis/Seo";

class ManageSEO extends React.Component {
  state = {
    size: 10,
    current_page: 1,
    rows: [],
    totalData: 0,
    page: 0,
    editSEOId: "",
    status: false,
  };

  headCells = [
    {
      id: "slNumber",
      label: "#",
      width: "5%",
      align: "center",
    },
    {
      id: "page",
      label: "Page",
      width: "25%",
    },
    // {
    //   id: "pageUrl",
    //   label: "Page URL",
    //   width: "20%",
    // },
    {
      id: "title",
      label: "Title",
      width: "30%",
    },
    {
      id: "description",
      label: "Description",
      width: "30%",
    },
    {
      id: "action",
      label: "Action",
      width: "10%",
      align: "center",
    },
  ];

  handleRequestSort = (property) => {
    this.setState((prevState) => {
      const { orderBy, order, rows } = prevState;
      const isAsc = orderBy === property && order === "asc";

      const sortedRows = [...rows].sort((a, b) => {
        if (property === "slNumber") {
          return isAsc ? a.slNumber - b.slNumber : b.slNumber - a.slNumber;
        }
        if (property === "page") {
          return isAsc
            ? a.page.localeCompare(b.page)
            : b.page.localeCompare(a.page);
        }
        if (property === "title") {
          return isAsc
            ? a.title.localeCompare(b.title)
            : b.title.localeCompare(a.title);
        }
        if (property === "description") {
          return isAsc
            ? a.description.localeCompare(b.description)
            : b.description.localeCompare(a.description);
        }
        return 0;
      });

      return {
        orderBy: property,
        order: isAsc ? "desc" : "asc",
        rows: sortedRows,
      };
    });
  };

  async componentDidMount() {
    await this.SeoListApi();
  }

  SeoListApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
    };
    const response = await SeoList(requestData);
    if (response && response.status === "success") {
      const result = response.result;
      let apiRows = [];

      for (let i in result.data) {
        const row = {
          id: result.data[i].id,
          slNumber: Number(i) + 1,
          page: result.data[i].page,
          pageUrl: result.data[i].pageUrl,
          title: result.data[i].metaTitle,
          description: result.data[i].metaDescription,
        };
        apiRows.push(row);
      }
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  renderTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%" }}
            style={{
              border: "1px solid #E40045",
              borderRadius: "5px",
              backgroundColor: "#f7f7f7",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow style={{ borderColor: "red" }}>
                    {this.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={
                          this.state.orderBy === headCell.id
                            ? this.state.order
                            : false
                        }
                        width={headCell.width}
                        align={headCell.align ? headCell.align : "left"}
                      >
                        <TableSortLabel
                          active={this.state.orderBy === headCell.id}
                          direction={
                            this.state.orderBy === headCell.id
                              ? this.state.order
                              : "asc"
                          }
                          onClick={() => this.handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.rows.map((row) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell
                          align="left"
                          style={{ padding: "15px 10px" }}
                        >
                          {" "}
                          {row.slNumber}{" "}
                        </TableCell>
                        <TableCell
                          className="text-truncate"
                          align="left"
                          // style={{ padding: "15px 5px" }}
                          style={{
                            maxWidth: "150px",
                            padding: "15px 5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {row.page || "-"}{" "}
                        </TableCell>
                        {/* <TableCell
                          className="text-truncate"
                          align="left"
                          // style={{ padding: "15px 5px" }}
                          style={{
                            maxWidth: "150px",
                            padding: "15px 5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {row.pageUrl || "-"}{" "}
                        </TableCell> */}
                        <TableCell
                          align="left"
                          className="text-truncate"
                          style={{
                            maxWidth: "150px",
                            padding: "15px 5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {row.title || "-"}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-truncate"
                          style={{
                            maxWidth: "150px",
                            padding: "15px 5px",
                            textTransform: "capitalize",
                          }}
                        >
                          {row.description || "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "15px 5px" }}
                        >
                          <div className="d-flex justify-content-center">
                            <Icomoon
                              className="pointer mx-2"
                              icon="pen-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.editSEO(row.id)}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {this.state.rows.length === 0 && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "300px",
                  }}
                >
                  <p>No records found</p>
                </div>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.size}
              page={this.state.page}
              onPageChange={(e, newPage) =>
                this.setState(
                  {
                    size: this.state.size,
                    current_page: newPage + 1,
                    page: newPage,
                  },
                  () => this.SeoListApi()
                )
              }
              onRowsPerPageChange={(e) =>
                this.setState({ size: e.target.value, current_page: 1 }, () =>
                  this.SeoListApi()
                )
              }
            />
          </Paper>
        </Box>
      </div>
    );
  };

  // Edit Seo modal
  editSEO = (id) => {
    this.setState({ editSEOId: id, status: true });
  };

  render() {
    return (
      <Layout>
        <div className="px-5 pb-5" style={{ marginTop: "4rem" }}>
          <div className="d-flex justify-content-between">
            <p className="theme-font-color align-self-center mt-2">
              Manage SEO Content
            </p>
          </div>
          {this.renderTable()}
        </div>
        <CreateOrEditSEO
          id={AppConfig.user_id}
          edit={this.state.editSEOId}
          status={this.state.status}
          closeModel={() => this.setState({ status: false, editSEOId: "" })}
          SeoListApi={this.SeoListApi}
        />
      </Layout>
    );
  }
}

export default observer(ManageSEO);
