// Manage banner Page

import React from "react";
import { Layout } from "../../common/Element";
import "../../css/Common.css";
import Icomoon from "../../libraries/Icomoon";
import AppConfig from "../../modal/AppConfig";
import { observer } from "mobx-react";
import User from "../../modal/User";
import {
  uploadFile,
  deleteBannerImage,
  getBannerImages,
} from "../../apis/Banner";
import ConfirmModal from "../../common/ConfirmModal";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

class ManageBanner extends React.Component {
  state = {
    error: "",
    status: false,
    bannerType: "desktop",
    bannerGalleryCollection: [],
  };

  async componentDidMount() {
    // Api call
    this.fecthBannerImagesApi(this.state.bannerType);
  }

  fecthBannerImagesApi = async (bannerType) => {
    const filter = bannerType;
    const response = await getBannerImages(filter);
    if (response && response.status === "success") {
      const result = response.result;
      const updatedImages = result?.map((result) => ({
        id: result.id,
        bannerImage: result.bannerImage,
      }));
      this.setState({ bannerGalleryCollection: updatedImages });
    } else {
      this.setState({ bannerGalleryCollection: [] });
    }
  };

  render() {
    return (
      <Layout>
        <div className="pt-2 header-margin">
          <div className="mt-3">{this.renderBannerImage()}</div>
        </div>

        <ConfirmModal
          delete={true}
          visible={this.state.deleteImageConfirmModal}
          heading="Delete Picture"
          title="Are you sure you want to delete the picture?"
          confirm={() => this.getBannerImageSuccess()}
          handleClose={() => this.setState({ deleteImageConfirmModal: false })}
        />
      </Layout>
    );
  }

  // Render banner images function

  renderBannerImage() {
    return (
      <div className="w-100 px-4">
        <div className="d-flex flex-column flex-lg-row justify-content-between align-content-lg-center align-items-start">
          <p className="theme-font-color pt-md-4">Banner Images</p>
          <FormControl className="col-md-3 mb-3 col-lg-2 me-1">
            <InputLabel id="property-details">Banner type</InputLabel>
            <Select
              labelId="banner-type"
              id="banner-type"
              value={this.state.bannerType}
              onChange={(e) => {
                this.setState({
                  bannerType: e.target.value,
                });
                this.fecthBannerImagesApi(e.target.value);
              }}
            >
              <MenuItem value="desktop">Desktop</MenuItem>
              <MenuItem value="mobile">Mobile</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="">
          <div className="d-flex flex-wrap mt-3">
            {this.state.bannerGalleryCollection?.map((galleryCollection) => (
              <div className="row col-12 col-lg-4 mx-0 p-0" key={galleryCollection.id}>
                <img
                  src={galleryCollection.bannerImage}
                  alt={galleryCollection.id}
                  width="100%"
                  // height="175px"
                  className="rounded-3"
                />
                <div className="d-flex my-3">
                  <Icomoon
                    className="pointer mx-2"
                    icon="pen-solid"
                    size={16}
                    color="#E40045"
                    onClick={(e) => {
                      this.setState({ editGallaryId: galleryCollection?.id });
                      this.handleUploadPicsSelect(e);
                    }}
                  />
                  <Icomoon
                    className="pointer mx-2"
                    icon="trash-solid"
                    size={16}
                    color="#E40045"
                    onClick={() => {
                      this.deleteBannerImage(galleryCollection?.id);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <form encType="multipart/form-data">
          <div className="d-flex justify-content-start mt-3">
            <input
              type="file"
              id="bannerUploadPics"
              className="d-none"
              accept=".png, .jpg, .jpeg"
              onChange={this.selectUploadPics}
            />
            <button
              className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
              onClick={(e) => {
                if(this.state.bannerGalleryCollection.length < 6) {
                  this.setState({ editGallaryId: "" });
                  this.handleUploadPicsSelect(e);
                }
              }}
              disabled={this.state.bannerGalleryCollection.length >= 6}
            >
              <i className="fas fa-upload" />
              <span className="mx-3">Upload</span>
            </button>
          </div>
        </form>
      </div>
    );
  }

  // Handle file select
  handleUploadPicsSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("bannerUploadPics");
    fileSelector.click();
  };

  // Select file

  selectUploadPics = async (e) => {
    e.preventDefault();
    const id = this.state.editGallaryId;
    const bannerUploadPics = e.target.files[0];

    if (bannerUploadPics) {
      const fileSize = bannerUploadPics.size; // Size in bytes
      const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
      if (fileSize > maxSizeInBytes) {
        AppConfig.setMessage("Image size is too large. Please choose an image up to 20MB.");

        // Optionally, you can clear the input field
        e.target.value = null;
      } else {
        // Handle the valid image upload here
        const documentType = "bannerUploadPics";
        let requestData = {
          id: id ? id : "",
          image: bannerUploadPics,
          type: this.state.bannerType,
          documentType: documentType,
        };
        if (id) {
          requestData["updated_by"] = User.user_id;
        } else {
          requestData["created_by"] = User.user_id;
        }

        uploadFile(requestData, this.callBackUploadPics);
      }
    } else {
    }
  };

  //  Call back function for uploaded pics
  callBackUploadPics = (response = false) => {
    if (response && response.status === "success") {
      AppConfig.setMessage("Picture uploaded successfully", false);
      this.fecthBannerImagesApi(this.state.bannerType);
    } else {
      AppConfig.setMessage(response.result);
    }
  };

  validateAll = async () => {
    const oldPasswordInput = await this.oldPasswordCheck();
    const passwordInput = await this.passwordCheck();
    const confirmPasswordInput = await this.confirmPasswordCheck();
    if (oldPasswordInput && passwordInput && confirmPasswordInput) {
      return true;
    }
    return false;
  };

  // Delete banner image confirm modal
  deleteBannerImage = (id) => {
    this.setState({ deleteImageConfirmModal: true, deleteImageId: id });
  };

  // Delete banner image api function
  getBannerImageSuccess = () => {
  const id = this.state.deleteImageId;
    deleteBannerImage(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ deleteImageConfirmModal: false });
        AppConfig.setMessage("Deleted Successfully", false);
        this.fecthBannerImagesApi(this.state.bannerType);
      } else {
        // this.setState({ deleteImageConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };
}

export default observer(ManageBanner);
