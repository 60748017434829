import React from 'react';
import '../css/Common.css';
import {callApi} from '../apis/Api';
// import Icomoon from '../libraries/Icomoon';
// import moment from 'moment';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Header from './Header';
import Footer from '../pages/Footer';
// import Modal from 'react-bootstrap/Modal';
import ImageGallery from 'react-image-gallery';

class ViewProject extends React.Component{
    state={
       gallery:false, 
       galleryCollection:[],
       leftContent:false,
       previousProject:'',
       nextProject:'',
       id:'',
    }
    async componentDidMount() {
        const id = this.props.location.state.id
        if(id) {
            this.setState({ id }, () => {
                this.projectDetail();
                this.galleryApi();
            })
        }
	}

    // view single project details api
    projectDetail = async() => {
        const id = this.state.id;
        let functionUrl = 'view_project';
        const response = await callApi(functionUrl, {}, 'GET', id);
        const result = response.result;
        const previousProject = result.projectDetail;
        const embeddedURL = this.isValidYouTubeUrl(previousProject.youtubeURL) ? `https://www.youtube.com/embed/${this.getYouTubeVideoId(previousProject.youtubeURL)}` : null ;

        this.setState({
            name:previousProject.name,
            description:previousProject.description,
            date:previousProject.date,
            projectImage:previousProject.projectimage,
            previousProject:result.previousProject,
            nextProject:result.nextProject,
            youtubeURL: embeddedURL
        });
    }

    galleryApi = async() =>{
        // View Gallery api
        const { id } = this.state
        let galleryFunctionUrl = 'view_gallery';
        const response = await callApi(galleryFunctionUrl, {}, 'GET', id);
        const result = response.result
        let galleryCollection = [];
        for(let i in result.projectGalleries) {
            galleryCollection.push({original:result.projectGalleries[i].image})
        }
        this.setState({galleryCollection:galleryCollection })
    }
    
    render() {
        return(
            <>
                <Header/>
                    <div className='header-margin'>
                        {this.renderViewProject()} 
                    </div>

                <Footer props = {this.props}/>
            </>
        )
    }

    // Render view project
    renderViewProject(){
        // const { previousProject, nextProject } = this.state;
        return(
            <div className='col-md-12 px-0 mx-0 header-margin' style={{overflow:'hidden'}}>
                <div className='col-md-12 view-gallery-style position-absolute my-5' style={{left:'70px', zIndex:1}}>
                    {this.state.leftContent ?
                        <h1 className='font-style2 theme-font-color'>
                            {this.state.name ? this.state.name :'-'}
                        </h1>:
                        <h1 className='font-style2'>
                            {this.state.name ? this.state.name :'-'}
                        </h1>
                    }
                    {this.state.leftContent ?
                        <SlidingPane 
                            className="some-custom-class " 
                            overlayClassName="some-custom-overlay-class"
                            isOpen={this.state.leftContent}
                            from="left"
                            width='700px'
                            onRequestClose={() => {
                                this.setState({ leftContent: false });
                            }}
                            style={{left:'70px', zIndex: 999}}
                        >
                        <div className='col-md-12 mt-5 pt-5 ' style={{left:'50px'}}>
                            <p className='font-style normal-font-size' onClick={() => this.setState({leftContent:false})}>
                                <u>Close</u>
                            </p>
                            <div className='col-md-9'>
                                <p className='font-style small-font-size'>
                                    {this.state.description ? this.state.description === 'null':'-'}
                                </p>
                                 <div className='mt-5 pt-5'>
                                    {/* <p className='font-style small-font-size mt-5 pt-5'>
                                        Project Date <br/>
                                        {this.state.date ? moment(this.state.date).format('Do MMMM  YYYY'):'-'}
                                    </p> */}
                                    {/* <button type='btn'  className='font-style normal-font-size theme-font-color bg-transparent border-0' onClick={() => this.setState({gallery:true} ,()=>{this.galleryApi()})}>
                                        Gallery
                                    </button> */}
                                </div> 
                            </div>
                        </div>
                    </SlidingPane> 
                    :
                    <div className='col-md-6'>
                        <p className='font-style normal-font-size' onClick={() => this.setState({leftContent:true})}>
                            <u>Read Details</u>
                        </p>
                        <div className='mt-5 pt-5'>
                            {/* <p className='font-style small-font-size mt-5 pt-5'>
                                Project Date <br/>
                                {this.state.date ? moment(this.state.date).format('Do MMMM  YYYY'):''}
                            </p> */}
                            {/* <button type='btn' className='font-style normal-font-size theme-font-color bg-transparent border-0' onClick={() => this.setState({gallery:true},()=>{this.galleryApi()})}>
                                Gallery
                            </button> */}
                        </div>
                    </div>
                }
                </div>
                <div className='row'>
                    <div className='d-flex col-md-12 justify-content-center'>
                        <div className='col-md-10' style={{ zIndex: this.state.leftContent && -10}}>
                            {this.renderGallery()}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center col-md-12'>
                        <div className='col-md-10 pt-5'>
                            {/* <img className='pt-5 pointer' src={this.state.projectImage} alt='view' width='100%' onClick={() => this.setState({gallery:true},()=>{this.galleryApi()})}/> */}

                            {
                                this.state.youtubeURL && (
                                    <div className='mt-5'>
                                        <iframe className="w-100 rounded-2 custom-youtube-style" src={this.state.youtubeURL} title="Project youTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </div>
                                )
                            }
                            {/* <div className="col-md-11 d-flex justify-content-between pt-3">
                                {previousProject ? 
                                    <div className='d-flex justify-content-start'>
                                        <button type='btn' className='btn font-style big-font-size our-project-container page-button bg-transparent border-0 d-flex justify-content-start' onClick={() => this.togglePrevious(previousProject)}>
                                            Previous Project
                                        </button>
                                    </div> 
                                : 
                                null }
                                {nextProject ? 
                                    <div className='d-flex justify-content-end mr-3'>
                                        <button type='btn' className='btn font-style big-font-size our-project-container page-button bg-transparent border-0 d-flex justify-content-end' onClick={() => this.toggleNext(nextProject)}>
                                            Next Project
                                        </button> 
                                    </div> 
                                :
                                null }
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="mobile_project mx-5">
                    <h1 className='font-style2 theme-font-color'>
                        {this.state.name ? this.state.name :'-'}
                    </h1>
                    {/* <p className='font-style small-font-size'>
                        Project Date : &nbsp;
                        {this.state.date ? moment(this.state.date).format('Do MMMM  YYYY'):''}
                    </p> */}
                    {/* <button type='btn' className='font-style normal-font-size theme-font-color bg-transparent border-0' onClick={() => this.setState({gallery:true},()=>{this.galleryApi()})}>
                        Gallery
                    </button> */}
                </div>
            </div>    
        )
    }

    // Handle previous button
    togglePrevious = (id) => {
        this.setState({ id }, () => {
            this.projectDetail();
        })
    }

    // Handle next button
    toggleNext = (id) => {
        this.setState({ id }, () => {
            this.projectDetail();
        })
    }

    // to validate youtube url
    isValidYouTubeUrl = (url) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
        return youtubeRegex.test(url);
    };

    // To get youtube video ID
    getYouTubeVideoId(url) {
        // Extract video ID from various YouTube URL formats
        const patterns = [
          /youtu\.be\/(\w+)/,
          /youtube\.com.*[?&]v=([^&]+)/,
          /youtube\.com.*embed\/([^&]+)/,
          /youtube\.com.*[?&]video_id=([^&]+)/,
        ];
        
        for (const pattern of patterns) {
          const match = url.match(pattern);
          if (match) {
            return match[1];
          }
        }
        return null; // Return null if no match is found
    }

    // Render gallery function

    // renderGallery = () =>{
    //     const {galleryCollection} = this.state;
    //     return(
    //         <Modal
    //             size='xl'
    //             className="border-style rounded"
    //             aria-labelledby="contained-modal-title-vcenter"
    //             show={this.state.gallery}
    //         >    
    //         <div className="p-3">
    //             <div className="d-flex justify-content-end mx-3">
    //                 <Icomoon style={{cursor:'pointer'}} icon="close" size={20} onClick={()=>{this.setState({gallery:false})}}/>
    //             </div>
    //             <Modal.Body>
    //                 <div className='p-3'>
    //                     <ImageGallery 
    //                             items={galleryCollection}
    //                             showBullets={true}
    //                             showIndex={true}
    //                             showThumbnails={false}
    //                             lazyLoad={true}
    //                             showPlayButton={true}
    //                             autoPlay={true}
    //                          />
    //                     {/* {this.state.galleryCollection.map((galleryCollection) => 
    //                         <div className='col-md-3 p-2 mx-0'>
    //                             <img src={galleryCollection.image} alt={galleryCollection.id} width='100%' height='250px'/>
    //                         </div> 
    //                     ) }  */}
    //                 </div> 
    //             </Modal.Body>
    //         </div>
    //     </Modal> 
    //     )
    // }
    
    renderGallery = () =>{
        const {galleryCollection} = this.state;
        return(
            <div className="mt-5 pt-5">
                {/* <div className="d-flex justify-content-end mx-3">
                    <Icomoon style={{cursor:'pointer'}} icon="close" size={20} onClick={()=>{this.setState({gallery:false})}}/>
                </div> */}
                    <div className='mt-5'>
                        <ImageGallery 
                            items={galleryCollection}
                            showBullets={true}
                            showIndex={true}
                            showThumbnails={false}
                            lazyLoad={true}
                            showPlayButton={true}
                            autoPlay={true}
                        />
                        {/* {this.state.galleryCollection.map((galleryCollection) => 
                            <div className='col-md-3 p-2 mx-0'>
                                <img src={galleryCollection.image} alt={galleryCollection.id} width='100%' height='250px'/>
                            </div> 
                        ) }  */}
                    </div> 
            </div>
        )
    }   
}
export default ViewProject
