// Manage testimonial page

import React from "react";
import { Layout } from "../../common/Element";
import "../../css/DataTable.css";
import { observer } from "mobx-react";
import Icomoon from "../../libraries/Icomoon";
import Modal from "react-bootstrap/Modal";
import "../../css/Common.css";
import { deleteTestimonial, testimonialList } from "../../apis/Testimonial";
import AppConfig from "../../modal/AppConfig";
import ConfirmModal from "../../common/ConfirmModal";
import CreateTestimonial from "./CreateTestimonial";
import { Box, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import { Table } from "react-bootstrap";

class TestimonialContent extends React.Component {
  state = {
    size: 10,
    current_page: 1,
    rows: [],
    // columns: [],
    totalData: 0,
    consultDataModal: false,
    order: 'asc',
    page: 0,
    orderBy: '',
  };

  async componentDidMount() {
    // const columns = [
    //   { field: "id", headerName: "#", hide: false, minWidth: 80 }, // 150
    //   { field: "name", headerName: "Name", minWidth: 170 }, // 150
    //   { field: "description", headerName: "Description", minWidth: 280 }, // 150 reviewLink
    //   { field: "imageUrl", headerName: "Image URL", minWidth: 220 }, // 150
    //   { field: "reviewLink", headerName: "Review Link", minWidth: 220 }, // 150
    //   {
    //     field: "action",
    //     headerName: "Action",
    //     minWidth: 162, // 150
    //     renderCell: (param) => {
    //       return (
    //         <div>
    //           <Icomoon
    //             className="pointer mx-2"
    //             icon="pen-solid"
    //             size={16}
    //             color="#E40045"
    //             onClick={() => this.editTestimonial(param.row.id)}
    //           />
    //           <Icomoon
    //             className="pointer mx-2"
    //             icon="trash-solid"
    //             size={16}
    //             color="#E40045"
    //             onClick={() => this.deleteTestimonial(param.row.id)}
    //           />
    //           <i
    //             className="fas fa-eye theme-font-color pointer"
    //             onClick={() =>
    //               this.setState({
    //                 consultDataModal: true,
    //                 name: param.row.name,
    //                 description: param.row.description,
    //                 imageUrl: param.row.imageUrl,
    //                 reviewLink: param.row.reviewLink,
    //               })
    //             }
    //           />
    //         </div>
    //       );
    //     },
    //   },
    // ];

    // Api call
    this.TestimonialListApi();
    // this.setState({ columns: columns });
  }

  TestimonialListApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
    };
    const response = await testimonialList(requestData);
    if (response && response.status === "success") {
      const {data, total } = response.result;
      const apiRows = data?.map((result, index) => ({
        id: result.id,
        slNumber: Number(index) + 1,
        name: result.name,
        description: result.description,
        imageUrl: result.image && (result.image !== "null" || null) ? result.image : "",
        reviewLink:
          result.review_link && (result.review_link !== "null" || null)
            ? result.review_link
            : "",
      }))
      this.setState({ rows: apiRows, totalData: total });
    }
  };

  // Edit testimonial modal
  editTestimonial = (id) => {
    this.setState({ editTestimonialId: id, status: true });
  };

  // Delete testimonial confirm modal
  deleteTestimonial = (id) => {
    this.setState({ viewTestimonialConfirmModal: true, deleteId: id });
  };

  // Delete Testimonial api function
  getTestimonialSuccess = () => {
    const id = this.state.deleteId;
    deleteTestimonial(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewTestimonialConfirmModal: false });
        AppConfig.setMessage("Deleted Successfully", false);
        this.TestimonialListApi();
      } else {
        this.setState({ viewTestimonialConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };

  render() {
    return (
      <Layout>
        <div className="px-5 pb-5" style={{ marginTop: "4rem" }}>
          <div className="d-flex justify-content-between">
            <p className="theme-font-color align-self-center">
              Testimonials
            </p>
            <button
              className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
              onClick={() =>
                this.setState({ status: true, editTestimonialId: "" })
              }
            >
              CREATE TESTIMONIAL
            </button>
          </div>
          <div className="mt-2">
            {this.renderTable()}
          </div>
        </div>
        <ConfirmModal
          delete={true}
          visible={this.state.viewTestimonialConfirmModal}
          heading="Delete Testimonial"
          title="Are you sure you want to delete the testimonial?"
          confirm={() => this.getTestimonialSuccess()}
          handleClose={() =>
            this.setState({ viewTestimonialConfirmModal: false })
          }
        />

        <CreateTestimonial
          id={AppConfig.user_id}
          edit={this.state.editTestimonialId}
          status={this.state.status}
          closeModel={() => this.setState({ status: false, editTestimonialId: ''})}
          TestimonialListApi={this.TestimonialListApi}
        />
      </Layout>
    );
  }

  headCells = [
    {
      id: 'slNumber',
      label: '#',
      width: '5%',
      align: 'center'
    },
    {
      id: 'name',
      label: 'Name',
      width: '15%',
    },
    {
      id: 'description',
      label: 'Description',
      width: '25%',
    },
    {
      id: 'imageUrl',
      label: 'Image Url',
      width: '20%',
    },
    {
      id: 'reviewLink',
      label: 'Review Link',
      width: '20%',
    },
    {
      id: 'action',
      label: 'Action',
      width: '15%',
      align: 'center'
    },
  ];

  handleRequestSort = (property) => {
    this.setState((prevState) => {
      const { orderBy, order, rows } = prevState;
      const isAsc = orderBy === property && order === 'asc';

      const sortedRows = [...rows].sort((a, b) => {
        if (property === 'slNumber') {
          return isAsc ? a.slNumber - b.slNumber : b.slNumber - a.slNumber;
        }
        if (property === 'name') {
          return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }
        if (property === 'description') {
          return isAsc ? a.description.localeCompare(b.description) : b.description.localeCompare(a.description);
        }
        if (property === 'imageUrl') {
          return isAsc ? a.imageUrl.localeCompare(b.imageUrl) : b.imageUrl.localeCompare(a.imageUrl);
        }
        if (property === 'reviewLink') {
          return isAsc ? a.reviewLink.localeCompare(b.reviewLink) : b.reviewLink.localeCompare(a.reviewLink);
        }
        return 0;
      }); 

      return {
        orderBy: property,
        order: isAsc ? 'desc' : 'asc',
        rows: sortedRows,
      };
    });
  };

  renderTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <Box sx={{ width: '100%' }} >
          <Paper sx={{ width: '100%'}} style={{
             border: "1px solid #E40045",
             borderRadius: '5px',
             backgroundColor: '#f7f7f7'
          }}>
            <TableContainer >
              <Table
                sx={{ minWidth: 750 }}
              >
                <TableHead>
                  <TableRow style={{ borderColor: 'red'}}>
                    {this.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                        width={headCell.width}
                        align={headCell.align ? headCell.align : 'left'}
                      >
                        <TableSortLabel
                          active={this.state.orderBy === headCell.id}
                          direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                          onClick={() => this.handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.rows.map((row) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="left" style={{ padding: '20px 10px' }}> {row.slNumber} </TableCell>
                        <TableCell align="left" style={{ padding: '20px 10px' }}> {row.name || '-'} </TableCell>
                        <TableCell align="left" className="text-truncate" style={{maxWidth: "150px", padding: '20px 10px'}} >
                          {row.description || '-'}
                        </TableCell>
                        <TableCell align="left" className="text-truncate" style={{maxWidth: "150px", padding: '20px 10px'}}>{row.imageUrl || '-'}</TableCell>
                        <TableCell align="left" style={{ padding: '20px 10px' }}>{row.reviewLink || '-'}</TableCell>
                        <TableCell align="center" style={{ padding: '20px 10px' }}>
                          <div className="d-flex justify-content-center">
                            <Icomoon
                              className="pointer mx-2"
                              icon="pen-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.editTestimonial(row.id)}
                            />
                            <Icomoon
                              className="pointer mx-2"
                              icon="trash-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.deleteTestimonial(row.id)}
                            />
                            <i
                              className="fas fa-eye theme-font-color pointer"
                              onClick={() =>
                                this.setState({
                                  consultDataModal: true,
                                  name: row.name,
                                  description: row.description,
                                  imageUrl: row.imageUrl,
                                  reviewLink: row.reviewLink,
                                })
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {this.state.rows.length === 0 && (
                <div className="d-flex justify-content-center align-items-center" style={{
                  height: '300px'
                }}>
                  <p>
                    No records found
                  </p>
                </div>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.size}
              page={this.state.page}
              onPageChange={(e, newPage) => 
                this.setState({ size: this.state.size, current_page: newPage + 1, page: newPage }, () =>
                this.TestimonialListApi()
              )}
              onRowsPerPageChange={(e) =>
                this.setState({ size: e.target.value, current_page: 1 }, () =>
                  this.TestimonialListApi()
                )
              }
            />
          </Paper>
        </Box>
        {this.renderConsultDataModal()}
      </div>
    );
  };

  renderConsultDataModal = () => {
    return (
      <Modal
        size="md"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.consultDataModal}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="medium-font-size theme-font-color">
              Testimonial Details
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.setState({ consultDataModal: false });
              }}
            />
          </div>
          <Modal.Body>
            <ul className="py-0 mt-0 normal-font-size">
              <li className="d-flex gap-5 ">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  {" "}
                  Name:
                </p>{" "}
                <p className=""> {this.state.name || '-'}</p>
              </li>
              <li className="d-flex gap-3">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  {" "}
                  Description:
                </p>{" "}
                <p>{this.state.description || '-'}</p>
              </li>
              <li className="d-flex gap-3">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  {" "}
                  Image URL:
                </p>{" "}
                {this.state.imageUrl ? (
                  <img
                    src={this.state.imageUrl}
                    className="w-75 h-50 rounded-3"
                    alt="testimonial_image"
                  />
                ) : (
                  "-"
                )}
              </li>
              <li className="d-flex gap-3">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  {" "}
                  Review Link:
                </p>{" "}
                <p>{this.state.reviewLink || '-'}</p>
              </li>
            </ul>
          </Modal.Body>
        </div>
      </Modal>
    );
  };
}
export default observer(TestimonialContent);
