import User from "../modal/User";
import { callApi } from "./Api";
import "./Global";

/**
 *
 * @param{email, password)data
 */
export const login = async (requestData = {}) => {
  const functionUrl = "login";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{email)data
 */
export const forgot = async (requestData = {}) => {
  const functionUrl = "forgot";
  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        return response;
      })
      // .catch((error) => {
      //   console.log("Error from API => ", error);
      // });
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};

// /**
//  *
//  * @param{otp)data
//  */
// export const otp = async(requestData={}) => {
//     const functionUrl = "otp";
//     try {
//         return await callApi(functionUrl, requestData )
//             .then((response) => {
//                 return response;
//             })
//             .catch((error) => {
//                 console.log("Error from API => ", error);
//             });
//     } catch (error) {
//         console.log("Error from catch => ", error);
//     }
// };

/**
 *
 * @param{new & old password)data
 */
export const changePassword = async (requestData = {}) => {
  const functionUrl = "change_password";
  const id = User.user_id;
  try {
    return await callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        return response;
      })
      // .catch((error) => {
      //   console.log("Error from API => ", error);
      // });
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};

/**
 *
 * @param{view mails)data
 */
export const viewMail = async (requestData = {}) => {
  const functionUrl = "view_mails";

  try {
    return await callApi(functionUrl, requestData)
      .then((response) => {
        return response;
      })
      // .catch((error) => {
      //   console.log("Error from API => ", error);
      // });
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};

export const getContents = async () => {
  const functionUrl = "get_contents";

  try {
    return await callApi(functionUrl, {}, "GET")
      .then((response) => {
        return response;
      })
      // .catch((error) => {
      //   console.log("Error from API => ", error);
      // });
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};

export const updateContents = async (requestData = {}) => {
  const functionUrl = "update_content";

  try {
    return await callApi(functionUrl, requestData, "PUT")
      .then((response) => {
        return response;
      })
      // .catch((error) => {
      //   console.log("Error from API => ", error);
      // });
  } catch (error) {
    // console.log("Error from catch => ", error);
  }
};
