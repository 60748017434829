import React from "react";
import "../css/Common.css";
import Followus from "../images/home/followus.svg";
import { callApi } from "../apis/Api";
import Icomoon from "../libraries/Icomoon";
// import moment from 'moment';
import Header from "./Header";
import Footer from "../pages/Footer";

class OurProjects extends React.Component {
  state = {
    projects: [],
  };
  async componentDidMount() {
    // document.title = `Luxury Interior Designers in Bangalore | DezignCode`;
    // document.querySelector('meta[name="description"]').content =
    //   "DezignCode is one of the best luxury interior designers in bangalore with 6+ years of experience & served more than 200+ clients, we are specialize in home and commercial interior space designing experts. Visit us Today!";
    let functionUrl = "projects";
    const response = await callApi(functionUrl, {}, "GET");
    const result = response.result;
    let projects = [];
    for (let i in result.projectslist) {
      projects.push(result.projectslist[i]);
    }
    this.setState({ projects: projects });
  }
  render() {
    return (
      <>
        <Header />
        <div className="p-5">{this.renderOurProjects()}</div>
        <div className="p-5">{this.renderFollowUs()}</div>
        <Footer props={this.props} />
      </>
    );
  }

  // Render function for our projects
  renderOurProjects() {
    return (
      <div className="mt-5">
        <div className="mt-5">
          <h1 className="theme-font-color font-weight-bold font-style2 mb-4">
            Our Simple and Elegant Projects
          </h1>
          <h2
            className="theme-font-color font-weight-bold font-style2"
            style={{ fontSize: "180%" }}
          >
            THE FUTURE OF DESIGN IS HERE
          </h2>
        </div>
        <div>
          <div className="row">
            {this.state.projects.map((projects, i) => (
              <div
                className="col-md-4 our-project-container pointer my-3"
                key={i}
                onClick={() => this.viewProjects(projects.id, projects.name.toLowerCase())}
              >
                <div
                  className="col-md-11  bg-white p-2"
                  style={{ borderRadius: "25px" }}
                >
                  <img
                    style={{ borderRadius: "25px" }}
                    src={projects.thumbnailimage}
                    alt={projects.alt_tag}
                    width="100%"
                    height="90%"
                  />
                </div>
                <div className="our-project-style">
                  <p className="font-weight-bold big-font-size">
                    {projects.name}
                  </p>
                  <p className="font-weight-bold big-font-size">
                    {projects.title}
                  </p>
                  {/* <p className="text-white small-font-size">
                                        {projects.date ? moment(projects.date).format('Do MMMM  YYYY'):'-'}
                                    </p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Render function for follows us
  renderFollowUs() {
    return (
      <div className="row">
        <div className="col-md-6 pl-5">
          <h1 className="theme-font-color font-weight-bold font-style2 follow-us font-weight-bolder">
            FOLLOW US
          </h1>
          <p style={{ fontSize: "40px" }} className="font-style">
            Instagram
          </p>
          <button className="bg-dark border-0 text-white p-2 px-4 my-3 font-style">
            <a
              className="text-decoration-none text-white"
              href="https://www.instagram.com/dezign_code/"
              target="_blank"
              rel="noreferrer"
            >
              <Icomoon
                icon="instagram1"
                className="theme-font-color mr-3"
                color="#f222ff"
              />
              Follow us --
            </a>
          </button>
        </div>
        <div className="col-md-6  pl-5">
          <img src={Followus} alt="follow-us" width="90%" />
        </div>
      </div>
    );
  }

  viewProjects = (id, projectName) => {
    this.props.history.push({
      pathname: `/projects/${projectName.replace(/ /g, "-")}`,
      state: { id },
    });
  };
}

export default OurProjects;
