import Loader from "../images/loader.gif";
import "./customLoaderModal.css";

const CustomLoaderModal = ({ loader }) => {
  return (
    <>
      {loader ? (
        <div className="">
          <div
            className="form-loader-modal"
            style={{display: "block", zIndex: 99999}}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="">
                <div className="modal-content-loader modal-body">
                  <div className="d-flex justify-content-center m-5 p-5">
                    <img src={Loader} alt="loader" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default CustomLoaderModal;
