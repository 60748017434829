import LeftMenu from "./Menu/LeftMenu";
import Facebook from "../images/home/facebook.png";
import Instagram from "../images/home/instagram.png";
import Youtube from "../images/home/youtube.png";
import LinkedIn from "../images/home/linkedin.png";
import { observer } from "mobx-react";
import AppConfig from "../modal/AppConfig";
import { Route, Redirect, useLocation } from "react-router-dom";

// Render layout
export const Layout = (props) => {
  return <LeftMenu children={props.children} />;
};

// Render Social Media link

export const SocialMediaLink = () => {
  return (
    <ul id="social_side_links">
      <li>
        <a
          href="https://www.facebook.com/thedezigncode"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Facebook} alt="" width="35px" height="35px" />
        </a>
      </li>
      <li>
        <a
          // href="https://youtube.com/CodeDezign"
          href="https://www.youtube.com/@dezigncode6246"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Youtube} alt="" width="35px" height="35px" />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/dezign_code/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Instagram} alt="" width="35px" height="35px" />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/the-dezign-code/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={LinkedIn} alt="" width="35px" height="35px" />
        </a>
      </li>
    </ul>
  );
};

/**
 *
 * @param{props} route elements
 * @returns page route
 */
export const PublicRoute = observer((props) => {
  const currentPath = useLocation();
  const routes = ["/"];
  if (AppConfig.api_key && routes.includes(currentPath.pathname)) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
});
/**
 *
 * @param {*} route elements
 * @returns square page route
 */
export const PrivateRoute = ({ component: Component, ...props }) => {
  // console.log("props", props);
  const token = sessionStorage.getItem("app")
  return (
    <Route
      {...props}
      render={(props) =>
        (AppConfig.api_key !== "" || token.api_key !== "") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
