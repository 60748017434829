import {callApi} from './Api';
import './Global';

/**
 *
 * @param{email data)
 */
export const emailForm = async(requestData={}) => {
    const functionUrl = "subscription";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{name, email, phone number)data
 */
export const sendMail = async(requestData={}) => {
    const functionUrl = "send_email";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{email data)
 */
export const consultOnline = async(requestData={}) => {
    const functionUrl = "consult_online";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};
