import React from "react";
import "../css/Common.css";
import Icomoon from "../libraries/Icomoon";
import Person from "../images/modalperson.svg";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { consultOnline } from "../apis/Form";
import {
  CheckUserName,
  CheckEmail,
  CheckPhone,
  DropDownCheck,
} from "../common/Validation";
import AppConfig from "../modal/AppConfig";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router-dom";
import CustomLoaderModal from "../common/CustomLoaderModal";

class ConsultModal extends React.Component {
  state = {
    consultModal: false,
    date: new Date(),
    name: "",
    mobileNumber: "",
    email: "",
    propertyType: "",
    propertyDetails: "",
    appointmentType: "",
    toastMessage: false,
    error: "",
    status: false,
    home: "",
    loading: false,
  };

  resetState = () => {
    this.setState({
      consultModal: false,
      date: new Date(),
      name: "",
      mobileNumber: "",
      email: "",
      propertyType: "",
      propertyDetails: "",
      appointmentType: "",
      toastMessage: false,
      error: "",
      status: false,
      loading: false,
      home: "",
      dateError: "",
      nameError: "",
      emailError: "",
      phoneError: "",
      propertyTypeError: "",
      propertyDetailsError: "",
      appointmentTypeError: "",
    });
  };

  render() {
    return (
      <>
        <div>
          {this.props.home === "service" ? (
            <button
              className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3 btn-lg btn-block tab-btn"
              onClick={() => this.setState({ consultModal: true })}
            >
              REQUEST A QUOTE TODAY
            </button>
          ) : (
            <>
              <button className="consult-btn">
                <i
                  className="icon-size fas fa-headset"
                  onClick={() => {
                    this.setState({ consultModal: true });
                  }}
                ></i>{" "}
                <span
                  className="hidesm"
                  onClick={() => {
                    this.setState({ consultModal: true });
                  }}
                >
                  {this.props.service === "service"
                    ? "CONSULT ONLINE"
                    : "CONSULT ONLINE NOW"}
                </span>
              </button>
              {/* <button className="btn theme-button normal-font-size mx-2" onClick={()=>{this.setState({consultModal:true})}}>CONSULT ONLINE NOW</button> */}
            </>
          )}

          <div>
            {this.renderConsultNowModal()}
          </div>
        </div>
      </>
    );
  }

  // Render create Consult modal function

  renderConsultNowModal = () => {
    return (
      <div className="row">
        <Modal
          fullscreen="lg-down"
          size="xl"
          className="border-style rounded"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.consultModal}
        >
          <div className="p-3">
            <CustomLoaderModal loader={this.state.loading} />
            <div className="d-flex justify-content-end mx-3">
              <Icomoon
                className="pointer"
                icon="close"
                size={20}
                onClick={() => {
                  this.setState({ consultModal: false });
                  this.resetState();
                }}
              />
            </div>
            <Modal.Body>
              <form onSubmit={this.onSubmitConsultNow}>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-4 px-0 ml-0 pt-3">
                        <img
                          className="ml-lg-5"
                          src={Person}
                          alt="consult"
                          width="100%"
                          height="580"
                        />
                      </div>
                      <div className="col-lg-7 px-0 border-style">
                        <div className="calender-bg-color p-3 ">
                          <Icomoon
                            className="ml-2"
                            style={{ cursor: "pointer" }}
                            icon="calendar"
                            size={20}
                          />
                          <span className="normal-font-size theme-font-color ml-3">
                            SCHEDULE
                          </span>
                        </div>
                        <div className="calender-bg-color px-3">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk
                              disableToolbar
                              variant="static"
                              openTo="date"
                              value={this.state.date}
                              onChange={this.dateHandleChange}
                              minDate={new Date()}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="d-flex justify-content-start">
                          {this.state.dateError ? (
                            <span className="small-font-size text-danger">
                              {" "}
                              {this.state.dateError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <TextField
                      className="col-lg-12 my-2"
                      id="full-name"
                      label="Full Name"
                      value={this.state.name}
                      onChange={(e) =>
                        this.setState({ name: e.target.value, nameError: "" })
                      }
                      onFocus={() => this.setState({ nameError: "" })}
                    />
                    <div className="d-flex justify-content-start">
                      {this.state.nameError ? (
                        <span className="small-font-size text-danger">
                          {" "}
                          {this.state.nameError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <TextField
                      className="col-lg-12 my-2"
                      id="email"
                      label="Email"
                      value={this.state.email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value, emailError: "" })
                      }
                      onFocus={(e) => this.setState({ emailError: "" })}
                    />
                    <div className="d-flex justify-content-start">
                      {this.state.emailError ? (
                        <span className="small-font-size text-danger">
                          {" "}
                          {this.state.emailError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <TextField
                      className="col-lg-12 my-2"
                      id="mob-num"
                      label="Mobile Number"
                      value={this.state.mobileNumber}
                      onChange={(e) =>
                        this.setState({
                          mobileNumber: e.target.value,
                          phoneError: "",
                        })
                      }
                      onFocus={(e) => this.setState({ phoneError: "" })}
                    />
                    <div className="d-flex justify-content-start">
                      {this.state.phoneError ? (
                        <span className="small-font-size text-danger">
                          {" "}
                          {this.state.phoneError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <FormControl className="col-lg-12 my-2">
                      <InputLabel id="property-type">Property Type</InputLabel>
                      <Select
                        labelId="property-type"
                        id="property-type"
                        value={this.state.propertyType}
                        onChange={(e) =>
                          this.setState({
                            propertyType: e.target.value,
                            propertyTypeError: "",
                          })
                        }
                      >
                        <MenuItem value="villa">Villa</MenuItem>
                        <MenuItem value="independent-house">
                          Independent House
                        </MenuItem>
                        <MenuItem value="appartment">Appartment</MenuItem>
                        <MenuItem value="commercial">Commercial</MenuItem>
                      </Select>
                    </FormControl>
                    <span className="my-3 xsmall-font-size">
                      If Villa / Independent House / Apartment
                    </span>
                    <div className="d-flex justify-content-start">
                      {this.state.propertyTypeError ? (
                        <span className="small-font-size text-danger">
                          {" "}
                          {this.state.propertyTypeError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.propertyType !== "commercial" && (
                      <div>
                        <FormControl className="col-lg-12 my-2">
                          <InputLabel id="property-details">
                            Property Details
                          </InputLabel>
                          <Select
                            labelId="property-details"
                            id="property-details"
                            value={this.state.propertyDetails}
                            onChange={(e) =>
                              this.setState({
                                propertyDetails: e.target.value,
                                propertyDetailsError: "",
                              })
                            }
                          >
                            {this.state.propertyType !== "villa" && (
                              <MenuItem value="1-bhk">1 bhk</MenuItem>
                            )}
                            <MenuItem value="2-bhk">2 bhk</MenuItem>
                            <MenuItem value="3-bhk">3 bhk</MenuItem>
                            {this.state.propertyType === "villa" && (
                              <MenuItem value="4-bhk">4 bhk</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        <div className="d-flex justify-content-start">
                          {this.state.propertyDetailsError ? (
                            <span className="small-font-size text-danger">
                              {" "}
                              {this.state.propertyDetailsError}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}

                    <FormControl className="col-lg-12 my-2">
                      <InputLabel id="appointment-type">
                        Appointment Type
                      </InputLabel>
                      <Select
                        labelId="appointment-type"
                        id="appointment-type"
                        value={this.state.appointmentType}
                        onChange={(e) =>
                          this.setState({
                            appointmentType: e.target.value,
                            appointmentTypeError: "",
                          })
                        }
                      >
                        <MenuItem value="studio-visit">Studio Meet</MenuItem>
                        <MenuItem value="site-visit">Site Meet</MenuItem>
                        <MenuItem value="online">Online</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="d-flex justify-content-start">
                      {this.state.appointmentTypeError ? (
                        <span className="small-font-size text-danger">
                          {" "}
                          {this.state.appointmentTypeError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      type="submit"
                      className={`theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-2 mt-5 font-style`}
                      onClick={() => window.gtag_report_conversion()}
                    >
                      LETS GET IN TOUCH --
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    );
  };

  // Handle change date
  dateHandleChange = (date) => {
    this.setState({ date, dateError: "" });
  };

  // Validation for  name
  validateName = () => {
    const nameError = CheckUserName(this.state.name);
    if (nameError === 1) {
      this.setState({ nameError: "Please enter your name" });
      return false;
    } else if (nameError === 2) {
      this.setState({ nameError: "Please enter valid name" });
      return false;
    } else return true;
  };

  // Validation for email

  validateEmail = () => {
    const emailError = CheckEmail(this.state.email);
    if (emailError === 1) {
      this.setState({ emailError: "Please enter your email address" });
      return false;
    } else if (emailError === 2) {
      this.setState({ emailError: "Please enter your valid email address" });
      return false;
    } else return true;
  };

  // Validation for phone Number
  validatephoneNumber = () => {
    const phoneNumberError = CheckPhone(this.state.mobileNumber);
    if (phoneNumberError === 1) {
      this.setState({ phoneError: "Please enter your phone number" });
      return false;
    } else if (phoneNumberError === 2) {
      this.setState({ phoneError: "Please enter valid phone number" });
      return false;
    } else return true;
  };

  // Validation for date
  validateDate = () => {
    const dateError = DropDownCheck(this.state.date);
    if (dateError === 1) {
      this.setState({ dateError: "Please select date" });
      return false;
    } else return true;
  };
  // Validation for property type
  validatePropertyType = () => {
    const dateError = DropDownCheck(this.state.propertyType);
    if (dateError === 1) {
      this.setState({ propertyTypeError: "Please select your property type" });
      return false;
    } else return true;
  };
  // Validation for property details
  validatePropertyDetails = () => {
    const dateError = DropDownCheck(this.state.propertyDetails);
    if (this.state.propertyType !== "commercial" && dateError === 1) {
      this.setState({
        propertyDetailsError: "Please select your property details",
      });
      return false;
    } else {
      this.setState({
        propertyDetailsError: "",
      });
      return true;
    }
  };

  // Validation for appointment date
  validateAppointmentType = () => {
    const dateError = DropDownCheck(this.state.appointmentType);
    if (dateError === 1) {
      this.setState({
        appointmentTypeError: "Please select your appointment type",
      });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const nameInput = this.validateName();
    const emailInput = this.validateEmail();
    const phoneNumberInput = this.validatephoneNumber();
    const dateInput = this.validateDate();
    const propertyTypeInput = this.validatePropertyType();
    const propertyDetailsInput = this.validatePropertyDetails();
    const appointmentTypeInput = this.validateAppointmentType();

    if (
      nameInput &&
      emailInput &&
      phoneNumberInput &&
      dateInput &&
      propertyTypeInput &&
      propertyDetailsInput &&
      appointmentTypeInput
    ) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit consult now form function
  onSubmitConsultNow = async (e) => {
    e.preventDefault();

    const allValidation = this.ValidateAll();
    if (allValidation) {
      this.setState({ loading: true });
      const requestData = {
        name: this.state.name,
        email: this.state.email,
        mobile_number: this.state.mobileNumber,
        date: moment(this.state.date).format("Do MMMM  YYYY"),
        property_type: this.state.propertyType,
        property_details: this.state.propertyType !== "commercial" ? this.state.propertyDetails : "N/A",
        appointment_type: this.state.appointmentType,
        title: "Consult-Online-Now",
      };

      const response = await consultOnline(requestData);
      if (response.status === "success") {
        this.resetState();
        this.props.history.push("/thank-you");
        this.setState({ consultModal: false, loading: false });
        AppConfig.setMessage(response.result, false);
      } else if (response.status === "error") {
        AppConfig.setMessage(response.result);
        this.setState({ loading: false });
      }
    }
  };
}
export default withRouter(ConsultModal);
