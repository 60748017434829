import React from "react";
import "../../css/Common.css";
import Icomoon from "../../libraries/Icomoon";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AppConfig from "../../modal/AppConfig";
import Modal from "react-bootstrap/Modal";
import {
  createService,
  getService,
  uploadFile,
  updateService,
} from "../../apis/Service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { observer } from "mobx-react";
import User from "../../modal/User";
import { serviceCategories } from "../../pages/serviceData";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class CreateService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      description: "",
      createService: false,
      title: "",
      serviceType: "",
      // category: "",
      thumbnailImage: "",
      titleError: "",
      serviceTypeError: "",
      descriptionError: "",
      thumbnailImageError: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  resetState = () => {
    this.setState({
      id: "",
      description: "",
      serviceType: "",
      // category: "",
      createService: false,
      title: "",
      thumbnailImage: "",
      titleError: "",
      serviceTypeError: "",
      descriptionError: "",
      thumbnailImageError: "",
    });
  };

  async componentDidUpdate(prevProps) {
    if (this.props.edit && this.props.edit !== prevProps.edit) {
      const id = this.props.edit;
      const response = await getService(id);
      const result = response.result.serviceDetails;
      if (response && response.status === "success") {
        this.setState({
          id: id,
          title: result.title,
          description: result.description,
          serviceType: result.serviceType,
          category: result.category,
          thumbnailImage: result.thumbnailImage,
        });
      }
    }
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  render() {
    return (
      <div>
        <CustomLoaderModal loader={this.state.loading} />
        {this.renderCreateServiceModal()}
      </div>
    );
  }

  // Render create service modal function

  renderCreateServiceModal = () => {
    return (
      <Modal
        size="lg"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.status}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              {this.props.edit ? "Edit Service" : "Create Service"}
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.props.closeModel(false);
                this.resetState();
              }}
            />
          </div>
          <Modal.Body>
            <form onSubmit={this.onSubmitService} encType="multipart/form-data">
              <div>
                <TextField
                  className="col-md-12 my-2"
                  id="title"
                  label="Title"
                  value={this.state.title}
                  onChange={(e) =>
                    this.setState({ title: e.target.value, titleError: "" })
                  }
                  onFocus={() => this.setState({ titleError: "" })}
                />
                <div className="d-flex justify-content-start">
                  {this.state.titleError && (
                    <span className="small-font-size text-danger">
                      {this.state.titleError}
                    </span>
                  )}
                </div>

                {/* <FormControl className="col-md-12 mb-3 col-lg-12 me-1">
                  <InputLabel id="category">Category</InputLabel>
                  <Select
                    labelId="category"
                    id="category"
                    value={this.state.category}
                    onChange={(e) => {
                      this.setState({
                        category: e.target.value,
                        categoryError: "",
                      });
                    }}
                    onFocus={() => this.setState({ categoryError: "" })}
                  >
                    <MenuItem value="residential-interior">
                      Residential Interior
                    </MenuItem>
                    <MenuItem value="commercial-interior">
                      Commercial Interior
                    </MenuItem>
                  </Select>
                </FormControl>

                <div className="d-flex justify-content-start">
                  {this.state.categoryError && (
                    <span className="small-font-size text-danger">
                      {this.state.categoryError}
                    </span>
                  )}
                </div> */}

                <FormControl className="col-md-12 mb-3 col-lg-12 me-1">
                  <InputLabel id="service-type">Service type</InputLabel>
                  <Select
                    labelId="service-type"
                    id="service-type"
                    value={this.state.serviceType}
                    onChange={(e) => {
                      this.setState({
                        serviceType: e.target.value,
                        serviceTypeError: "",
                      });
                    }}
                    onFocus={() => this.setState({ serviceTypeError: "" })}
                  >
                    {/* {serviceCategories.filter((item) => item.category === this.state.category)?.map((category) => (
                      <MenuItem value={category.value} key={category.value}>
                        {category.label}
                      </MenuItem>
                    ))} */}
                    {serviceCategories.map((category) => (
                      <MenuItem value={category.value} key={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="d-flex justify-content-start">
                  {this.state.serviceTypeError && (
                    <span className="small-font-size text-danger">
                      {this.state.serviceTypeError}
                    </span>
                  )}
                </div>

                <div className="py-3">
                  <ReactQuill
                    value={this.state.description}
                    onChange={this.handleChange}
                    modules={this.modules}
                    formats={this.formats}
                    placeholder="Description"
                    onFocus={() => this.setState({ descriptionError: "" })}
                    style={{
                      height: "180px",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-start pt-2">
                  {this.state.descriptionError && (
                    <span className="small-font-size text-danger pt-4">
                      {this.state.descriptionError}
                    </span>
                  )}
                </div>

                {/* {this.state.serviceImage ? (
                  <div className="col-md-10">{this.renderServiceImage()}</div>
                ) : (
                  <div className="float-left">
                    <input
                      type="file"
                      id="serviceimage"
                      className="d-none"
                      accept=".png, .jpg, .jpeg"
                      onChange={this.selectServiceImgFile}
                    />
                    <p className="small-font-size my-0">
                      {" "}
                      Image resolution should be 1170*658
                    </p>
                    <button
                      className="btn theme-bg-color text-white small-font-size font-style py-2 my-3"
                      onClick={this.handleServiceImgFileSelect}
                    >
                      <i className="fas fa-upload" />
                      <span className="mx-3">Upload Service Image</span>
                    </button>
                  </div>
                )} */}

                {this.state.thumbnailImage ? (
                  <div className="col-md-10 mt-5">
                    {this.renderThumbnailImage()}
                  </div>
                ) : (
                  <div
                    className={`float-left ${
                      this.state.descriptionError ? "mt-3" : "mt-5"
                    }`}
                  >
                    <input
                      type="file"
                      id="thumbnailimage"
                      className="d-none"
                      accept=".png, .jpg, .jpeg"
                      onChange={this.selectThumbnailFile}
                    />
                    <p className="small-font-size my-0">
                      {" "}
                      Image resolution should be 416*592
                    </p>
                    <button
                      className="btn theme-bg-color text-white small-font-size font-style py-2 mb-2 mt-1"
                      onClick={this.handleThumbnailImgFileSelect}
                    >
                      <i className="fas fa-upload" />
                      <span className="mx-3">Upload Thumbnail Image</span>
                    </button>
                    <div className="d-flex justify-content-start pb-3">
                      {this.state.thumbnailImageError && (
                        <span className="small-font-size text-danger">
                          {this.state.thumbnailImageError}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <button
                  type="submit"
                  className="theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-2  mt-5 font-style"
                >
                  {this.props.edit ? "UPDATE" : "CREATE"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  handleChange(value) {
    this.setState({ description: value, descriptionError: "" });
  }

  // Render service image

  // renderServiceImage = () => {
  //   return (
  //     <div className="d-flex my-3">
  //       <div className="col-md-10 border border-dark rounded bg-white">
  //         <div className="d-flex">
  //           <img
  //             src={this.state.serviceImage}
  //             alt="service"
  //             width="100%"
  //             height="200px"
  //           />
  //         </div>
  //       </div>
  //       <div className="col-md-2">
  //         <i
  //           className="fas fa-trash-alt theme-font-color big-font-size m-2 pointer"
  //           onClick={() => this.setState({ serviceImage: "" })}
  //         ></i>
  //       </div>
  //     </div>
  //   );
  // };

  // Render thumbnail image

  renderThumbnailImage = () => {
    return (
      <div className="d-flex">
        <div className="col-md-10 p-0 border border-dark rounded bg-white">
          <div className="d-flex">
            <img
              src={this.state.thumbnailImage}
              alt="service"
              width="100%"
              height="250px"
            />
          </div>
        </div>
        <div className="col-md-2">
          <i
            class="fas fa-trash-alt theme-font-color big-font-size m-2 pointer"
            onClick={() => this.setState({ thumbnailImage: "" })}
          ></i>
        </div>
      </div>
    );
  };

  // Handle file select

  // handleServiceImgFileSelect = (e) => {
  //   e.preventDefault();
  //   const fileSelector = document.getElementById("serviceimage");
  //   fileSelector.click();
  // };

  // Select file

  // selectServiceImgFile = (e) => {
  //   e.preventDefault();
  //   const serviceimage = e.target.files[0];
  //   const type = "serviceImage";
  //   uploadFile(serviceimage, this.callBackServiceImage, type);
  // };

  // callBackServiceImage = (response = false) => {
  //   if (response && response.status === "success") {
  //     this.setState({ serviceImage: response.result.serviceImage });
  //   }
  // };

  // Handle file select

  handleThumbnailImgFileSelect = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("thumbnailimage");
    fileSelector.click();
  };

  // Select file

  // selectThumbnailFile = (e) => {
  //   e.preventDefault();
  //   const thumbnailimage = e.target.files[0];
  //   const type = "thumbnailImage";
  //   uploadFile(thumbnailimage, this.callBackThumbnailImage, type);
  // };

  // callBackThumbnailImage = (response = false) => {
  //   if (response && response.status === "success") {
  //     this.setState({ thumbnailImage: response.result.serviceImage });
  //   }
  // };

  selectThumbnailFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && typeof event.target.result === "string") {
        this.setState({ thumbnailImage: event.target.result });
      }
    };
    reader.readAsDataURL(file);
  };

  // Validation for  title
  validateTitle = () => {
    if (this.state.title === "") {
      this.setState({ titleError: "Please enter title" });
      return false;
    } else return true;
  };

  // Validation for type
  validateServiceType = () => {
    if (this.state.serviceType === "") {
      this.setState({ serviceTypeError: "Please select type" });
      return false;
    } else return true;
  };

  // Validation for category
  // validateCategory = () => {
  //   if (this.state.category === "") {
  //     this.setState({ categoryError: "Please select category" });
  //     return false;
  //   } else return true;
  // };

  // Validation for description
  validateDescription = () => {
    if (this.state.description === "") {
      this.setState({ descriptionError: "Please enter description" });
      return false;
    } else return true;
  };

  // Validation for thumbnail image
  validateThumbnailImage = () => {
    if (this.state.thumbnailImage === "") {
      this.setState({
        thumbnailImageError: "Please upload an thumbnail image",
      });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const titleInput = this.validateTitle(this.state.title);
    const typeError = this.validateServiceType();
    // const categoryError = this.validateCategory();
    const descriptionError = this.validateDescription();
    const thumbnailImageError = this.validateThumbnailImage();
    if (titleInput && typeError && descriptionError && thumbnailImageError) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit create service form function
  onSubmitService = async (e) => {
    e.preventDefault();
    const id = this.props.edit;
    const allValidation = this.ValidateAll();
    if (allValidation) {
      this.setState({ loading: true });
      const category = serviceCategories.find(
        (item) => item.value === this.state.serviceType
      ).category;

      const requestData = {
        // createdBy: User.user_id,
        title: this.state.title,
        serviceType: this.state.serviceType,
        // category: this.state.category,
        category: category,
        description: this.state.description,
        // serviceImage: this.state.serviceImage,
        thumbnailImage: this.state.thumbnailImage,
      };
      if (this.props.edit) {
        requestData["updatedBy"] = User.user_id;
      } else {
        requestData["createdBy"] = User.user_id;
      }
      console.log("Request", requestData, category);
      if (this.props.edit) {
        const response = await updateService(requestData, id);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.serviceListApi();
          AppConfig.setMessage("Service updated successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      } else {
        const response = await createService(requestData);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.serviceListApi();
          AppConfig.setMessage("Service created successfully", false);
        } else if (response.status === "error") {
          this.props.closeModel(false);
          this.resetState();
          AppConfig.setMessage(response.result);
        }
      }
      this.setState({ loading: false });
    }
  };
}
export default observer(CreateService);
