// Common api call function
import AppConfig from '../modal/AppConfig';
import './Global';

export const callApi = (
	functionUrl = null,
	requestBody = {},
	method = 'POST',
	urlValue = null,
	headerVal = false,
	metaBody = false,
	queryParams = null,
) => {
	// setting Body
	const requestInfo = JSON.stringify(requestBody);
	// Setting the Header
	let headers = {
		'Accept': 'application/json',
		// 'Content-Type': 'application/json',
		// 'Access-Control-Allow-Origin': global.baseUrl
	};

	if(AppConfig.api_key) {
        headers['Authorization'] = 'Bearer ' + AppConfig.api_key;
    }

	let requestObj = {
		method,
		headers: headers,
		body: requestInfo
	};

	if (metaBody) {
		requestBody['meta'] = metaBody;
	}
	if (headerVal !== false) {
		for (let i in headerVal) {
			headers[i] = headerVal[i];
		}
	}

	if(method === 'GET') {
		requestObj = {};
	}

	let url = global.baseUrl + functionUrl;
	if (global.apiUrls[functionUrl]) {
		url = global.baseUrl + global.apiUrls[functionUrl];
	}
	if (urlValue) {
		url = functionUrl ? (url +"/"+urlValue) : (url + urlValue);
	}
	if (queryParams) {
        const params = [];
        Object.keys(queryParams).forEach((key) => {
            if (queryParams[key] !== undefined) params.push(`${key}=${String(queryParams[key])}`);
        });
        if (params.length > 0) url = `${url}?${params.join("&")}`;
    }

	AppConfig.setLoader(true);
	
	// All core API will be called from here
	return fetch(url, requestObj)
		.then(response => {
			const result = response.json();
			result.then(data => {
				AppConfig.setLoader(false)
				
				// We are checking common errors
				// if (data.status === false && data.result && data.result.error === 31) {
				// 	// Logout the user
				// 	console.log('Logout the user');
				// }
			});
			AppConfig.setLoader(false)

			return result;
		})
		.catch(async error => {
			AppConfig.setLoader(false)
			// if (error.message === 'Network request failed') {
				
			// }
			throw error;
		});
};


export const uploadApi = async (functionUrl, pictureData, callBack, type) => {
	const url = global.baseUrl + global.apiUrls[functionUrl];

	let formData = new FormData();
	formData.append('image', pictureData);
	formData.append('fileType', pictureData && pictureData.type ? pictureData.type : 'image/png');
	formData.append('documentType', type);

	// Starting Http Request
	const xhr = new XMLHttpRequest();

	xhr.open('POST', url);
	addListeners(xhr, callBack);
	// xhr.onprogress = function() {
	// 	console.log('LOADING', xhr.status);
	// };
	xhr.onload = function() {
		if (xhr.status === 200){
			const response = JSON.parse(xhr.response);
			// returning the response in callBack page 
			callBack(response);
        }
	};
	// Ending Http Request
	xhr.send(formData);
};

const addListeners = (xhr, callBack) => {
	xhr.addEventListener('loadstart', callBack);
	xhr.addEventListener('load', callBack);
	xhr.addEventListener('loadend', callBack);
	xhr.addEventListener('progress', callBack);
	xhr.addEventListener('error', callBack);
	xhr.addEventListener('abort', callBack);
	xhr.addEventListener('response', callBack);
};

export const MultipleImageUploadApi = async (functionUrl, pictureData, callBack, id, userId) => {
	const url = global.baseUrl + global.apiUrls[functionUrl] + '/' + id;

	let formData = new FormData();
	for (let i = 0; i < pictureData.length; i++) {
		formData.append("images[]", pictureData[i]);
	}
	formData.append('created_by', userId);
	// Starting Http Request
	const xhr = new XMLHttpRequest();

	xhr.open('POST', url);
	addListeners(xhr, callBack);
	xhr.onload = function() {
		console.log("xhr", xhr);

		if (xhr.status === 200){
			const response = JSON.parse(xhr.response);
			// returning the response in callBack page 
			callBack(response);
        }
	};
	// Ending Http Request
	xhr.send(formData);
};
