import React from "react";
import "../css/Common.css";
import Carousel from "react-bootstrap/Carousel";
import Spread from "../images/offers/spread.png";
import Earn from "../images/offers/earn.png";
import { callApi } from "../apis/Api";
import Refer from "../images/offers/refer-compressed.jpg";
import SendMailForm from "./SendMailForm";
import Header from "./Header";
import Footer from "../pages/Footer";

// General Focus Hook
const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    ref.current && ref.current.focus();
  };
  return { setFocus, ref };
};

class Offers extends React.Component {
  constructor() {
    super();
    this.referFriend = utilizeFocus();
  }
  state = {
    offers: [],
  };

  async componentDidMount() {
    // document.title = `Get 20% off on Modular Furnitures | Refer and Earn Offer | DezignCode`;
    // document.querySelector('meta[name="description"]').content =
    //   "Know more about our refer and earn offer up to 50000, how it works and get 20% off on all modular furnitures. Visit or Call us @6366939113";
    const {
      location: { hash },
    } = this.props;
    if (hash === "#refer-friend") {
      this.referFriend.setFocus();
    }

    let functionUrl = "offers";
    const response = await callApi(functionUrl, {}, "GET");
    const result = response.result;

    let offers = [];
    for (let i in result) {
      offers.push(result[i]);
    }
    this.setState({
      offers: offers,
    });
  }

  componentDidUpdate() {
    const {
      location: { hash },
    } = this.props;
    if (hash === "#refer-friend") {
      this.referFriend.setFocus();
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="py-5">{this.renderCarouselContainer()}</div>
        <div className="py-5">{this.renderReferAndEarn()}</div>
        <Footer props={this.props} />
      </>
    );
  }

  // Render carousel conatiner
  renderCarouselContainer() {
    return (
      <div className="position-relative">
        {this.renderCarouselButton()}
        <Carousel>
          {this.state.offers.map((offers, i) => (
            <Carousel.Item key={i}>
              <img
                className="d-block w-100"
                src={offers.image}
                alt="modular furniture"
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <div>
          <img
            src={Refer}
            alt="Join your hands with DezignCode to make your dream home beautiful"
            width="100%"
          />
        </div>
      </div>
    );
  }

  // Render refer and earn function
  renderReferAndEarn() {
    return (
      <div className="header-margin">
        <h1 className="theme-font-color text-center font-style2 font-weight-bold">
          REFER AND EARN!
        </h1>

        <p className="normal-font-size text-center">Earn upto</p>
        <div
          className="text-center"
          style={{ marginTop: "-15px", marginLeft: "1.5rem" }}
        >
          <span
            className="theme-font-color font-weight-bold font-style2"
            style={{ fontSize: "2rem" }}
          >
            {" "}
            &#8377; 50,000
          </span>
          {/* <span
            className="theme-font-color font-weight-bold font-style2"
            style={{ fontSize: "1rem", position: "relative", bottom: "10px" }}
          >
            *
          </span> */}
          <span
            className=" theme-font-color normal-font-size"
            style={{ fontSize: "0.6rem" }}
          >
            {" "}
            (*T&C apply){" "}
          </span>
        </div>
        {/* <p className="text-center big-font-size font-weight-bold">
          &#8377; 15,000
        </p> */}

        <div className="d-flex justify-content-center">
          <SendMailForm title="Refer-A-Friend" />
        </div>
        <div className="container-fluid my-5">
          <h2 className="theme-font-color text-center font-style2 font-weight-bold">
            HOW IT WORKS
          </h2>
          <div className="row d-flex justify-content-center">
            <div className="col-md-7">
              <div className="row">
                <div className="md-stepper-horizontal">
                  <div className="md-step">
                    <div className="md-step-circle">
                      <span>
                        <img src={Spread} alt="spread" width="60%" />
                      </span>
                    </div>
                    <div className="normal-font-size text-center font-weight-bold my-3">
                      {" "}
                      Spread the word
                    </div>
                    <div className="text-center normal-font-size">
                      Refer your friends & family by sharing the link or give us
                      their details and we'll do the rest.
                    </div>
                    <div className="md-step-bar-left"></div>
                    <div className="md-step-bar-right"></div>
                  </div>
                  <div className="md-step">
                    <div className="md-step-circle">
                      <span>
                        <img src={Earn} alt="earn" width="60%" />
                      </span>
                    </div>
                    <div className="normal-font-size text-center font-weight-bold my-3">
                      Earn
                    </div>
                    <div className="text-center normal-font-size">
                      You will get paid once your referee pays 50% of the
                      project value.
                    </div>
                    <div className="md-step-bar-left"></div>
                    <div className="md-step-bar-right"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-5 pb-3">
          <h2 className="theme-font-color text-center font-style2 font-weight-bold">
            T&C'S APPLY
          </h2>
          <p className="normal-font-size text-center">
            Valid if the minimum order value is &#8377; 3 lakhs
          </p>
          <div className="text-center">
            <span className="normal-font-size">
              When your friend makes 50% payment of their total order value,
              you'll earn upto
            </span>
            <span
              className="font-weight-bold font-style2"
              style={{ fontSize: "1rem" }}
            >
              {` ${"\u20B9"}50,000`}
            </span>
          </div>
        </div>
        <input
          type="text"
          className="hiddenInput mt-5 pt-5"
          ref={this.referFriend.ref}
        />
      </div>
    );
  }

  // Render carousel button
  renderCarouselButton() {
    return (
      <div className="d-flex position-absolute offer-button-container">
        {this.state.offers.map((offers) => (
          <div className="dropdown" key={offers.id}>
            <button className="font-style mx-2 btn offer-button small-font-size px-3 font-weight-bold">
              {offers.title ? offers.title : ""}
            </button>
            <div className="dropdown-modal-content py-5">
              <p className="small-font-size">{offers.description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Offers;
