import React from "react";
import '../../css/Common.css';
import Icomoon from '../../libraries/Icomoon';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import { CheckUserName, DropDownCheck} from "../../common/Validation";
import AppConfig from '../../modal/AppConfig';
import Modal from 'react-bootstrap/Modal';
import {createProject, getProject, updateProject} from '../../apis/Project';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { observer } from 'mobx-react';
import User from '../../modal/User';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomLoaderModal from "../../common/CustomLoaderModal";

class CreateProject extends React.Component{
    constructor (props) {
        super(props)
        this.state = { 
            loading: false,
            description:'', 
            createProject:false, 
            projectName:'', 
            date: null,
            youtubeURL:'', 
            thumbnailImage:'',
            projectImage:'',
            alt_tag: '',
            projectNameError: '', 
            youtubeURLError: '',
            dateError: '',
            descriptionError:''
        }
        this.handleChange = this.handleChange.bind(this);
      }

      resetState = () => {
        this.setState({
          id: '',
          description: '',
          createProject: false,
          projectName: '',
          date: null,
          youtubeURL: '',
          embeddedURL: '',
          thumbnailImage: '',
          projectImage: '',
          alt_tag: '',
          projectNameError: '', 
          youtubeURLError: '',
          dateError: '',
          descriptionError:''
        });
      };

    async componentDidUpdate (prevProps) {
        // const id = User.user_id
        if(this.props.edit && this.props.edit !== prevProps.edit) {
            const id = this.props.edit
            const response = await getProject(id);
            const result = response.result.projectDetail;
            if( response && response.status === 'success') { 
                const embeddedURL = this.isValidYouTubeUrl(result.youtubeURL) ? `https://www.youtube.com/embed/${this.getYouTubeVideoId(result.youtubeURL)}` : null ;
                this.setState({
                    id:id,
                    projectName:result.name, 
                    date: result.date ? moment(result.date).format('DD-MMM-YYYY') : null,
                    youtubeURL: result.youtubeURL,
                    description:result.description,
                    projectImage:result.projectimage,
                    thumbnailImage:result.thumbnailimage,
                    alt_tag: result.alt_tag,
                    embeddedURL: embeddedURL
                })
            }
        } 
    }

      modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      }
     
      formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]
    

    handleDateChange = (date) => {
        this.setState({date: date, dateError: ''});
      };
    
    render() {
        // const date = this.state;
        return(
            <div>
                {/* {this.props.edit ==='edit' ? 
                    <Icomoon  className="pointer mx-2"  icon="pen-solid" size={16}  color="#E40045" onClick={() => this.setState({createProject:true})}  />:
                    <button className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3 col-md-12" onClick={()=> this.setState({createProject:true})}>CREATE PROJECT</button>
                } */}
                <CustomLoaderModal loader={this.state.loading} />
                {this.renderCreateProjectModal()}
            </div>
        )
    }

    // Render create project modal function

    renderCreateProjectModal = () => {
        return(
            <Modal
                size='lg'
                className="border-style rounded"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.status}
            >    
                <div className="p-3">
                    <div className="d-flex justify-content-between mx-3">
                        <p className='big-font-size theme-font-color fw-bold my-0'>{this.props.edit ? "Edit Project" : "Create Project"}</p>
                        <Icomoon className='pointer theme-font-color' icon="close" size={20} onClick={() => {
                            this.props.closeModel(false)
                            this.resetState();
                        }} />
                    </div>
                    <Modal.Body>
                        <form onSubmit={this.onSubmitCreateProject} encType="multipart/form-data">
                            <div>
                                <TextField 
                                    className="col-md-12 my-2" 
                                    id="project-name" 
                                    label="Project Name"
                                    value={this.state.projectName} 
                                    onChange={(e)=> this.setState({projectName:e.target.value, projectNameError:''})}
                                    onFocus ={()=> this.setState({projectNameError:''})}
                                />
                                <div className="d-flex justify-content-start">
                                    {this.state.projectNameError ?  (<span className='small-font-size text-danger'> {this.state.projectNameError}</span>):''}
                                </div>
                                {/* <TextField 
                                    className="col-md-12 my-2" 
                                    id="date" 
                                    // label="Date"
                                    type="text"
                                    value={this.state.date} 
                                    // defaultValue="2021-05-09"
                                    // inputProps={{ min: "2021-12-12", max: "2021-12-12" }}
                                    onChange={(e)=> this.setState({date:e.target.value})}
                                /> */}
                                {/* <div className="d-flex justify-content-start">
                                    {this.state.dateError ?  (<span className='small-font-size text-danger'> {this.state.dateError}</span>):''}
                                </div> */}

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label="Select Date"
                                        format="dd-MMM-yyyy"
                                        className="mt-2"
                                        value={this.state.date} 
                                        onChange={this.handleDateChange} 
                                        fullWidth
                                        placeholder="dd-mm-yyyy"
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <i className="fas fa-solid fa-calendar"></i>
                                              </InputAdornment>
                                            ),
                                          }}
                                        onFocus ={()=> this.setState({dateError:''})}
                                    />
                                </MuiPickersUtilsProvider>

                                <div className="d-flex justify-content-start my-2">
                                    {this.state.dateError ?  (<span className='small-font-size text-danger'> {this.state.dateError}</span>):''}
                                </div>

                                <TextField 
                                    className="col-md-12 my-2" 
                                    id="youtube-link" 
                                    label="Youtube link" 
                                    value={this.state.youtubeURL} 
                                    onChange={(e)=> {
                                        if(this.isValidYouTubeUrl(e.target.value)) {
                                            const embeddedURL = `https://www.youtube.com/embed/${this.getYouTubeVideoId(e.target.value)}`;
                                            this.setState({youtubeURL:e.target.value, youtubeURLError: '', embeddedURL: embeddedURL});
                                        }else {
                                            this.setState({youtubeURL:e.target.value, youtubeURLError: "", embeddedURL: ''});
                                        }
                                    }}
                                    onFocus ={()=> this.setState({youtubeURLError:''})}
                                />

                                <div className="d-flex justify-content-start">
                                    {this.state.youtubeURLError ?  (<span className='small-font-size text-danger'> {this.state.youtubeURLError}</span>):''}
                                </div>
                                {
                                    this.state.youtubeURL && this.state.embeddedURL && !this.state.youtubeURLError && (
                                        <iframe className="w-100 rounded-2" height="315" src={this.state.embeddedURL} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    )
                                }

                                <div className='py-3'>
                                    <ReactQuill 
                                        value={this.state.description}
                                        onChange={this.handleChange}
                                        modules={this.modules}
                                        formats={this.formats}
                                        placeholder="Description"
                                        style={{
                                            height: "150px",
                                        }}  
                                    />
                                </div>
                                <div className="d-flex justify-content-start pt-2">
                                    {this.state.descriptionError && (
                                        <span className="small-font-size text-danger pt-4">
                                        {this.state.descriptionError}
                                        </span>
                                    )}
                                </div>
                                
                                { this.state.projectImage ?
                                    <div className="col-md-10 mt-5">
                                        {this.renderProjectImage()} 
                                    </div>
                                    :
                                    // <div className={`float-left ${this.state.descriptionError ? 'mt-3' : 'mt-5'}`}>
                                    //     <input  
                                    //         type="file" 
                                    //         id="projectimage" 
                                    //         className="d-none" 
                                    //         accept=".png, .jpg, .jpeg"
                                    //         onChange={this.selectProjectImgFile}  
                                    //     />
                                    //     <p className="small-font-size my-0"> Image resolution should be 1170*658</p>
                                    //     <button className='btn theme-bg-color text-white small-font-size font-style py-2 my-3' onClick={this.handleProjectImgFileSelect}><i className="fas fa-upload"/><span className='mx-3'>Upload Project Image</span></button>
                                    // </div>
                                    <div className={`${this.state.descriptionError ? 'mt-3' : 'mt-5'}`}>
                                        <input  
                                            type="file" 
                                            id="projectimage" 
                                            className="d-none" 
                                            accept=".png, .jpg, .jpeg"
                                            onChange={this.selectProjectImgFile}  
                                        />
                                        <p className="small-font-size my-0"> Image resolution should be 1170*658</p>
                                        <button className='btn theme-bg-color text-white small-font-size font-style py-2 my-3' onClick={this.handleProjectImgFileSelect}><i className="fas fa-upload"/><span className='mx-3'>Upload Project Image</span></button>
                                    </div>
                                }
                                { this.state.thumbnailImage ?
                                    <div className="col-md-10 mt-5">
                                        {this.renderThumbnailImage()} 
                                    </div>
                                    :
                                    // <div className={`float-right ${this.state.descriptionError ? 'mt-3' : 'mt-5'}`}>
                                    //     <input  
                                    //         type="file" 
                                    //         id="thumbnailimage" 
                                    //         className="d-none" 
                                    //         accept=".png, .jpg, .jpeg"
                                    //         onChange={this.selectThumbnailFile}  
                                    //     />
                                    //     <p className="small-font-size my-0"> Image resolution should be 416*592</p>
                                    //     <button className='btn theme-bg-color text-white small-font-size font-style py-2 my-3' onClick={this.handleThumbnailImgFileSelect}><i className="fas fa-upload"/><span className='mx-3'>Upload Thumbnail Image</span></button>
                                    // </div>
                                    <div className={`${this.state.descriptionError ? 'mt-3' : 'mt-2'}`}>
                                        <input  
                                            type="file" 
                                            id="thumbnailimage" 
                                            className="d-none" 
                                            accept=".png, .jpg, .jpeg"
                                            onChange={this.selectThumbnailFile}  
                                        />
                                        <p className="small-font-size my-0"> Image resolution should be 416*592</p>
                                        <button className='btn theme-bg-color text-white small-font-size font-style py-2 my-3' onClick={this.handleThumbnailImgFileSelect}><i className="fas fa-upload"/><span className='mx-3'>Upload Thumbnail Image</span></button>
                                    </div>
                                }
                                <button type='submit' className="theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-2 mt-5 font-style" >
                                  {this.props.edit ?  'UPDATE' : 'CREATE'  }
                                </button>
                            </div>
                        </form>          
                    </Modal.Body>
                </div>
            </Modal>
        )    
    }

    handleChange(value) {
        this.setState({ description: value, descriptionError:'' });
    }

    getYouTubeVideoId(url) {
        // Extract video ID from various YouTube URL formats
        const patterns = [
          /youtu\.be\/(\w+)/,
          /youtube\.com.*[?&]v=([^&]+)/,
          /youtube\.com.*embed\/([^&]+)/,
          /youtube\.com.*[?&]video_id=([^&]+)/,
        ];

        for (const pattern of patterns) {
          const match = url.match(pattern);
          if (match) {
            return match[1];
          }
        }
        return null; // Return null if no match is found
    }

    // Render project image 

    renderProjectImage = () => {
        return (
           <div className='d-flex my-3'>
                <div className="col-md-11 p-0 border border-dark rounded rounded bg-white">
                    <div className='d-flex'>
                       <img src={this.state.projectImage} alt='project' width="100%" height="250px"/>
                    </div>
                </div>
                <div className='col-md-1'>
                    <i className="fas fa-trash-alt theme-font-color big-font-size m-2 pointer" onClick={()=> this.setState({projectImage:''})}></i>
                </div>
            </div> 
        )
    }

    // Render thumbnail image 

    renderThumbnailImage = () => {
        return (
            <div className='d-flex'>
                <div className="col-md-11 p-0 border border-dark rounded rounded bg-white">
                    <div className="d-flex">
                        <img src={this.state.thumbnailImage} alt='project' width="100%" height="250px" />
                    </div>
                </div>
                <div className='col-md-1'>
                    <i className="fas fa-trash-alt theme-font-color big-font-size m-2 pointer" onClick={()=> this.setState({thumbnailImage:''})}></i>
                </div>
            </div>   
        )
    }

    // Handle file select 

    handleProjectImgFileSelect = (e) => {
        e.preventDefault();
        const fileSelector = document.getElementById("projectimage");
        fileSelector.click();
    };

    // Select file 

    // selectProjectImgFile = (e) => {
    //     e.preventDefault();
    //     const projectimage = e.target.files[0];
    //     const type = 'projectImage'
    //     uploadFile(projectimage, this.callBackProjectImage, type);
    // };

    // callBackProjectImage = (response = false) => {
    //     if (response && response.status === "success") {
    //        this.setState({projectImage:response.result.projectimage})
    //     }
       
    // }

    selectProjectImgFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target && typeof event.target.result === "string") {
                this.setState({ projectImage: event.target.result })
            }
        }
        reader.readAsDataURL(file);
    };

    // Handle file select 

    handleThumbnailImgFileSelect = (e) => {
        e.preventDefault();
        const fileSelector = document.getElementById("thumbnailimage");
        fileSelector.click();
    };

    // Select file 

    // selectThumbnailFile = (e) => {
    //     e.preventDefault();
    //     const thumbnailimage = e.target.files[0];
    //     const type = 'thumbnailImage'
    //     uploadFile(thumbnailimage, this.callBackThumbnailImage, type);
    // };

    // callBackThumbnailImage = (response = false) => {
    //     if (response && response.status === "success") {
    //        this.setState({thumbnailImage:response.result.projectimage})
    //     } 
    // }

    selectThumbnailFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target && typeof event.target.result === "string") {
                this.setState({ thumbnailImage: event.target.result })
            }
        }
        reader.readAsDataURL(file);
    };

    // Validation for  projectName
    validateName = () => {
        const projectNameError = CheckUserName(this.state.projectName);
        if (projectNameError === 1) {
            this.setState({projectNameError: 'Please enter project name' });
            return false;
        } else if (projectNameError === 2) {
            this.setState({projectNameError: 'Please enter valid project name' });
            return false;
        } else return true;
    };

    // Validation for date
    validateDate = () => {
        const dateError = DropDownCheck(this.state.date);
        if (dateError === 1 || this.state.date === null) {
             this.setState({dateError:'Please select date'});
            return false;
        } else return true;
    };

    isValidYouTubeUrl = (url) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
        return youtubeRegex.test(url);
    };

    validateYoutubeLink = () => {
        if (this.state.youtubeURL && !this.isValidYouTubeUrl(this.state.youtubeURL)) {
            this.setState({youtubeURLError: "Please enter valid youtube url" });
            return false;
        } else return true;
    }

    // Validation for description
    validateDescription = () => {
        if (this.state.description === "") {
        this.setState({ descriptionError: "Please enter description" });
        return false;
        } else return true;
    };
    
    // Empty input validation
    ValidateAll = () => {
        const nameInput = this.validateName();
        const dateInput = this.validateDate();
        const descriptionError = this.validateDescription();
        const urlInput = this.validateYoutubeLink();

        if ( nameInput &&  dateInput && urlInput && descriptionError ) {
            return true;
        } else {
            return false;   
        }
    }

     // onsubmit create project form function
     onSubmitCreateProject = async(e) => {
        e.preventDefault();
        const id =this.props.edit;
        const allValidation = this.ValidateAll()
        if (allValidation) {
            this.setState({ loading: true });
            const requestData = {
                user_id:User.user_id,
                name: this.state.projectName,
                date:moment(this.state.date).format('Do MMMM  YYYY'),
                youtubeURL: this.state.youtubeURL,
                projectimage:this.state.projectImage,
                thumbnailimage:this.state.thumbnailImage,
                description:this.state.description,
                alt_tag: this.state.alt_tag
            }
            if (this.props.edit){
                const response = await updateProject(requestData, id);
                if(response.status === 'success') {
                    this.props.closeModel(false);
                    this.resetState();
                    this.props.projectApi();
                    AppConfig.setMessage('Project updated successfully', false);
                }else if(response.status === 'error') {
                    this.props.closeModel(false);
                    this.resetState();
                    AppConfig.setMessage(response.result);
                }
            }else{
                const response = await createProject(requestData);
                if(response.status === 'success') {
                    this.props.closeModel(false);
                    this.resetState();
                    this.props.projectApi();
                    AppConfig.setMessage('Project created successfully', false);
                }else if(response.status === 'error') {
                    this.props.closeModel(false);
                    this.resetState();
                    AppConfig.setMessage(response.result);
                }
            }
        this.setState({ loading: false });
        }
    }

}
export default observer(CreateProject)
