
import { Link} from "react-router-dom";
import '../../css/Common.css';
import React from 'react';
import{ MenuItem} from "react-pro-sidebar";
import Icomoon from "../../libraries/Icomoon";
// import '../../css/menu.css';


export const  Item = props => {
  const {title, active, link, icon } = props;

  return (
        <MenuItem 
           className='bg-white'
            icon={
                <Icomoon
                    icon={icon}  
                    size={20}
                    style={
                        active 
                            ? {color:"#E40045"}
                            : {color:"#000000"}
                    }
                />
            }
        > 
            <Link
                className="text-decoration-none"
                to={link}
                style={
                    active 
                        ? {color:"#E40045"}
                        : {color:"#000000"}
                }
            > 
                <span 
                    style={
                        active 
                            ? {color:"#E40045"}
                            : {color:"#000000"}
                    }> 
                    {title}
                </span>
            </Link>
        </MenuItem>
    );

}

