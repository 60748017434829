// Manage faqs page

import React from "react";
import { Layout } from "../../common/Element";
import "../../css/DataTable.css";
import { observer } from "mobx-react";
import Icomoon from "../../libraries/Icomoon";
import Modal from "react-bootstrap/Modal";
import "../../css/Common.css";
import { deleteFAQ, faqsList } from "../../apis/FAQs";
import AppConfig from "../../modal/AppConfig";
import ConfirmModal from "../../common/ConfirmModal";
import { Box, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import { Table } from "react-bootstrap";
import CreateFAQs from "./CreateFAQs";
import { serviceCategories } from "../serviceData";

class ManageFAQs extends React.Component {
  state = {
    size: 10,
    current_page: 1,
    rows: [],
    totalData: 0,
    consultDataModal: false,
    order: 'asc',
    page: 0,
    orderBy: '',
  };

  async componentDidMount() {
    // Api call
    this.FAQsListApi();
  }

  FAQsListApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
    };
    const response = await faqsList(requestData);
    if (response && response.status === "success") {
      const {data, total } = response.result;

      const apiRows = data?.map((result, index) => ({
        id: result.id,
        slNumber: Number(index) + 1,
        question: result.question,
        answer: result.answer,
        category: result.category?.split('-')?.join(' '),
        faqorder: result.order,
        // type: result.type?.split('-')?.join(' '),
        type: serviceCategories.find((item) => item.value === result?.type)?.displayValue
      }))
      this.setState({ rows: apiRows, totalData: total });
    }
  };

  // Edit faqs modal
  editFAQs = (id) => {
    this.setState({ editFAQsId: id, status: true });
  };

  // Delete faqs confirm modal
  deleteFAQs = (id) => {
    this.setState({ viewFAQsConfirmModal: true, deleteId: id });
  };

  // Delete faqs api function
  getFAQSuccess = () => {
    const id = this.state.deleteId;
    deleteFAQ(id).then((response) => {
      if (response && response.status === "success") {
        this.setState({ viewFAQsConfirmModal: false });
        AppConfig.setMessage("Deleted Successfully", false);
        this.FAQsListApi();
      } else {
        this.setState({ viewFAQsConfirmModal: false });
        AppConfig.setMessage(response.result);
      }
    });
  };

  render() {
    return (
      <Layout>
        <div className="px-5 pb-5" style={{ marginTop: "4rem" }}>
          <div className="d-flex justify-content-between">
            <p className="theme-font-color align-self-center">
              Manage FAQs
            </p>
            <button
              className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
              onClick={() =>
                this.setState({ status: true, editFAQsId: "" })
              }
            >
              CREATE FAQs
            </button>
          </div>
          <div className="mt-2">
            {this.renderTable()}
          </div>
        </div>
        <ConfirmModal
          delete={true}
          visible={this.state.viewFAQsConfirmModal}
          heading="Delete FAQs"
          title="Are you sure you want to delete the faq?"
          confirm={() => this.getFAQSuccess()}
          handleClose={() =>
            this.setState({ viewFAQsConfirmModal: false })
          }
        />

        <CreateFAQs
          id={AppConfig.user_id}
          edit={this.state.editFAQsId}
          status={this.state.status}
          closeModel={() => this.setState({ status: false, editFAQsId: ''})}
          FAQsListApi={this.FAQsListApi}
        />
      </Layout>
    );
  }

  headCells = [
    {
      id: 'slNumber',
      label: '#',
      width: '5%',
      align: 'center'
    },
    {
      id: 'question',
      label: 'Question',
      width: '40%',
    },
    {
      id: 'category',
      label: 'Category',
      width: '20%',
    },
    {
      id: 'type',
      label: 'Type',
      width: '20%',
    },
    // {
    //   id: 'faqorder',
    //   label: 'FAQ Order',
    //   width: '5%',
    // },
    {
      id: 'action',
      label: 'Action',
      width: '15%',
      align: 'center'
    },
  ];

  handleRequestSort = (property) => {
    this.setState((prevState) => {
      const { orderBy, order, rows } = prevState;
      const isAsc = orderBy === property && order === 'asc';

      const sortedRows = [...rows].sort((a, b) => {
        if (property === 'slNumber') {
          return isAsc ? a.slNumber - b.slNumber : b.slNumber - a.slNumber;
        }
        if (property === 'question') {
          return isAsc ? a.question.localeCompare(b.question) : b.question.localeCompare(a.question);
        }
        if (property === 'category') {
          return isAsc ? a.category.localeCompare(b.category) : b.category.localeCompare(a.category);
        }
        if (property === 'type') {
          return isAsc ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type);
        }
        return 0;
      }); 

      return {
        orderBy: property,
        order: isAsc ? 'desc' : 'asc',
        rows: sortedRows,
      };
    });
  };

  renderTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <Box sx={{ width: '100%' }} >
          <Paper sx={{ width: '100%'}} style={{
             border: "1px solid #E40045",
             borderRadius: '5px',
             backgroundColor: '#f7f7f7'
          }}>
            <TableContainer >
              <Table
                sx={{ minWidth: 750 }}
              >
                <TableHead>
                  <TableRow style={{ borderColor: 'red'}}>
                    {this.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                        width={headCell.width}
                        align={headCell.align ? headCell.align : 'left'}
                      >
                        <TableSortLabel
                          active={this.state.orderBy === headCell.id}
                          direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                          onClick={() => this.handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.rows.map((row) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="left" className="text-truncate" style={{ maxWidth: "150px", padding: '20px 10px' }}> {row.slNumber} </TableCell>
                        <TableCell align="left" style={{ padding: '20px 10px' }}> {row.question || '-'} </TableCell>
                        <TableCell align="left" className="text-truncate text-capitalize" style={{maxWidth: "150px", padding: '20px 10px'}}>
                          {row.category || '-'}
                        </TableCell>
                        <TableCell align="left" className="text-truncate text-capitalize" style={{maxWidth: "150px", padding: '20px 10px', textTransform: 'capitalize'}}>{row.type || '-'}</TableCell>
                        {/* <TableCell align="left" className="text-truncate text-capitalize" style={{maxWidth: "150px", padding: '20px 10px', textTransform: 'capitalize'}}>{row.faqorder || '-'}</TableCell> */}
                        <TableCell align="center" style={{ padding: '20px 10px' }}>
                          <div className="d-flex justify-content-center">
                            <Icomoon
                              className="pointer mx-2"
                              icon="pen-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.editFAQs(row.id)}
                            />
                            <Icomoon
                              className="pointer mx-2"
                              icon="trash-solid"
                              size={16}
                              color="#E40045"
                              onClick={() => this.deleteFAQs(row.id)}
                            />
                            <i
                              className="fas fa-eye theme-font-color pointer"
                              onClick={() =>
                                this.setState({
                                  consultDataModal: true,
                                  question: row.question,
                                  answer: row.answer,
                                  type: row.type,
                                  category: row.category,
                                  faqorder: row.faqorder
                                })
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {this.state.rows.length === 0 && (
                <div className="d-flex justify-content-center align-items-center" style={{
                  height: '300px'
                }}>
                  <p>
                    No records found
                  </p>
                </div>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.size}
              page={this.state.page}
              onPageChange={(e, newPage) => 
                this.setState({ size: this.state.size, current_page: newPage + 1, page: newPage }, () =>
                this.FAQsListApi()
              )}
              onRowsPerPageChange={(e) =>
                this.setState({ size: e.target.value, current_page: 1 }, () =>
                  this.FAQsListApi()
                )
              }
            />
          </Paper>
        </Box>
        {this.renderConsultDataModal()}
      </div>
    );
  };

  renderConsultDataModal = () => {
    return (
      <Modal
        size="md"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.consultDataModal}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="medium-font-size theme-font-color">
              FAQ Details
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.setState({ consultDataModal: false });
              }}
            />
          </div>
          <Modal.Body>
            <ul className="py-0 mt-0 normal-font-size">
              <li className="d-flex gap-2">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  Category:
                </p>
                <p style={{textTransform: 'capitalize' }}>{this.state.category || '-'}</p>
              </li>
              <li className="d-flex gap-4">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  Type:
                </p>
                <p style={{textTransform: 'capitalize', paddingLeft: '10px'}}>{this.state.type || '-'}</p>
              </li>
              <li className="d-flex gap-2">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  Question:
                </p>
                <p className=""> {this.state.question || '-'}</p>
              </li>
              <li className="d-flex gap-3">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  Answer:
                </p>
                <div dangerouslySetInnerHTML={{
                  __html: this.state.answer
                    ? this.state.answer
                    : "",
                  }}
                  className="description-content"
                ></div>
              </li>
              <li className="d-flex gap-4">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                  }}
                >
                  Order:
                </p>
                <p className=""> {this.state.faqorder || '-'}</p>
              </li>
            </ul>
          </Modal.Body>
        </div>
      </Modal>
    );
  };
}
export default observer(ManageFAQs);
