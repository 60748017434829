import React from "react";
import "../css/Common.css";
import { callApi } from "../apis/Api";
import Header from "./Header";
import Footer from "../pages/Footer";

class ThemeHomes extends React.Component {
  state = {
    themeHomes: [],
  };

  async componentDidMount() {
    // document.title = `Best Home Interior Designers in Bangalore | DezignCode`;
    // document.querySelector('meta[name="description"]').content =
    //   "Looking for the best home interior designers in your budget? DezignCode is the one stop solution for all creative Residential, Commercial Interior designs at your budget. Vist or Call us Today @6366939113.";
    let functionUrl = "theme_homes";
    const response = await callApi(functionUrl, {}, "GET");
    const result = response.result;
    let themeHomes = [];
    for (let i in result) {
      themeHomes.push(result[i]);
    }
    this.setState({
      themeHomes: themeHomes,
    });
  }
  render() {
    return (
      <>
        <Header />
        <div>{this.renderThemeHomes()}</div>
        <Footer props={this.props} />
      </>
    );
  }

  // Render function for our projects
  renderThemeHomes() {
    return (
      <div>
        <div className="col-md-12 p-3 header-margin">
          {this.state.themeHomes.map((themeHomes, i) => (
            <div className="d-flex justify-content-center" key={i}>
              <div className="col-md-11 my-3">
                {i === 0 ? (
                  <h1 className="theme-font-color text-center my-3 font-style2 font-weight-bold">
                    {themeHomes.title}
                  </h1>
                ) : (
                  <h2 className="theme-font-color text-center my-3 font-style2 font-weight-bold">
                    {themeHomes.title}
                  </h2>
                )}

                <div className="row">
                  <div className="col-md-6 my-2">
                    <div
                      className="col-md-12 border-radius bg-white p-2"
                      style={{ borderRadius: 20 }}
                    >
                      <img
                        src={themeHomes.image1}
                        alt={themeHomes.alt_tag1}
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div
                      className="col-md-12 border-radius bg-white p-2 mb-2"
                      style={{ borderRadius: 20 }}
                    >
                      <img
                        className="pb-1"
                        src={themeHomes.image2}
                        alt={themeHomes.alt_tag2}
                        width="100%"
                      />
                    </div>
                    <div
                      className="col-md-12 border-radius bg-white p-2 mt-2"
                      style={{ borderRadius: 20 }}
                    >
                      <img
                        className="pt-1"
                        src={themeHomes.image3}
                        alt={themeHomes.alt_tag3}
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ThemeHomes;
