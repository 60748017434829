import {MultipleImageUploadApi, callApi} from './Api';
import './Global';

/**
 *
 * @param{email, password)data
 */
export const createGallery = async(requestData={}) => {
    const functionUrl = "serviceGallery";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};


/**
 *
 * @param{get single project) by id
 */
export const updateGallery = async(requestData, id) => {
    const functionUrl = "serviceGallery";
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};


/**
 *
 * @param{delete gallery) by id
 */
export const deleteGallery = async(id) => {
    const functionUrl = "serviceGallery";
    try {
        return await callApi(functionUrl, {}, 'DELETE', id )
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single project) by id
 */
export const getGallery = async(id) => {
    const functionUrl = "serviceGallery";
    try {
        return await callApi(functionUrl, {}, 'GET', id)
            .then((response) => {
                return response;
            })
            // .catch((error) => {
            //     console.log("Error from API => ", error);
            // });
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 * Common Api for upload images
 * @param(file, callback) 
 * @return API response
*/
export const MultipleFileUpload = async (file, callBack, id, userId) => {
	const functionUrl = 'serviceMultipleImageUpload';
	try {
		const response = await MultipleImageUploadApi(functionUrl, file, callBack, id, userId);
		if (response) {
			return callBack(response);
		}
	} catch (error) {
		// console.log('Error from catch => ', error);
	}
}

export const updateServiceGalleryImageApi = async (functionUrl, pictureData, callBack, id, projectId, userId) => {
	const url = global.baseUrl + global.apiUrls[functionUrl] + '/' + id;

	let formData = new FormData();
	formData.append('image', pictureData);
	formData.append('userId', userId);
	formData.append('serviceId', projectId);

	// Starting Http Request
	const xhr = new XMLHttpRequest();

	xhr.open('POST', url);
	addListeners(xhr, callBack);

	xhr.onload = function() {
		if (xhr.status === 200){
			const response = JSON.parse(xhr.response);
			// returning the response in callBack page 
			callBack(response);
        }
	};
	// Ending Http Request
	xhr.send(formData);
};

const addListeners = (xhr, callBack) => {
	xhr.addEventListener('loadstart', callBack);
	xhr.addEventListener('load', callBack);
	xhr.addEventListener('loadend', callBack);
	xhr.addEventListener('progress', callBack);
	xhr.addEventListener('error', callBack);
	xhr.addEventListener('abort', callBack);
	xhr.addEventListener('response', callBack);
};