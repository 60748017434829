// Manage project page

import React from "react";
import { Layout } from "../../common/Element";
// import { DataGrid } from "@mui/x-data-grid";
import { viewMail } from "../../apis/Admin";
import "../../css/DataTable.css";
import { observer } from "mobx-react";
import Icomoon from "../../libraries/Icomoon";
import Modal from "react-bootstrap/Modal";
import "../../css/Common.css";
import moment from "moment";
import { Box, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import { Table } from "react-bootstrap";

class ViewMailDetails extends React.Component {
  state = {
    size: 10,
    current_page: 1,
    rows: [],
    // columns: [],
    totalData: 0,
    page: 0,
    consultDataModal: false,
  };

  async componentDidMount() {
    // const columns = [
    //   { field: "id", headerName: "Sl.no", hide: false, minWidth: 100 }, // 150
    //   { field: "name", headerName: "Name", hide: false, minWidth: 150 }, // 150
    //   { field: "email", headerName: "Email", minWidth: 210 }, // 150
    //   { field: "mobileNumber", headerName: "Phone No.", minWidth: 160 }, // 170
    //   { field: "date", headerName: "Date", minWidth: 150 }, // 150
    //   { field: "title", headerName: "Type", minWidth: 150 }, // 150
    //   {
    //     field: "action",
    //     headerName: "Action",
    //     minWidth: 130, // 150

    //     renderCell: (param) => {
    //       return param?.row?.title === "Consult-Online-Now" ? (
    //         <div>
    //           <i
    //             className="fas fa-eye theme-font-color pointer"
    //             onClick={() =>
    //               this.setState({
    //                 consultDataModal: true,
    //                 name: param.row.name,
    //                 email: param.row.email,
    //                 mobile: param.row.mobileNumber,
    //                 propertyDetails: param.row.propertyDetails,
    //                 propertyType: param.row.propertyType,
    //                 appointmentType: param.row.appointmentType,
    //               })
    //             }
    //           />
    //         </div>
    //       ) : null;
    //     },
    //   },
    // ];

    // Api call

    this.mailListApi();
    // this.setState({ columns: columns });
  }

  mailListApi = async () => {
    const requestData = {
      size: this.state.size,
      page: this.state.current_page,
    };
    const response = await viewMail(requestData);
    if (response && response.status === "success") {
      // const { rows } = this.state;
      const result = response.result;
      let apiRows = [];
      // if (rows.length > 0) {
      //   for (let i in rows) {
      //     apiRows.push(rows[i]);
      //   }
      // }
      for (let i in result.data) {
        const row = {
          id: result.data[i].id,
          slNumber: Number(i) + 1,
          name: result.data[i].name,
          email: result.data[i].email,
          date: result.data[i].date ? moment(result.data[i].date).format("DD-MMM-YYYY") : '-',
          //   date: moment(result.data[i].date).format("Do MMMM  YYYY"),
          title: result.data[i].title,
          mobileNumber: result.data[i].mobileNumber,
          propertyDetails: result.data[i].propertyDetails,
          propertyType: result.data[i].propertyType,
          appointmentType: result.data[i].appointmentType,
        };
        apiRows.push(row);
      }
      this.setState({ rows: apiRows, totalData: result.total });
    }
  };

  render() {
    return (
      <Layout>
        <div className="px-5" style={{ marginTop: "4rem" }}>
          <p className="theme-font-color">Mail Details</p>
          {this.renderTable()}
        </div>
      </Layout>
    );
  }

  // renderTable = () => {
  //   return (
  //     <div style={{ height: 620, width: "100%" }}>
  //       <DataGrid
  //         rows={this.state.rows}
  //         columns={this.state.columns}
  //         pageSize={this.state.size}
  //         onPageSizeChange={(size) =>
  //           this.setState({ size: size, current_page: 1 }, () =>
  //             this.mailListApi()
  //           )
  //         }
  //         rowsPerPageOptions={[10, 20, 50]}
  //         onPageChange={(current_page) =>
  //           this.setState({ current_page: parseInt(current_page) + 1 }, () =>
  //             this.mailListApi()
  //           )
  //         }
  //         rowCount={this.state.totalData}
  //         pagination
  //       />
  //       {this.renderConsultDataModal()}
  //     </div>
  //   );
  // };

  headCells = [
    {
      id: 'slNumber',
      label: '#',
      width: '5%',
      align: 'center'
    },
    {
      id: 'name',
      label: 'Name',
      width: '20%',
    },
    {
      id: 'email',
      label: 'Email',
      width: '20%',
    },
    {
      id: 'mobileNumber',
      label: 'Phone No.',
      width: '20%',
    },
    {
      id: 'date',
      label: 'Date',
      width: '20%',
    },
    {
      id: 'title',
      label: 'type',
      width: '20%',
    },
    {
      id: 'action',
      label: 'Action',
      width: '20%',
      align: 'center'
    },
  ];

  handleRequestSort = (property) => {
    this.setState((prevState) => {
      const { orderBy, order, rows } = prevState;
      const isAsc = orderBy === property && order === 'asc';

      const sortedRows = [...rows].sort((a, b) => {
        if (property === 'slNumber') {
          return isAsc ? a.slNumber - b.slNumber : b.slNumber - a.slNumber;
        }
        if (property === 'name') {
          return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }
        if (property === 'email') {
          return isAsc ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
        }
        if (property === 'mobileNumber') {
          return isAsc ? a.mobileNumber.localeCompare(b.mobileNumber) : b.mobileNumber.localeCompare(a.mobileNumber);
        }
        if (property === 'date') {
          return isAsc ? a.date.localeCompare(b.date) : b.date.localeCompare(a.date);
        }
        if (property === 'title') {
          return isAsc ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        }
        return 0;
      }); 

      return {
        orderBy: property,
        order: isAsc ? 'desc' : 'asc',
        rows: sortedRows,
      };
    });
  };

  renderTable = () => {
    return (
      <div style={{ width: "100%", paddingBottom: "30px" }}>
        <Box sx={{ width: '100%' }} >
          <Paper sx={{ width: '100%'}} style={{
            border: "1px solid #E40045",
            borderRadius: '5px',
            backgroundColor: '#f7f7f7',
          }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
              >
                <TableHead>
                  <TableRow style={{ borderColor: 'red'}}>
                    {this.headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                        width={headCell.width}
                        align={headCell.align ? headCell.align : 'left'}
                      >
                        <TableSortLabel
                          active={this.state.orderBy === headCell.id}
                          direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                          onClick={() => this.handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.rows.map((row) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell align="left" style={{ padding: '15px 10px' }}> {row.slNumber} </TableCell>
                        <TableCell align="left" style={{ padding: '15px 5px' }}> {row.name || '-'} </TableCell>
                        <TableCell align="left" className="text-truncate" style={{maxWidth: "150px", padding: '15px 5px'}} >
                          {row.email || '-'}
                        </TableCell>
                        <TableCell align="left" className="text-truncate" style={{maxWidth: "150px", padding: '15px 5px'}} >
                          {row.mobileNumber || '-'}
                        </TableCell>
                        <TableCell align="left" className="text-truncate" style={{maxWidth: "150px", padding: '15px 5px'}} >
                          {row.date || '-'}
                        </TableCell>
                        <TableCell align="left" className="text-truncate" style={{maxWidth: "150px", padding: '15px 5px'}} >
                          {row.title || '-'}
                        </TableCell>
                        <TableCell align="center" style={{ padding: '15px 5px' }}>
                        <div>
                          <i
                            className="fas fa-eye theme-font-color pointer"
                            onClick={() =>
                              this.setState({
                                consultDataModal: true,
                                name: row.name,
                                email: row.email,
                                mobile: row.mobileNumber,
                                propertyDetails: row.propertyDetails,
                                propertyType: row.propertyType,
                                appointmentType: row.appointmentType,
                              })
                            }
                          />
                        </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {this.state.rows.length === 0 && (
                <div className="d-flex justify-content-center align-items-center" style={{
                  height: '300px'
                }}>
                  <p>
                    No records found
                  </p>
                </div>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.size}
              page={this.state.page}
              onPageChange={(e, newPage) => 
                this.setState({ size: this.state.size, current_page: newPage + 1, page: newPage }, () =>
                this.mailListApi()
              )}
              onRowsPerPageChange={(e) =>
                this.setState({ size: e.target.value, current_page: 1 }, () =>
                  this.mailListApi()
                )
              }
            />
          </Paper>
        </Box>
        {this.renderConsultDataModal()}
      </div>
    );
  };

  renderConsultDataModal = () => {
    return (
      <Modal
        size="md"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.consultDataModal}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="medium-font-size theme-font-color">Contact Details</p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.setState({ consultDataModal: false });
              }}
            />
          </div>
          <Modal.Body>
            <ul className="py-0 mt-0 normal-font-size">
              <li>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                    display: "inline-block",
                    width: "130px",
                  }}
                >
                  {" "}
                  Name:
                </span>{" "}
                {this.state.name || '-'}
              </li>
              <li>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                    display: "inline-block",
                    width: "130px",
                  }}
                >
                  {" "}
                  Email:
                </span>{" "}
                {this.state.email || '-'}
              </li>
              <li>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                    display: "inline-block",
                    width: "130px",
                  }}
                >
                  {" "}
                  Mobile:
                </span>{" "}
                {this.state.mobile || '-'}
              </li>
              <li>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                    display: "inline-block",
                    width: "130px",
                  }}
                >
                  {" "}
                  Property Type:
                </span>{" "}
                {this.state.propertyDetails || '-'}
              </li>
              <li>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                    display: "inline-block",
                    width: "130px",
                  }}
                >
                  {" "}
                  Property Details:
                </span>{" "}
                {this.state.propertyType || '-'}
              </li>
              <li>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "900",
                    display: "inline-block",
                    width: "130px",
                  }}
                >
                  {" "}
                  Appointment Type:
                </span>{" "}
                {this.state.appointmentType || '-'}
              </li>
            </ul>
          </Modal.Body>
        </div>
      </Modal>
    );
  };
}
export default observer(ViewMailDetails);
