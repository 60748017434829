// Manage Content page

import React from "react";
import { Layout } from "../../common/Element";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { TextField } from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { observer } from "mobx-react";
import { getContents, updateContents } from "../../apis/Admin";
import { getContentTitle } from "../../libraries/getContentTitle";
import "../../css/ManageContent.css";
import { checkHtmlTags } from "../../libraries/checkHtmlTags";
import AppConfig from "../../modal/AppConfig";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class ManageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      description: "",
      selectOption: "",
      title: "",
      selectedFile: null,
      dropdownValues: [],
      selectedDropdown: {},
      titleRef: "",
      descriptionRef: "",
      isTitleMaxLength: false,
      isDescriptionMaxLength: false,
      maxLengthError: "Maximum character limit exceeded",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleContentUpdate = this.handleContentUpdate.bind(this);
    this.getContentsData = this.getContentsData.bind(this);
    this.getMaxLength = this.getMaxLength.bind(this);
  }
  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  async componentDidMount() {
    await this.getContentsData();
  }

  render() {
    return (
      <Layout>
        <CustomLoaderModal loader={this.state.loading} />
        <div style={{ marginTop: "5rem" }}>
          <div className="col-md-6 mt-5" style={{ marginTop: "5rem" }}>
            <h3 className="theme-font-color">Select Content</h3>
            {this.renderSelect()}
            {/* {this.state.selectOption ? this.renderForm() : null} */}
            {this.state.selectOption ? this.renderEditor() : null}
          </div>
        </div>
      </Layout>
    );
  }

  // Render Select
  renderSelect = () => {
    return (
      <div className="col-md-12 rounded bg-white shadow p-3">
        <FormControl className="col-md-12 my-2">
          <InputLabel id="property-type">Select</InputLabel>
          <Select
            labelId="property-type"
            id="property-type"
            value={this.state.selectOption}
            onChange={(e) => this.handleDropdownSelect(e.target.value)}
          >
            {this.state.dropdownValues &&
              this.state.dropdownValues.length > 0 &&
              this.state.dropdownValues.map((data, index) => (
                <MenuItem value={data.contentTitle} key={index}>
                  {data.contentTitle}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  renderForm = () => {
    return (
      <div className="col-md-12 rounded bg-white shadow p-3 my-3">
        <div className="py-3">
          <TextField
            type="text"
            className="form-control"
            id="title"
            label="Title"
            value={this.state.title}
            onChange={(e) =>
              this.setState({ title: e.target.value, titleError: "" })
            }
            onFocus={() => this.setState({ titleError: "" })}
          />
        </div>
        <div className="py-3">
          <p className="normal-font-size">Description</p>
          <ReactQuill
            value={this.state.description}
            onChange={this.handleChange}
            modules={this.modules}
            formats={this.formats}
            placeholder="Description"
          />
        </div>
        <div>
          <div className="py-3">
            <input type="file" />
          </div>
        </div>
      </div>
    );
  };

  renderEditor = () => {
    return (
      <div className="col-md-12 rounded bg-white shadow p-3 my-3">
        <div className="py-2">
          <div className="d-flex flex-row justify-content-between">
            <p className="font-weight-bold">
              {"title" in this.state.selectedDropdown ? "Title :" : "Name :"}
            </p>
            <p className="font-weight-bold">
              {`No of Characters : ${this.state.selectedDropdown.maxTitleLength}`}
            </p>
          </div>
          <p
            id="content-title"
            onInput={(e) => this.handleContentChange(e, "Title")}
            contentEditable
            dangerouslySetInnerHTML={{
              __html:
                "title" in this.state.selectedDropdown
                  ? this.state.selectedDropdown.title
                  : this.state.selectedDropdown.name,
            }}
          ></p>
          {this.state.isTitleMaxLength && (
            <p className="theme-font-color">{this.state.maxLengthError}</p>
          )}
        </div>
        {"description" in this.state.selectedDropdown && (
          <div className="py-2">
            <div className="d-flex flex-row justify-content-between">
              <p className="font-weight-bold">Description :</p>
              <p className="font-weight-bold">
                {`Max characters : ${this.state.selectedDropdown.maxDescriptionLength}`}
              </p>
            </div>
            <p
              id="content-description"
              onInput={(e) => this.handleContentChange(e, "Description")}
              contentEditable
              dangerouslySetInnerHTML={{
                __html: this.state.selectedDropdown.description,
              }}
            ></p>
            {this.state.isDescriptionMaxLength && (
              <p className="theme-font-color">{this.state.maxLengthError}</p>
            )}
          </div>
        )}
        <button
          className="btn theme-bg-color text-white normal-font-size font-style py-2 my-3"
          onClick={this.handleContentUpdate}
          disabled={
            (!this.state.titleRef && !this.state.descriptionRef) ||
            this.state.isTitleMaxLength ||
            this.state.isDescriptionMaxLength
          }
        >
          Update
        </button>
      </div>
    );
  };

  handleChange(value) {
    this.setState({ description: value });
  }

  handleDropdownSelect(text) {
    for (const data of this.state.dropdownValues) {
      if (data.contentTitle === text) {
        this.setState({
          selectOption: text,
          selectedDropdown: { ...data },
          titleRef: "",
          descriptionRef: "",
        });
      }
    }
  }

  handleContentChange(e, identifier) {
    e.preventDefault();

    if (identifier === "Title") {
      this.setState(
        {
          titleRef: e.target.innerHTML,
        },
        () => {
          if (
            this.state.titleRef.length >
            this.state.selectedDropdown.maxTitleLength
          ) {
            this.setState({ isTitleMaxLength: true });
          } else {
            this.setState({ isTitleMaxLength: false });
          }
        }
      );
    }
    if (identifier === "Description") {
      this.setState(
        {
          descriptionRef: e.target.innerHTML,
        },
        () => {
          if (
            this.state.descriptionRef.length >
            this.state.selectedDropdown.maxDescriptionLength
          ) {
            this.setState({ isDescriptionMaxLength: true });
          } else {
            this.setState({ isDescriptionMaxLength: false });
          }
        }
      );
    }
  }

  handleContentUpdate() {
    let stateObj = { ...this.state.selectedDropdown };
    if (this.state.titleRef) {
      if ("title" in stateObj) {
        stateObj.title = this.state.titleRef;
      } else {
        stateObj.name = this.state.titleRef;
      }
    }
    if (this.state.descriptionRef) {
      if ("description" in stateObj) {
        stateObj.description = this.state.descriptionRef;
      }
    }
    this.setState({ selectedDropdown: { ...stateObj }, loading: true }, async () => {
      const requestData = {
        ...this.state.selectedDropdown,
      };
      const response = await updateContents(requestData);
      if (response && response.status === "success") {
        AppConfig.setMessage(response.result, false);
        await this.getContentsData();
      } else {
        AppConfig.setMessage(response.result);
      }
      this.setState({ loading: false });
    });
  }

  getMaxLength(key) {
    if (key === "banners") return [56, 0];
    if (key === "aboutus") return [40, 830];
    if (key === "worksteps") return [40, 300];
    if (key === "testimonials") return [25, 300];
    if (key === "services") return [30, 1000];
  }

  async getContentsData() {
    const response = await getContents();
    let apiArr = [];
    if (response && response.status === "success") {
      for (const apikey in response.result) {
        for (const dataKey in response.result[apikey]) {
          for (const [arrKey, arrValue] of response.result[apikey][
            dataKey
          ].entries()) {
            const title = getContentTitle(dataKey);
            const [titleLimit, descriptionLimit] = this.getMaxLength(dataKey);
            let obj = {
              contentTitle: `${apikey} - ${title} ${
                "title" in arrValue ? arrValue.title : arrValue.name
              }`,
              // contentTitle: apikey + title + arrValue.title,
              index: arrKey,
              maxTitleLength: titleLimit,
              maxDescriptionLength: descriptionLimit,
              isPTag: checkHtmlTags("<p>", arrValue?.description),
              ...arrValue,
            };
            apiArr.push({ ...obj });
            obj = {};
          }
        }
      }
    }

    this.setState({
      dropdownValues: [...apiArr],
      selectOption: "",
      selectedDropdown: {},
      titleRef: "",
      descriptionRef: "",
    });
  }
}
export default observer(ManageContent);
