import React from "react";
import Header from "./Header";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";

class ThankYouPage extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div>
            <p className="text-center" style={{ fontSize: 45 }}>
              Thank you
            </p>
            <p className="text-center" style={{ fontSize: 25 }}>
              For sharing your details our team will connect you in 24hrs.
            </p>
            <div className="d-flex justify-content-center">
              <button className="theme-bg-color border-0 text-white p-2 px-4 font-style">
                <Link to="/" className="text-decoration-none text-white">
                  <i className="fas fa-arrow-left mr-2" color="#f222ff" />
                  Home
                </Link>
              </button>
            </div>
          </div>
        </div>
        <Footer props={this.props} />
      </>
    );
  }
}

export default ThankYouPage;
