import React from "react";
import { observer } from "mobx-react";
import Toast from "react-bootstrap/Toast";
import AppConfig from "../modal/AppConfig";

class ToastMessage extends React.Component {
  render() {
    const status = AppConfig.status;
    const success = AppConfig.success;
    const error = AppConfig.error;
    let message = "Error!";
    let classMessage = "text-danger";
    if (success) {
      message = "Success !";
      classMessage = "text-success";
    }
    return (
      <>
        {success !== "" || error !== "" ? (
          <div
            className="border-radius shadow bg-white position-fixed"
            style={{ right: "2%", top: "15%", zIndex: "9999" }}
          >
            <Toast
              onClose={this.closeMessage}
              show={status}
              delay={3000}
              autohide
            >
              <Toast.Body className="normal-font-size">
                <p className={`small-font-size fw-bold ${classMessage}`}>
                  {message}
                </p>
                <p className="small-font-size fw-bold">
                  {success ? success : error}
                </p>
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </>
    );
  }

  //close message
  closeMessage = () => {
    AppConfig.setMessage("", true);
    AppConfig.setMessage("", false);
  };
}

export default observer(ToastMessage);
