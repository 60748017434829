import React from "react";
import "../../css/Common.css";
import Icomoon from "../../libraries/Icomoon";
import { TextField } from "@material-ui/core";
import AppConfig from "../../modal/AppConfig";
import Modal from "react-bootstrap/Modal";
import "react-quill/dist/quill.snow.css";
import { observer } from "mobx-react";
import User from "../../modal/User";
import { getSeoById, updateSeoById } from "../../apis/Seo";
import CustomLoaderModal from "../../common/CustomLoaderModal";

class CreateOrEditSEO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      id: "",
      page: "",
      title: "",
      description: "",
      pageUrl: "",
      openSeoModal: false,
      titleError: "",
      descriptionError: "",
    };
  }

  resetState = () => {
    this.setState({
      id: "",
      page: "",
      title: "",
      description: "",
      pageUrl: "",
      openSeoModal: false,
      titleError: "",
      descriptionError: "",
    });
  };

  async componentDidUpdate(prevProps) {
    if (this.props.edit && this.props.edit !== prevProps.edit) {
      const id = this.props.edit;
      const response = await getSeoById(id);
      const result = response.result;
      if (response && response.status === "success") {
        this.setState({
          id: id,
          page: result.page,
          title: result.metaTitle,
          pageUrl: result.pageUrl,
          description: result.metaDescription,
        });
      }
    }
  }

  render() {
    return (
      <div>
        <CustomLoaderModal loader={this.state.loading} />
        {this.renderCreateOrEditModal()}
      </div>
    );
  }

  // Render create or edit modal function
  renderCreateOrEditModal = () => {
    return (
      <Modal
        size="lg"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.status}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mx-3">
            <p className="big-font-size theme-font-color fw-bold my-0">
              {this.props.edit ? "Edit SEO" : "Create SEO"}
            </p>
            <Icomoon
              className="pointer theme-font-color"
              icon="close"
              size={20}
              onClick={() => {
                this.props.closeModel(false);
                this.resetState();
              }}
            />
          </div>
          <Modal.Body>
            <form onSubmit={this.onSubmitService} encType="multipart/form-data">
              <div>
                <TextField
                  className="col-md-12 my-2 capitalize"
                  id="page"
                  label="Page"
                  disabled
                  value={this.state.page}
                  onChange={(e) => this.setState({ page: e.target.value })}
                />

                <TextField
                  className="col-md-12 my-2"
                  id="title"
                  label="Title"
                  multiline
                  rows={2}
                  value={this.state.title}
                  onChange={(e) =>
                    this.setState({ title: e.target.value, titleError: "" })
                  }
                  onFocus={() => this.setState({ titleError: "" })}
                />
                <div className="d-flex justify-content-start">
                  {this.state.titleError && (
                    <span className="small-font-size text-danger">
                      {this.state.titleError}
                    </span>
                  )}
                </div>

                <TextField
                  className="col-md-12 my-2"
                  id="description"
                  label="Description"
                  multiline
                  rows={4}
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({
                      description: e.target.value,
                      descriptionError: "",
                    })
                  }
                  onFocus={() => this.setState({ descriptionError: "" })}
                />

                <div className="d-flex justify-content-start">
                  {this.state.descriptionError && (
                    <span className="small-font-size text-danger">
                      {this.state.descriptionError}
                    </span>
                  )}
                </div>

                <button
                  type="submit"
                  className="theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-1 rounded-3  mt-5 font-style"
                >
                  {this.props.edit ? "UPDATE" : "CREATE"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  };

  // Validation for  title
  validateTitle = () => {
    if (this.state.title === "") {
      this.setState({ titleError: "Please enter title" });
      return false;
    } else return true;
  };

  // Validation for type
  validateServiceType = () => {
    if (this.state.serviceType === "") {
      this.setState({ serviceTypeError: "Please select type" });
      return false;
    } else return true;
  };

  // Validation for description
  validateDescription = () => {
    if (this.state.description === "") {
      this.setState({ descriptionError: "Please enter description" });
      return false;
    } else return true;
  };

  // Empty input validation
  ValidateAll = () => {
    const titleInput = this.validateTitle(this.state.title);
    const typeError = this.validateServiceType();
    const descriptionError = this.validateDescription();
    if (titleInput && typeError && descriptionError) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit create service form function
  onSubmitService = async (e) => {
    e.preventDefault();
    const id = this.props.edit;
    const allValidation = this.ValidateAll();
    if (allValidation) {
      this.setState({ loading: true });
      const requestData = {
        page: this.state.page,
        metaTitle: this.state.title,
        metaDescription: this.state.description,
        pageUrl: this.state.pageUrl,
        updatedBy: User.user_id,
      };

      console.log("Request", requestData);
      if (this.props.edit) {
        const response = await updateSeoById(requestData, id);
        if (response.status === "success") {
          this.props.closeModel(false);
          this.resetState();
          this.props.SeoListApi();
          AppConfig.setMessage("Seo updated successfully", false);
        } else if (response.status === "error") {
          AppConfig.setMessage(response.result);
        }
      }
      this.setState({ loading: false });
    }
  };
}
export default observer(CreateOrEditSEO);
