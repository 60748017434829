import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/Common.css";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurProjects from "./pages/OurProjects";
import Services from "./pages/Services";
// import Blog from './pages/Blog';
import ThemeHomes from "./pages/ThemeHomes";
import Offers from "./pages/Offers";
import Policies from "./pages/Policies";
import ViewProject from "./pages/ViewProject";

//Import admin component
import Login from "./pages/admin/Login";
import ForgotPassword from "./pages/admin/ForgotPassword";
import ChangePassword from "./pages/admin/ChangePassword";
import ManageContent from "./pages/admin/ManageContent";
import ManageProject from "./pages/admin/ManageProject";
import ViewMailDetails from "./pages/admin/ViewMailDetails";
import TestimonialContent from "./pages/admin/TestimonialContent";

import ToastMessage from "./common/ToastMessage";
import ScrollToTop from "./pages/ScrollToTop";
import Loader from "./common/Loader";

import { Redirect, Route, Switch } from "react-router-dom";
// import AppConfig from "./modal/AppConfig";
import { observer } from "mobx-react";
import { PublicRoute, PrivateRoute } from "./common/Element";
// import Page404 from "./pages/404page";
import ManageBanner from "./pages/admin/ManageBanner";

import "./dummy-changes";
import ManageServices from "./pages/admin/ManageServices";
import ServiceCategoryPage from "./pages/ServiceCategoryPage";
import ManageFAQs from "./pages/admin/ManageFAQs";
import ManageClients from "./pages/admin/ManageClients";
import ThankYouPage from "./pages/ThankYouPage";
import ManageSEO from "./pages/admin/ManageSEO";
import MetaDataDescription from "./common/MetaDataDescription";

const App = observer(() => {
  useEffect(() => {
    // Push 'pageview' event to dataLayer
    window.dataLayer.push({
      event: 'pageview'
    });
  }, []);

  return (
    <>
      <Loader />
      <MetaDataDescription />
      <ToastMessage />
      <ScrollToTop />
      <Switch>
        <PublicRoute exact path="/" component={Home} />
        <PublicRoute exact path="/home" component={Home} />
        <PublicRoute exact path="/about-us" component={AboutUs} />
        <PublicRoute exact path="/our-projects" component={OurProjects} />
        <PublicRoute exact path="/services/:category" component={Services} />
        <PublicRoute
          path="/services/:category/:type"
          component={ServiceCategoryPage}
        />
        <PublicRoute exact path="/theme-homes" component={ThemeHomes} />
        <PublicRoute
          exact
          path="/blog"
          component={() => {
            window.location.href = "https://thedezigncode.com/blog/";
            return null;
          }}
        />
        <PublicRoute exact path="/offers" component={Offers} />
        <PublicRoute exact path="/policies" component={Policies} />
        <PublicRoute
          exact
          path="/projects/:projectName"
          component={ViewProject}
        />
        <PublicRoute exact path="/thank-you" component={ThankYouPage} />
        {/** Admin link */}
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePassword}
        />
        <PrivateRoute exact path="/manage-banner" component={ManageBanner} />
        <PrivateRoute exact path="/manage-content" component={ManageContent} />
        <PrivateRoute exact path="/manage-project" component={ManageProject} />
        <PrivateRoute exact path="/manage-service" component={ManageServices} />
        <PrivateRoute exact path="/manage-clients" component={ManageClients} />
        <PrivateRoute exact path="/manage-faqs" component={ManageFAQs} />
        <PrivateRoute
          exact
          path="/view-mail-details"
          component={ViewMailDetails}
        />
        <PrivateRoute
          exact
          path="/testimonial-content"
          component={TestimonialContent}
        />
        <PrivateRoute exact path="/manage-seo" component={ManageSEO} />
        {/* <PublicRoute path="*" component={() => "404 Not Found"} /> */}
        {/* <PublicRoute path="*" component={Page404} /> */}
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
});
export default App;
