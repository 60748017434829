import React from "react";
import "../css/Common.css";
import { TextField } from "@material-ui/core";
import { sendMail } from "../apis/Form";
import { CheckUserName, CheckEmail, CheckPhone } from "../common/Validation";
import AppConfig from "../modal/AppConfig";
import Modal from "react-bootstrap/Modal";
import Icomoon from "../libraries/Icomoon";
import { withRouter } from 'react-router-dom';
import CustomLoaderModal from "../common/CustomLoaderModal";

class GetInTouch extends React.Component {
  state = {
    name: "",
    mobileNumber: "",
    email: "",
    toastMessage: false,
    error: "",
    status: false,
    sendMailForm: false,
    loading: false,
    title: "",
  };

  resetState = () => {
    this.setState({
      name: "",
      mobileNumber: "",
      email: "",
      toastMessage: false,
      error: "",
      status: false,
      sendMailForm: false,
      loading: false,
      title: "",
      nameError: "",
      emailError: "",
      phoneError: ""
    });
  };

  render() {
    return (
      <div>
        <CustomLoaderModal loader={this.state.loading}/>
        <div className="py-0 my-0">
          <button
            className="theme-bg-color border-0 text-white p-2 px-4 my-5 normal-font-size"
            onClick={() => this.setState({ sendMailForm: true })}
          >
            {this.props.title === "Get-In-Touch"
              ? "LET'S GET IN TOUCH "
              : "REFER NOW"}
          </button>
          {this.renderGetInTouchModal()}
        </div>
      </div>
    );
  }

  // Render create Consult modal function

  renderGetInTouchModal = () => {
    return (
      <div className="row">
        <Modal
          fullscreen="lg-down"
          size="sm"
          className="border-style rounded"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.sendMailForm}
        >
          <div className="p-3">
            <div className="d-flex justify-content-end mx-3">
              <Icomoon
                style={{ cursor: "pointer" }}
                icon="close"
                size={20}
                onClick={() => {
                  this.setState({ sendMailForm: false });
                  this.resetState();
                }}
              />
            </div>
            <Modal.Body>
              <form onSubmit={this.onSubmitGetInTouch}>
                <TextField
                  className="col-md-12 my-2"
                  id="full-name"
                  label="Full Name"
                  value={this.state.name}
                  onChange={(e) =>
                    this.setState({ name: e.target.value, nameError: "" })
                  }
                  onFocus={(e) => this.setState({ nameError: "" })}
                />
                {this.state.nameError ? (
                  <span className="small-font-size text-danger">
                    {" "}
                    {this.state.nameError}
                  </span>
                ) : (
                  ""
                )}
                <TextField
                  className="col-md-12 my-2"
                  id="email"
                  label="Email"
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({ email: e.target.value, emailError: "" })
                  }
                  onFocus={(e) => this.setState({ emailError: "" })}
                />
                {this.state.emailError ? (
                  <span className="small-font-size text-danger">
                    {" "}
                    {this.state.emailError}
                  </span>
                ) : (
                  ""
                )}
                <TextField
                  className="col-md-12 my-2"
                  id="mob-num"
                  label="Mobile Number"
                  value={this.state.mobileNumber}
                  onChange={(e) =>
                    this.setState({
                      mobileNumber: e.target.value,
                      phoneError: "",
                    })
                  }
                  onFocus={(e) => this.setState({ phoneError: "" })}
                />
                {this.state.phoneError ? (
                  <span className="small-font-size text-danger">
                    {" "}
                    {this.state.phoneError}
                  </span>
                ) : (
                  ""
                )}
                <button
                  type="submit"
                  className={`theme-bg-color btn-lg btn-block border-0 rounded-0 text-white p-2 mt-3 font-style normal-font-size ${this.state.loading && 'disabled opacity-75'}`}
                >
                  LETS GET IN TOUCH
                </button>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    );
  };

  // Validation for Employee Name
  validateName = () => {
    const nameError = CheckUserName(this.state.name);
    if (nameError === 1) {
      this.setState({ nameError: "Please enter your name" });
      return false;
    } else if (nameError === 2) {
      this.setState({ nameError: "Please enter valid name" });
      return false;
    } else return true;
  };

  // Validation for username

  validateEmail = () => {
    const emailError = CheckEmail(this.state.email);
    if (emailError === 1) {
      this.setState({ emailError: "Please enter email address" });
      return false;
    } else if (emailError === 2) {
      this.setState({ emailError: "Please enter your valid email" });
      return false;
    } else return true;
  };

  // Validation for phone Number
  validatephoneNumber = () => {
    const phoneNumberError = CheckPhone(this.state.mobileNumber);
    if (phoneNumberError === 1) {
      this.setState({ phoneError: "Please enter your phone number" });
      return false;
    } else if (phoneNumberError === 2) {
      this.setState({ phoneError: "Please enter valid phone number" });
      return false;
    } else return true;
  };

  // Empty input validation

  ValidateAll = () => {
    const nameInput = this.validateName();
    const emailInput = this.validateEmail();
    const phoneNumberInput = this.validatephoneNumber();

    if (nameInput && emailInput && phoneNumberInput) {
      return true;
    } else {
      return false;
    }
  };

  // onsubmit get in touch form function
  onSubmitGetInTouch = async (e) => {
    e.preventDefault();
    const allValidation = this.ValidateAll();
    if (allValidation) {
      this.setState({
        loading: true
      });
      const requestData = {
        name: this.state.name,
        email: this.state.email,
        mobile_number: this.state.mobileNumber,
        title: this.props.title,
      };
      const response = await sendMail(requestData);
      if (response && response.status === "success") {
        this.setState({ sendMailForm: false, loading: false });
        AppConfig.setMessage(response.result, false);
        this.props.history.push('/thank-you');
        this.resetState();
      } else if (response && response.status === "error") {
        // this.setState({ sendMailForm: false });
        AppConfig.setMessage(response.result);
        this.setState({ loading: false });
        // this.resetState();
      }
      this.setState();
    }
  };
}
export default withRouter(GetInTouch);
