import React from "react";
import "../css/Common.css";
// import Carousel from "react-bootstrap/Carousel";
import { callApi } from "../apis/Api";
import Header from "./Header";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Slider from "react-slick";
import Icomoon from "../libraries/Icomoon";
import { serviceCategories } from "./serviceData";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ConsultModal from "./ConsultModal";

class ServiceCategoryPage extends React.Component {
  constructor(props) {
    super();
  }

  state = {
    serviceType: "",
    currentUrl: "",
    categoryUrl: "",
    type: "",
    accordianIndex: "",
    selectedCategory: {},
    serviceCategoryList: [],
    serviceFAQList: [],
    showMoreMap: {},
  };

  async componentDidMount() {
    const { history } = this.props;

    this.unlisten = history.listen((location) => {
      const currectUrl =
        location !== "/services" ? location.pathname.split("/")[3] : "";
      const categoryDetails = serviceCategories.find(
        (c) => c.value === currectUrl
      );
      // The location object contains information about the current URL
      this.setState({
        currentUrl: location.pathname,
        categoryUrl: location.pathname.split("/")[2],
        selectedCategory: categoryDetails,
        type: categoryDetails && categoryDetails.value,
        dispalyValue: categoryDetails && categoryDetails.displayValue,
      });
      if (categoryDetails) {
        this.serviceFilterBasedListApi(categoryDetails?.value);
        this.serviceFAQListApi(categoryDetails?.value);
      }
    });

    const currectUrl =
      history.location.pathname !== "/services"
        ? history.location.pathname.split("/")[3]
        : "";
    const categoryDetails = serviceCategories.find(
      (c) => c.value === currectUrl
    );

    if (history.location.pathname) {
      this.setState({
        currentUrl: history.location.pathname,
        categoryUrl: history.location.pathname.split("/")[2],
        selectedCategory: categoryDetails,
        type: categoryDetails && categoryDetails.value,
        dispalyValue: categoryDetails && categoryDetails.displayValue,
        // serviceCategoryList: serviceCategoryList,
      });

      if (categoryDetails) {
        this.serviceFilterBasedListApi(categoryDetails?.value);
        this.serviceFAQListApi(categoryDetails?.value);
      }
    }
  }
  // serviceFilterBasedListApi = async () => {
  // const response = await serviceFilterBasedList(this.state.type);
  //   if (response && response.status === "success") {
  //     const result = response.result;
  //     this.setState({ serviceCategoryData: result });
  //   }
  // };

  serviceFilterBasedListApi = async (filterType) => {
    let functionUrl = "getServicesType";
    const response = await callApi(functionUrl, {}, "GET", filterType);
    this.setState({ serviceCategoryList: response.result });
  };

  serviceFAQListApi = async (type) => {
    // View faq api
    let functionUrl = "faqType";
    const response = await callApi(functionUrl, {}, "GET", type);
    this.setState({ serviceFAQList: response.result });
  };

  componentWillUnmount() {
    // Cleanup the listener when the component unmounts
    this.unlisten();
  }

  toggleShowMore(index) {
    this.setState((prevState) => ({
      showMoreMap: {
        ...prevState.showMoreMap,
        [index]: !prevState.showMoreMap[index],
      },
    }));
  }

  // Render function for service content card
  renderServiceContent() {
    const homeBannerSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: <Icomoon icon="btnl" size={30} />,
      nextArrow: <Icomoon icon="btnr" size={30} />,
      // autoplay: true,
      // autoplaySpeed: 5000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <div className="d-none d-md-flex w-100 align-content-center justify-content-center">
          <div className="col-11">
            {this.state?.serviceCategoryList?.map((category, index) => (
              <div className="py-4" key={index}>
                <Slider
                  {...homeBannerSettings}
                  className="rose-dot service-slick-next-button"
                >
                  {category.imageUrls?.map((imageUrl, imageIndex) => (
                    <div key={imageIndex}>
                      <img
                        src={imageUrl}
                        alt=""
                        style={{
                          height: "80vh",
                          width: "100vw",
                        }}
                      />
                    </div>
                  ))}
                </Slider>

                <div className="mt-5">
                  <p className="font-weight-bold big-font-size">
                    {category.title}
                  </p>
                  <div className="font-style normal-font-size">
                    {this.state.showMoreMap[index] ? (
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: category.description,
                          }}
                          className="description-content"
                        ></div>
                        <p
                          onClick={() => this.toggleShowMore(index)}
                          className="border-0 theme-font-color bg-white"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Show less <i className="fas fa-solid fa-angle-up"></i>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div
                          className="font-style normal-font-size description-content"
                          dangerouslySetInnerHTML={{
                            __html: category.description.slice(0, 100),
                          }}
                        ></div>
                        {category.description.length > 100 && (
                          <p
                            onClick={() => this.toggleShowMore(index)}
                            className="border-0 theme-font-color bg-white"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Show more{" "}
                            <i className="fas fa-solid fa-angle-down"></i>
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-block d-md-none service-slick-next-button">
          {this.state?.serviceCategoryList?.map((category, index) => (
            <div className="py-4" key={index}>
              <Slider
                {...homeBannerSettings}
                className="rose-dot service-slick-next-button"
              >
                {category.imageUrls?.map((imageUrl, imageIndex) => (
                  <div key={imageIndex}>
                    <img
                      src={imageUrl}
                      alt=""
                      style={{
                        height: "50vh",
                        width: "100vw",
                      }}
                    />
                  </div>
                ))}
              </Slider>

              <div className="mt-5">
                <p className="font-weight-bold big-font-size">
                  {category.title}
                </p>
                <div>
                  {this.state.showMoreMap[index] ? (
                    <div className="font-style normal-font-size">
                      <div
                        className="font-style normal-font-size description-content"
                        dangerouslySetInnerHTML={{
                          __html: category.description,
                        }}
                      ></div>
                      <button
                        onClick={() => this.toggleShowMore(index)}
                        className="border-0 theme-font-color bg-white"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Show less <i className="fas fa-solid fa-angle-up"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="font-style normal-font-size">
                      <div
                        className="font-style normal-font-size description-content"
                        dangerouslySetInnerHTML={{
                          __html: category.description.slice(0, 50),
                        }}
                      ></div>
                      {category.description.length > 50 && (
                        <span>
                          <p
                            onClick={() => this.toggleShowMore(index)}
                            className="border-0 theme-font-color bg-white"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Show more{" "}
                            <i className="fas fa-solid fa-angle-down"></i>
                          </p>
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div>
          {this.state?.serviceCategoryList?.length <= 0 && (
            <div
              className="d-flex justify-content-center align-items-center theme-font-color"
              style={{
                height: "250px",
              }}
            >
              <p>Coming Soon...</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  accordionItems = [
    {
      title: "Accordion Item #1",
      content: "Content for Accordion Item #1",
    },
    {
      title: "Accordion Item #2",
      content: "Content for Accordion Item #2",
    },
  ];

  render() {
    return (
      <>
        <Header />
        <div className="bg-white pt-3 pt-lg-5 pb-lg-2">
          <ul className="d-none px-md-5 mx-md-5 d-lg-flex justify-content-start flex-wrap gap-2 bg-white">
            <li
              className="nav-item font-style"
              style={{ dispaly: "block" }}
            >
              <Link
                to={this.state.categoryUrl === `residential-interior` ? "/services/residential-interior" : "/services/commercial-interior"}
                className={
                  this.state.currentUrl === ("/services/residential-interior" || "/services/commercial-interior")
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                All {}
              </Link>
            </li>
            {serviceCategories
              .filter((item) => item.category === this.state.categoryUrl)
              .map((category) => (
                <li
                  className="nav-item dropdown font-style"
                  style={{ dispaly: "block" }}
                  key={category.value}
                >
                  <Link
                    to={`/services/${category.category}/${category.value}`}
                    onClick={() =>
                      this.setState({ selectedCategory: category })
                    }
                    className={`${
                      this.state.currentUrl ===
                      `/services/${category.category}/${category.value}`
                        ? "nav-link active"
                        : "nav-link"
                    }`}
                  >
                    {category.label}
                  </Link>
                </li>
              ))}
          </ul>

          <div
            className="d-flex d-block d-md-none gap-3 pt-5 mt-4 flex-wrap px-3 bg-white"
            style={{ position: "sticky", top: "30px", zIndex: 999 }}
          >
            <FormControl className="col-md-12 mb-3 col-lg-12 me-1">
              <InputLabel id="select-category">Select Category</InputLabel>
              <Select
                labelId="select-category"
                id="select-category"
                value={this.state.type}
                onChange={(e) => {
                  this.setState({
                    type: e.target.value,
                  });
                }}
              >
                <MenuItem value="all">
                  <Link
                    to={this.state.categoryUrl === `residential-interior` ? "/services/residential-interior" : "/services/commercial-interior"}
                    className={`w-100 text-decoration-none text-black w-100 ${
                      this.state.type ? "px-0" : "px-4"
                    }`}
                  >
                    {" "}
                    All Details{" "}
                  </Link>
                </MenuItem>

                {serviceCategories
                  .filter((item) => item.category === this.state.categoryUrl)
                  .map((category) => (
                    <MenuItem
                      value={category.value}
                      key={category.value}
                      onClick={() =>
                        this.setState({ selectedCategory: category })
                      }
                    >
                      <Link
                        to={`/services/${category.category}/${category.value}`}
                        className={`text-decoration-none text-black w-100 ${
                          this.state.type ? "px-0" : "px-4"
                        }`}
                      >
                        {category.label}
                      </Link>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="bg-white">
          <div className="bg-white py-2 pt-4 px-3 px-md-5 mx-md-5">
            <p className="font-weight-bold big-font-size theme-font-color">
              {this.state.selectedCategory.categoryTitle}
            </p>
            <p className="font-style normal-font-size">
              {this.state.selectedCategory.categoryDescription}
            </p>

            <div>{this.renderServiceContent()}</div>
            
            {
              this.state?.serviceCategoryList?.length > 0 && (
                <div className="d-flex justify-content-center pb-3">
                  <ConsultModal/>
                </div>
              )
            }
          </div>
        </div>
        
        {
          this.state.serviceFAQList.length > 0 && (
            <div className="py-5 px-3 px-md-5" style={{ background: "#f2f2f2" }}>
            <div>
              <h3 className="font-weight-bold big-font-size pb-2">
                FAQs on{" "}
                <span className="">
                  {this.state.dispalyValue}
                </span>
              </h3>
            </div>
            <Accordion>
              {this.state.serviceFAQList.map((faq, index) => (
                <Card key={index} style={{ background: "#f2f2f2", borderRight: "0px", borderLeft: "0px", borderRadius: "0px" }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={index.toString()}
                    className="d-flex justify-content-between"
                    style={{
                      cursor: "pointer",
                      border: "0px",
                      background: "#f2f2f2",
                    }}
                    onClick={() => {
                      if(this.state.accordianIndex === index) {
                        this.setState({accordianIndex: ""});
                      } else {
                        this.setState({accordianIndex: index});
                      }
                    }}
                  >
                    <h6 className="font-style" style={{ fontWeight: '600'}}>{index + 1}. {faq.question} </h6>
                    <div>
                      {
                        this.state.accordianIndex === index ? (
                          <i className="fas fa-solid fa-angle-up"></i>
                        ) : (
                          <i className="fas fa-solid fa-angle-down"></i>
                        )
                      }
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body style={{ background: "#f2f2f2" }}>
                      <div 
                        className="font-style normal-font-size text-justify description-content"
                        dangerouslySetInnerHTML={{
                          __html: faq.answer
                            ? faq.answer
                            : "",
                        }}>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
          )
        }

        <Footer props={this.props} />
      </>
    );
  }
}

export default ServiceCategoryPage;
