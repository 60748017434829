import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getSeoByPage } from "../apis/Seo";

const MetaDataDescription = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const GetSEOMetaData = async () => {
    const seoResponse = await getSeoByPage({
      pageUrl: currentPath === "/home" ? "/" : currentPath.startsWith("/projects/") ? "/our-projects" : currentPath,
    });
    const seoResult = seoResponse?.result;
    if (seoResult?.metaTitle && seoResult?.metaDescription) {
      document.title = seoResult?.metaTitle;
      document.querySelector('meta[name="description"]').content =
        seoResult?.metaDescription;
    } else {
      document.title = "DezignCode";
      document.querySelector('meta[name="description"]').content = "";
    }
  };

  useEffect(() => {
    Promise.all([GetSEOMetaData()])
  }, [currentPath]);

  return <></>;
};

export default MetaDataDescription;
