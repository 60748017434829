// Login page
import React from "react";
import { Link } from "react-router-dom";
import { CheckEmail, CheckPassword } from "../../common/Validation";
import { login } from "../../apis/Admin";
import { TextField } from "@material-ui/core";
import Logo from "../../images/logo.svg";
import AppConfig from "../../modal/AppConfig";
import User from "../../modal/User";
import Icomoon from "../../libraries/Icomoon";
import { observer } from "mobx-react";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    error: "",
    showPassword: false,
  };
  render() {
    return (
      <div>
        <div className="d-flex justify-content-center pt-5">
          <div className="col-md-3 mt-3">
            <Link to="/" target="_blank" rel="noreferrer">
              <img src={Logo} alt="Logo" className="mx-3" />
            </Link>
            {this.renderLogin()}
          </div>
        </div>
      </div>
    );
  }

  // Render signup form
  renderLogin() {
    return (
      <div className="col-md-12 rounded bg-white shadow p-3 my-4">
        <h3 className="theme-font-color font-style mb-3 text-center">Login</h3>
        <form onSubmit={this.onSubmitLogin}>
          <div className="py-3">
            <TextField
              type="text"
              className="form-control"
              id="email"
              label="Email"
              value={this.state.email}
              onChange={(e) =>
                this.setState({ email: e.target.value, emailError: "" })
              }
              onFocus={() => this.setState({ emailError: "" })}
            />
          </div>
          {this.state.emailError ? (
            <span className="small-font-size text-danger">
              {" "}
              {this.state.emailError}
            </span>
          ) : (
            ""
          )}
          <div className="py-3">
            <TextField
              type={this.state.showPassword ? "text" : "password"}
              className="form-control"
              id="password"
              label="Password"
              value={this.state.password}
              onChange={(e) =>
                this.setState({ password: e.target.value, passwordError: "" })
              }
              onFocus={() => this.setState({ passwordError: "" })}
              InputProps={{
                endAdornment: this.state.showPassword ? (
                  <Icomoon
                    icon="eye-slash-solid"
                    size={20}
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  />
                ) : (
                  <Icomoon
                    icon="eye-solid"
                    size={20}
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  />
                ),
              }}
            />
          </div>
          {this.state.passwordError ? (
            <span className="small-font-size text-danger">
              {" "}
              {this.state.passwordError}
            </span>
          ) : (
            ""
          )}
          <Link to="/forgot-password" className="text-decoration-none">
            <p className="small-font-size text-dark font-weight-bold my-3">
              Forgot Password?
            </p>
          </Link>
          {this.state.error ? (
            <span className="small-font-size text-danger">
              {" "}
              {this.state.error}
            </span>
          ) : (
            ""
          )}
          <button
            type="submit"
            className="btn theme-bg-color text-white col-md-12 font-style my-3"
          >
            Login
          </button>
        </form>
      </div>
    );
  }

  // Validation for username

  validateEmail = () => {
    const emailError = CheckEmail(this.state.email);
    if (emailError === 1) {
      this.setState({ emailError: "Please enter your email address" });
      return false;
    } else if (emailError === 2) {
      this.setState({ emailError: "Please enter an valid email address" });
      return false;
    } else return true;
  };

  // Validation for password

  validatePassword = () => {
    const passwordError = CheckPassword(this.state.password);
    if (passwordError === 1) {
      this.setState({ passwordError: "Please enter password" });
      return false;
    } else if (passwordError === 2) {
      this.setState({ passwordError: "Please enter valid password" });
      return false;
    } else return true;
  };

  // Empty input validation

  ValidateAll = () => {
    const emailInput = this.validateEmail();
    const passwordInput = this.validatePassword();
    if (emailInput && passwordInput) {
      return true;
    } else {
      return false;
    }
  };

  // on submit sign in function
  onSubmitLogin = async (e) => {
    e.preventDefault();
    const allValidation = this.ValidateAll();
    if (allValidation) {
      const requestData = {
        email: this.state.email,
        password: this.state.password,
      };
      login(requestData).then((response) => {
        if (response && response.status === "success") {
          User.setUserId(response.result.user_id);
          AppConfig.setApiKey(response.result.token);
          this.props.history.replace("/manage-banner");
        } else if (response.status === "error") {
          AppConfig.setMessage(response.result);
        }
      });
    }
  };
}
export default observer(Login);
