import { callApi } from './Api';
import './Global';

/**
 *
 * @param{email, password)data
 */
export const SeoList = async( requestData = {} ) => {
    const functionUrl = "seoList";

    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single seo) by id
 */
export const getSeoById = async(id) => {
    const functionUrl = "seo";
    try {
        return await callApi(functionUrl, {}, 'GET', id)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single seo) by id
 */
export const updateSeoById = async(requestData, id) => {
    const functionUrl = "seo";
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};

/**
 *
 * @queryParams{get single seo) by page
 */
export const getSeoByPage = async(queryParams) => {
    const functionUrl = "seoByPage";
    try {
        return await callApi(functionUrl, {}, 'GET', null, false, false, queryParams)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("Error from API => ", error);
            });
    } catch (error) {
        console.log("Error from catch => ", error);
    }
};