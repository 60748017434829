import Loading from '../images/load.gif';
import AppConfig from '../modal/AppConfig';
import Modal from 'react-bootstrap/Modal';
import '../css/Carousel.css';

const Loader = () => {
    return(
        <>
        {
           AppConfig.loader ? 
                <div >
                    <Modal 
                        show={ AppConfig.loader} 
                        centered
                        backdropClassName = "modal-backdrop-loader"
                        animation={false}
                    >
                        <Modal.Body className='modal-content-loader'> 
                            <div className="d-flex justify-content-center m-5 p-5">
                                <img src={Loading} alt='loader'/>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            :
            null
        }
        </>
    )
}
export default Loader;
