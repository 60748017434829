import { makeAutoObservable} from "mobx"
import { makePersistable , clearPersistedStore, getPersistedStore, hydrateStore, isHydrated, isPersisting, startPersisting,  pausePersisting, stopPersisting } from 'mobx-persist-store';

class User {
	user_id = '';
	api_key = '';

	constructor() {
        makeAutoObservable(this)
		makePersistable(this, { name: 'user', properties: ['user_id', 'api_key'], storage: window.sessionStorage });
    }
	get isHydrated() {
		return isHydrated(this);
	}
	
	get isPersisting() {
		return isPersisting(this);
	}

	startPersist() {
		startPersisting(this);
	}
	setApiKey(value) {
		this.api_key = value;
	}


	async hydrateStore() {
		await hydrateStore(this);
	}

	async clearStoredDate() {
		this.user_id = '';
		this.api_key =  '';
		
		await clearPersistedStore(this);
	}
	  
	async getStoredData() {
		return getPersistedStore(this);
	}

	pausePersist(){
		pausePersisting(this);
	}
	
	disposePersist(){
		stopPersisting(this);
	}
	
	async rehydrateStore() {
		await hydrateStore(this);
	}

	setUserId(value) {
		this.user_id = value;
	}
	
}

export default new User();