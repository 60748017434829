import { callApi } from "./Api";

/**
 *
 * @param{email, password)data
 */

export const faqsList = async( requestData = {} ) => {
    const functionUrl = "faqsList";
  
    try {
        return await callApi(functionUrl, requestData )
       
            .then((response) => {
                return response;
            })
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
  };


/**
 *
 * @param{email, password)data
 */

export const createFAQ = async(requestData={}) => {
    const functionUrl = "faq";
    try {
        return await callApi(functionUrl, requestData )
            .then((response) => {
                return response;
            })
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{update single faq) by id
 */
export const updateFAQ = async(requestData, id) => {
    const functionUrl = "faq";
    try {
        return await callApi(functionUrl, requestData, 'PUT', id )
            .then((response) => {
                return response;
            })
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{get single faq) by id
 */
export const getFAQ = async(id) => {
    const functionUrl = "faq";
    try {
        return await callApi(functionUrl, {}, 'GET', id)
            .then((response) => {
                return response;
            })
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};

/**
 *
 * @param{delete faq) by id
 */
export const deleteFAQ = async(id) => {
    const functionUrl = "faq";
    try {
        return await callApi(functionUrl, {}, 'DELETE', id )
            .then((response) => {
                return response;
            })
    } catch (error) {
        // console.log("Error from catch => ", error);
    }
};
