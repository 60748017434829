import React from "react";
import "../css/Common.css";
import "../css/Carousel.css";
import Testimonials from "../images/home/gradient.svg";
// import Followus from "../images/home/followus.svg";
import Play from "../images/home/play.svg";
import Icomoon from "../libraries/Icomoon";
import Slider from "react-slick";
import { callApi } from "../apis/Api";
import SendMailForm from "./SendMailForm";
// import ConsultModal from "./ConsultModal";
// import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import Header from "./Header";
import Footer from "../pages/Footer";
import { SocialMediaLink } from "../common/Element";
import "../css/Nav.css";

// General Focus Hook
const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    ref.current && ref.current.focus();
  };

  return { setFocus, ref };
};

class Home extends React.Component {
  constructor() {
    super();
    this.howWeWork = utilizeFocus();
    this.testimonial = utilizeFocus();
    this.ourClient = utilizeFocus();
  }
  state = {
    homeBanner: [],
    project: [],
    testimonial: [],
    client: [],
    workStep: [],
    getInTouchModal: false,
    consultModal: false,
    videoModal: false,
    homeBanners: [],
    desktopBanner: [],
    mobileBanner: []
  };
  async componentDidMount() {
    // document.title = `Professional Interior Designers in Bangalore | DezignCode`;
    // document.querySelector('meta[name="description"]').content =
    //   "Know more about our trained and equipped interior designers in bangalore to turn not just your dreams, passions, and imaginations but also your quirks to reality! We are one of those rare passionate in Interior designs. Visit us Today!";
    const {
      location: { hash },
    } = this.props;
    if (hash === "#how-we-work") {
      this.howWeWork.setFocus();
    } else if (hash === "#testimonial") {
      this.testimonial.setFocus();
    } else if (hash === "#our-client") {
      this.ourClient.setFocus();
    }

    // Api call for home page
    let functionUrl = "home";
    const response = await callApi(functionUrl, {}, "GET");
    if (response.status === "success") {
      const result = response.result;
      const homeBanner =
        result.homebanner && result.homebanner.length > 0
          ? result.homebanner[0]
          : [];
      const homeWelcome =
        result.homeaboutus && result.homeaboutus.length > 0
          ? result.homeaboutus[0]
          : [];

      let workStep = [];
      for (let i in result.workstep) {
        workStep.push(result.workstep[i]);
      }
      const project = result.project;
      const testimonial = result.testimonial;
      const client = result.client;
      this.setState({
        desktopBanner: result.desktopBanner,
        mobileBanner: result.mobileBanner,
        homeBannerTitle: homeBanner?.title,
        homeBannerImage: homeBanner?.image,
        homeWelcomeTitle: homeWelcome?.title,
        homeWelcomeDescription: homeWelcome?.description,
        homeWelcomeImage: homeWelcome?.image,
        workStep: workStep,
        project: project,
        testimonial: testimonial,
        client: client,
      });
    }
    let quotesFunctionUrl = "quotes";
    const quotesResponse = await callApi(quotesFunctionUrl, {}, "GET");
    if (response.status === "success") {
      const quotesResult = quotesResponse?.result[0];
      this.setState({
        quoteTitle: quotesResult?.title,
        quoteDescription: quotesResult?.description,
      });
    }
  }
  componentDidUpdate() {
    const {
      location: { hash },
    } = this.props;
    if (hash === "#how-we-work") {
      this.howWeWork.setFocus();
    } else if (hash === "#testimonial") {
      this.testimonial.setFocus();
    } else if (hash === "#our-client") {
      this.ourClient.setFocus();
    }
  }
  render() {
    // const { homeBannerImage } = this.state;
    return (
      <div>
        <Header />
        <SocialMediaLink />
        {/* <div
          className="d-flex align-items-center home-bg"
          style={{ backgroundImage: `url(${homeBannerImage})` }}
        >
          <div className="col-md-12 row align-items-center justify-content-around">
            <div className="col-md-6 mt-5 pt-5 pl-5">
              <p className="pt-5 font-style text-white mx-5">Decoding Ideas</p>
              <h1 className="text-white font-weight-bold font-style2 mx-5">
                {this.state.homeBannerTitle ? this.state.homeBannerTitle : "-"}
              </h1>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              {this.renderHomeCard()}
            </div>
          </div>
        </div> */}
        <div>{this.renderHomeCard()}</div>
        <div className="p-5">{this.renderDesignComapany()}</div>
        <div className="p-5">{this.renderHowWeWork()}</div>
        <div className="p-5">{this.renderOurProjects()}</div>
        <div
          className="p-5"
          style={{ backgroundImage: `url(${Testimonials})` }}
        >
          {this.renderTestimonials()}
        </div>
        <div className="p-5">{this.renderOurClients()}</div>
        {/* <div className="p-5">{this.renderFollowUs()}</div> */}
        {this.renderVideoModal()}
        <Footer props={this.props} />
      </div>
    );
  }

  // Render function for Home page card
  renderHomeCard() {
    const homeBannerSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: <Icomoon icon="btnl" size={30} />,
      nextArrow: <Icomoon icon="btnr" size={30} />,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <div
          className="d-none d-md-block "
          style={{ marginTop: "4.8rem", width: "" }}
        >
          <Slider
            {...homeBannerSettings}
            className="rose-dot custom-slick-next-button"
          >
            {this.state?.desktopBanner?.map((homeBanner) => (
              <div className="" key={homeBanner.id}>
                <img
                  src={homeBanner.image}
                  alt=""
                  style={{
                    height: "95vh",
                    width: "100vw",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div
          className="d-block d-md-none custom-slick-next-button"
          style={{ marginTop: "4rem", width: "" }}
        >
          <Slider {...homeBannerSettings} className="rose-dot">
            {this.state?.mobileBanner?.map((homeBanner) => (
              <div className="" key={homeBanner.id}>
                <img
                  src={homeBanner.image}
                  alt=""
                  style={{
                    height: "95vh",
                    width: "100vw",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      // <div className="col-md-9">
      //   <div className="bg-white border-style  p-3 m-5">
      //     <p className="medium-font-size font-weight-bold font-style">
      //       {this.state.quoteTitle ? this.state.quoteTitle : "-"}
      //     </p>
      //     <p className="normal-font-size font-style text-justify">
      //       {this.state.quoteDescription ? this.state.quoteDescription : "-"}
      //     </p>
      //     <ConsultModal home="home" />
      //     <p className="medium-font-size font-weight-bold font-style">
      //       {this.state.resentBlogTitle
      //         ? this.state.resentBlogTitle
      //         : "RECENT BLOG POSTS"}
      //     </p>
      //     <ul className="py-0 my-0 font-style normal-font-size">
      //       <li className="text-justify">
      //         <a
      //           href="https://thedezigncode.com/blog/2021/09/09/difference-between-interior-designer-and-interior-decorator/"
      //           target="_blank"
      //           rel="noreferrer"
      //           className="text-decoration-none text-dark normal-font-size"
      //         >
      //           Difference between interior designer and interior decorator
      //         </a>
      //       </li>
      //       <li className="text-justify">
      //         <a
      //           href="https://thedezigncode.com/blog/2021/09/09/design-your-home-interiors-with-diy/"
      //           target="_blank"
      //           rel="noreferrer"
      //           className="text-decoration-none text-dark normal-font-size"
      //         >
      //           Design your home interiors with diy
      //         </a>
      //       </li>
      //       <li className="text-justify">
      //         <a
      //           href="https://thedezigncode.com/blog/2021/09/09/modern-home-interior-design/"
      //           target="_blank"
      //           rel="noreferrer"
      //           className="text-decoration-none text-dark normal-font-size"
      //         >
      //           Modern Home Interior Design
      //         </a>
      //       </li>
      //     </ul>
      //   </div>
      // </div>
    );
  }

  // Render function for design company screen
  renderDesignComapany() {
    return (
      <div className="row">
        <div className="col-md-6">
          <h2 className="theme-font-color font-weight font-style2 mb-3">
            WHO WE ARE
          </h2>
          {/* <h3 className="font-style">
            We are the best Interior Designers in Bangalore
          </h3> */}
          <div className="col-md-11 px-0">
            <h3 className="font-weight font-style" style={{ fontSize: "150%" }}>
              We are the best interior designers in Bangalore - Designed to
              delight & Build to last
            </h3>
          </div>
          {/* <div className="col-md-11 px-0">
            <h2
              className="theme-font-color font-style"
              style={{ fontSize: "180%" }}
            >
              {this.state.homeWelcomeTitle
                ? `WE ARE THE BEST INTERIOR DESIGNERS IN BANGALORE - ${this.state.homeWelcomeTitle}`
                : "WE ARE THE BEST INTERIOR DESIGNERS IN BANGALORE"}
            </h2>
          </div> */}
          <p
            className="font-style mt-5 normal-font-size text-justify description-content"
            dangerouslySetInnerHTML={{
              __html: this.state.homeWelcomeDescription
                ? this.state.homeWelcomeDescription
                : "",
            }}
          />
          {/* <button className="theme-bg-color border-0 text-white p-2 px-4 my-5">READ MORE -- </button> */}
        </div>
        <div className="col-md-6">
          <img
            src={this.state.homeWelcomeImage}
            alt="best interior designers in bangalore"
            width="100%"
          />
          <div className="d-flex justify-content-end">
            <div className="mt-5 right-border-style px-3">
              <h1 className="font-weight-bold font-style2 theme-font-color">
                6+ YEARS
              </h1>
              <p className="theme-font-color normal-font-size font-style2 text-right mb-0">
                OF EXPERIENCE
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render function for how we work screen
  renderHowWeWork() {
    let j = 1;
    return (
      <div className="header-margin">
        <div className="d-flex justify-content-between ">
          <div>
            <p className="big-font-size font-style">STEPS TO DESTINATION</p>
            <h2 className="theme-font-color font-weight-bold font-style2">
              HOW WE WORK
            </h2>
          </div>
          <SendMailForm title="Get-In-Touch" />
        </div>
        <input type="text" className="hiddenInput" ref={this.howWeWork.ref} />
        {this.state.workStep.map((workStep, i) => {
          if (j % 2 !== 0) {
            j = j + 1;
            return (
              <>
                <div className="d-flex justify-content-between" key={i}>
                  <div className="row">
                    <div className="col-md-5 m-auto">
                      <div className="background">
                        <p className="theme-font-color font-style bg-text">
                          0{workStep.id}{" "}
                        </p>
                      </div>
                      <div className="content">
                        <p className="big-font-size font-weight-bold font-style mt-3">
                          {workStep.steps}
                        </p>
                        <h3 className="theme-font-color font-weight-bold font-style">
                          {workStep.title ? workStep.title : "-"}
                        </h3>
                        <p className="font-style mt-3 normal-font-size text-justify">
                          {workStep.description ? workStep.description : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 mt-5 d-flex justify-content-end">
                      <div className="col-md-10">
                        <img
                          src={workStep.image}
                          alt={workStep.alt_tag}
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          } else {
            j = j + 1;
            return (
              <>
                <br /> <br /> <br />
                <div className="row " key={i}>
                  <div className="col-md-6 mt-5 d-flex justify-content-start">
                    <div className="col-md-10">
                      <img
                        className="py-5"
                        src={workStep.image}
                        alt={workStep.alt_tag}
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="col-md-5 m-auto">
                    <div className="background">
                      <p className="theme-font-color font-style bg-text text-right">
                        0{workStep.id}
                      </p>
                    </div>
                    <div className="content px-3 pt-5">
                      <p className="big-font-size font-weight-bold font-style">
                        {workStep.steps}
                      </p>
                      <h3 className="theme-font-color font-weight-bold font-style">
                        {workStep.title ? workStep.title : "-"}
                      </h3>
                      <p className="font-style mt-3 normal-font-size text-justify">
                        {workStep.description ? workStep.description : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          }
        })}
        <br /> <br />
        <br />
        <div className="d-flex justify-content-end">
          <div className="mt-5 right-border-style px-3">
            <h2 className="font-weight-bold font-style2 theme-font-color">
              10 YEARS
            </h2>
            <p className="theme-font-color normal-font-size font-style2 text-right mb-0">
              WARRANTY* (T&C Apply)
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Render function for our projects
  renderOurProjects() {
    return (
      <div>
        <div>
          <p className="big-font-size font-style">
            STEP TOWARDS A BRAND-NEW WORLD
          </p>
          <h2 className="theme-font-color font-weight-bold font-style2">
            OUR PROJECTS
          </h2>
        </div>
        <div>
          <div className="row">
            {this.state.project.map((project, i) => (
              <div
                className="col-md-4 our-project-container pointer"
                key={i}
                onClick={() =>
                  this.viewProjects(project.id, project.name.toLowerCase())
                }
              >
                <div
                  className="col-md-11 project-container-style  bg-white p-2"
                  style={{ borderRadius: "25px" }}
                >
                  <img
                    style={{ borderRadius: "25px" }}
                    src={project.thumbnailimage}
                    alt={project.alt_tag}
                    width="100%"
                    height="90%"
                  />
                </div>
                <div className="our-project-style">
                  <p className="font-weight-bold big-font-size">
                    {project.name}
                  </p>
                  <p className="font-weight-bold big-font-size">
                    {project.projectName}
                  </p>
                  {/* <p className="small-font-size">
                                        {project.date ? moment(project.date).format('Do MMMM  YYYY'):'-'}
                                    </p> */}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end">
            <div className="right-border-style px-3 mt-5">
              <h2 className="font-weight-bold font-style2 theme-font-color">
                200+ PROJECTS
              </h2>
              <p className="theme-font-color normal-font-size text-right font-style2 mb-0">
                HOME AND COMMERCIAL SPACES
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render function for testimonials
  renderTestimonials() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      nextArrow: <Icomoon icon="btnr" size={30} />,
      prevArrow: <Icomoon icon="btnl" size={30} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="header-margin">
        <div>
          <p className="big-font-size font-style">REFERENCE</p>
          <h2 className="theme-font-color font-weight-bold font-style2">
            TESTIMONIALS
          </h2>
        </div>
        <div className="mt-md-5">
          <input
            type="text"
            className="hiddenInput"
            ref={this.testimonial.ref}
          />
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Slider {...settings} className="rose-arrow rose-dot">
                {this.state.testimonial.map((testimonial, i) => (
                  <div className="col-md-12 testimonial-container" key={i}>
                    <div
                      className="shadow card overflow-auto border-0 border-style p-3 p-md-5"
                      style={{ minHeight: "300px" }}
                    >
                      {testimonial.image &&
                      (testimonial.image !== "null" || null) ? (
                        <div className="d-flex justify-content-center my-2">
                          <img
                            src={testimonial.image}
                            alt="alt"
                            width="50%"
                            height="100%"
                            className="rounded-2"
                          />
                        </div>
                      ) : (
                        " "
                      )}
                      <div>
                        <p className="normal-font-size text-center text-justify">
                          {testimonial.description}
                        </p>
                        <p className="normal-font-size text-right">
                          {testimonial.name}
                        </p>
                        {this.state.testimonial.review_link ? (
                          <div className="middle col-md-12">
                            <div className="d-flex justify-content-center mb-5 pb-3">
                              <div>
                                <img
                                  src={Play}
                                  alt="play"
                                  width="80%"
                                  height="150"
                                  className="ml-3"
                                  onClick={() =>
                                    this.setState({
                                      videoModal: true,
                                      video: testimonial.review_link,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* <div className="container p-0">
            <div className="row d-flex col-md-12 p-0 h-100">
              {this.state.testimonial.map((testimonial, i) => (
                <div className="col-md-4 my-3 p-0" key={i}>
                  <div
                    className="shadow card h-100 border-0 border-style p-3 p-md-5"
                    // style={{ height: "300px" }}
                  >
                    {testimonial.image && (testimonial.image !== "null" || null) ? (
                      <div className="d-flex justify-content-center my-2">
                        <img
                          src={testimonial.image}
                          alt="alt"
                          width="50%"
                          height="100%"
                          className="rounded-2"
                        />
                      </div>
                    ) : (
                      " "
                    )}
                    <div>
                      <p className="normal-font-size text-center text-justify">
                        {testimonial.description}
                      </p>
                      <p className="normal-font-size text-right mb-0">
                        {testimonial.name}
                      </p>
                      {this.state.testimonial.review_link ? (
                        <div className="middle col-md-12">
                          <div className="d-flex justify-content-center mb-5 pb-3">
                            <div>
                              <img
                                src={Play}
                                alt="play"
                                width="80%"
                                height="150"
                                className="ml-3"
                                onClick={() =>
                                  this.setState({
                                    videoModal: true,
                                    video: testimonial.review_link,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
        <div className="d-flex justify-content-end">
          <div className="mt-5 right-border-style px-3">
            <h2 className="font-weight-bold font-style2 theme-font-color">
              TRUST US
            </h2>
            <p className="theme-font-color normal-font-size text-right font-style2 mb-0">
              AFFORDABLE LUXURY
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Render function for Our Clients
  renderOurClients() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      nextArrow: <Icomoon icon="btnr" size={30} />,
      prevArrow: <Icomoon icon="btnl" size={30} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
    return (
      <div className="header-margin" style={{ bottom: -100 }}>
        <div>
          <h2 className="theme-font-color font-weight-bold font-style2">
            OUR CLIENTS
          </h2>
        </div>
        <div className="container-fluid my-5">
          <input type="text" className="hiddenInput" ref={this.ourClient.ref} />
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Slider {...settings} className="rose-arrow rose-dot">
                {this.state.client.map((client, i) => (
                  <div className="col-md-12" key={i}>
                    <div className="card  shadow border-0 border-style">
                      <div className="d-flex justify-content-center align-items-center p-3">
                        <img
                          src={client.image}
                          alt={client.alt_tag}
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Render function for follows us
  // renderFollowUs() {
  //   return (
  //     <div className="row">
  //       <div className="col-md-6 pl-5">
  //         <h2 className="follow-us theme-font-color font-weight-bolder font-style2">
  //           FOLLOW US
  //         </h2>
  //         <h2 style={{ fontSize: "40px" }} className="font-style">
  //           Instagram
  //         </h2>
  //         <button className="bg-dark border-0 text-white p-2 px-4 my-3 font-style">
  //           <a
  //             className="text-decoration-none text-white"
  //             href="https://www.instagram.com/dezign_code/"
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             <Icomoon
  //               icon="instagram1"
  //               className="theme-font-color mr-3"
  //               color="#f222ff"
  //             />
  //             Follow us --
  //           </a>
  //         </button>
  //       </div>
  //       <div className="col-md-6  pl-5">
  //         <img
  //           src={Followus}
  //           alt="Dezign_code Interior designers in bangalore Follow us on instagram"
  //           width="90%"
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  // on click view projects details
  viewProjects = (id, projectName) => {
    this.props.history.push({
      // pathname: "/view-project",
      pathname: `/projects/${projectName.replace(/ /g, "-")}`,
      state: { id },
    });
  };

  renderVideoModal = () => {
    return (
      <Modal
        fullscreen="lg-down"
        size="xl"
        className="border-style rounded"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.videoModal}
      >
        <div className="p-3">
          <div className="d-flex justify-content-end mx-3">
            <Icomoon
              style={{ cursor: "pointer" }}
              icon="close"
              size={20}
              onClick={() => {
                this.setState({ videoModal: false });
              }}
            />
          </div>
          <Modal.Body>
            <iframe
              width="100%"
              height="700"
              src={this.state.video}
              title="video"
            ></iframe>
          </Modal.Body>
        </div>
      </Modal>
    );
  };
}

export default Home;
